import React from "react";
import { tokens } from "../theme";
import { useTheme } from "@mui/material";

// import VideoHighlights from "../components/SN/videoMetrics/VideoHighlights";
import VideoMetrics23 from "../components/SN/videoMetrics/VideoMetrics23"

const VideoMetrics = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    function hexToRgba(hex, opacity) {
        hex = hex.replace('#', '');
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
    }

    return (
        <div style={{ marginLeft: "1em" }}>

            {/* <div className="sh2" style={{ color: colors.SKcustom[11] }}>V-Commerce Highlights </div> */}

            {/* <VideoHighlights /> */}
            <VideoMetrics23 />

        </div >
    );
}
export default VideoMetrics;

