import React, { useEffect, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import axios from 'axios';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";

import '../../../components/visuals/design/sk.css'

import UKBCannual from './UKBCannual';

Chart.register(ChartDataLabels);

const UKsignUps = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    function hexToRgba(hex, opacity) {
        hex = hex.replace('#', '');
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
    }

    const [chartData, setChartData] = useState({
        labels: [{
            color: colors.SKcustom[10]
        }],
        datasets: [{
        }],
    });

    const [selectedYear, setSelectedYear] = useState(2024);

    useEffect(() => {
        // Fetch data from your MongoDB for the selected year
        axios.get(`${process.env.REACT_APP_SERVER_URL}/ukup/${selectedYear}`)
            .then((response) => {
                const dataFromServer = response.data;

                // Extract the necessary data for the chart
                const months = dataFromServer.map((item) => item.month);
                const signUpData = dataFromServer.map((item) => item.signUp);
                const sumData = dataFromServer.map((item) => item.sum);

                // MONTHLY SIGN UPS
                const signUpDataset = {
                    label: 'Monthly Sign-Ups',
                    backgroundColor: colors.SKcustom[30],
                    data: signUpData,
                    barThickness: 40,
                    datalabels: {
                        anchor: 'end',
                        align: 'top',
                        color: colors.SKcustom[30],
                        font: { size: 10 },
                        display: true,
                        padding: {
                            top: 10,
                        },
                        formatter: function (value, context) {
                            return value.toLocaleString(); // Format the label using toLocaleString()
                        },
                    }
                };

                // CUMULATIVE SIGN UPS
                const sumDataset = {
                    label: 'Cumulative',
                    backgroundColor: colors.SKcustom[31],
                    data: sumData,
                    barThickness: 40,
                    datalabels: {

                        color: colors.SKcustom[10],
                        font: { size: 10 },
                        display: true,
                        anchor: 'end',
                        align: 'start',
                        formatter: function (value, context) {
                            return value.toLocaleString();
                        },

                    }
                };


                setChartData({
                    labels: months,
                    datasets: [sumDataset, signUpDataset],
                });
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, [selectedYear]);

    const options = {
        scales: {
            x: {
                stacked: true,
                beginAtZero: false,
                ticks: {
                    color: colors.SKcustom[10],
                    font: { size: 12, weight: 500 },
                    padding: 10
                },
            },
            y: {
                stacked: true,
                beginAtZero: false,
                ticks: {
                    color: colors.SKcustom[10],
                    font: { size: 12 }
                }
            },
        },
        plugins: {
            datalabels: {
                font: { size: 9 },
                display: true,

            },
            legend: {
                labels: {
                    font: { size: "12em" },
                    color: colors.SKcustom[10],
                    boxHeight: 12,
                    boxWidth: 12
                }
            }
        },
    };

    const handleYearChange = (year) => {
        setSelectedYear(year);
    };


    return (
        <div>
            <div style={{ color: colors.SKcustom[18], marginBottom: "5px", fontSize: '16px' }}>BigCommerce Growth</div>

            <div className="rowPlain">
                {/* ANNUAL GRAPH */}
                <div className="column" style={{ width: '400px' }}>
                    <div style={{ backgroundColor: colors.SKcustom[18], width: '400px', height: '24px' }}>
                        <div style={{ marginLeft: "5px", color: "#fff", fontSize: "14px", fontWeight: 600 }}>
                            Annual Sign-Ups
                        </div>
                    </div>
                    <div style={{ width: '400px', backgroundColor: hexToRgba(colors.SKcustom[10], 0.12), padding: '10px' }}>
                        <UKBCannual />
                    </div>
                </div>

                <div style={{ width: '2px', backgroundColor: 'gray', }}></div>

                {/* MONTHLY GRAPH */}
                <div className="column" style={{ width: '600px' }}>
                    {/* YEAR LINE */}
                    <div style={{ backgroundColor: colors.SKcustom[18], width: '600px', height: '24px' }}>
                        <div style={{ marginLeft: "5px", color: "#fff", fontSize: "14px", fontWeight: 600 }}>
                            Monthly Breakdown: {selectedYear}
                        </div>
                    </div>
                    {/* BUTTONS */}
                    <div style={{ marginBottom: "-15px" }}>
                        <button className="noBorderButton" style={{ marginRight: "10px", color: colors.SKcustom[18] }} onClick={() => handleYearChange(2024)}>2024</button>
                        <button className="noBorderButton" style={{ marginRight: "10px", color: colors.SKcustom[18] }} onClick={() => handleYearChange(2023)}>2023</button>
                        <button className="noBorderButton" style={{ marginRight: "10px", color: colors.SKcustom[18] }} onClick={() => handleYearChange(2022)}>2022</button>
                        <button className="noBorderButton" style={{ marginRight: "10px", color: colors.SKcustom[18] }} onClick={() => handleYearChange(2021)}>2021</button>
                    </div>
                    <Bar data={chartData} options={options} />
                </div>

            </div>
        </div >
    );
};


export default UKsignUps;
