import React, { useState, useMemo, useEffect } from "react";
import { tokens } from "../theme";
import { useTheme } from "@mui/material";

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import Logo from '../img/dropzoneLogo.png'

const DropZone = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    //Klaviyo General
    const [klaviyo, setKlaviyo] = useState();
    useEffect(() => {
        const fetchKlaviyo = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/klaviyo/dropzone`);
                const data = await response.json();

                if (response.ok) {
                    setKlaviyo(data.profileCount);
                } else {
                    console.error('Response not ok:', data);
                }
            } catch (error) {
                console.error('Error fetching Klaviyo data:', error);
            }
        };
        fetchKlaviyo();
    }, []);

    //kcd
    // const [kcd, setKcd] = useState(0);
    // useEffect(() => {
    //     const fetchKcd = async () => {
    //         try {
    //             const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/klaviyo/kcd`);
    //             const data = await response.json();

    //             if (response.ok) {
    //                 setKcd(data.profileCount);
    //             } else {
    //                 console.error('Response not ok:', data);
    //             }
    //         } catch (error) {
    //             console.error('Error fetching Klaviyo data:', error);
    //         }
    //     };
    //     fetchKcd();
    // }, []);

    // goslings
    // const [goslings, setGoslings] = useState(0);
    // useEffect(() => {
    //     const fetchGoslings = async () => {
    //         try {
    //             const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/klaviyo/goslings`);
    //             const data = await response.json();

    //             if (response.ok) {
    //                 setGoslings(data.profileCount);
    //             } else {
    //                 console.error('Response not ok:', data);
    //             }
    //         } catch (error) {
    //             console.error('Error fetching Klaviyo data:', error);
    //         }
    //     };
    //     fetchGoslings();
    // }, []);

    // BBS
    // const [bbs, setBbs] = useState(0);
    // useEffect(() => {
    //     const fetchBbs = async () => {
    //         try {
    //             const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/klaviyo/bbs`);
    //             const data = await response.json();

    //             if (response.ok) {
    //                 setBbs(data.profileCount);
    //             } else {
    //                 console.error('Response not ok:', data);
    //             }
    //         } catch (error) {
    //             console.error('Error fetching Klaviyo data:', error);
    //         }
    //     };
    //     fetchBbs();
    // }, []);

    const data = [
        {
            date: '2021-05-21',
            product: 'Makers Mark BBS',
            sign: 310,
            units: 487,
            sales: '$39,115'
        },

        { date: '2021-08-11', product: 'Goslings Papa Seal', sign: 385, units: 11, sales: '$2,225' },
        { date: '2021-12-06', product: 'KCD Bottled in Bond Batch #10', sign: 146, units: 3, sales: '$397' },
        { date: '2022-08-10', product: 'Dalmore 21', sign: 73, units: 0, sales: '0' },
        { date: '2022-08-23', product: 'Copper Tongue Orphan Barrel', sign: 124, units: 2, sales: '$650' },
        { date: '2022-08-31', product: 'Dos Hombres Mezcal Tobala Joven', sign: 84, units: 2, sales: '$1,200' },
        { date: '2022-09-15', product: 'Macallan 25 Year', sign: 340, units: 0, sales: '0' },
        { date: '2022-10-11', product: 'Kentucky Owl Batch 11', sign: 217, units: 2, sales: '$810' },
        { date: '2022-10-25', product: 'Kings County Distillery Blended Bourbon', sign: 319, units: 11, sales: '$754.89' },
        { date: '2022-11-08', product: "Michter's 10 Year Single Barrel Striaght Bourbon", sign: 388, units: 3, sales: '$1,049' },
        { date: '2023-06-30', product: "Orphan Barrel Scarlet Shade", sign: 0, units: 7, sales: '$1,220' },
        { date: '2024-01-11', product: "Michter's Barrel Strength Rye", sign: 0, units: 1, sales: '$249.00' }
    ];

    const sumSigns = data.reduce((accumulator, item) => accumulator + item.sign, 0).toLocaleString();
    const sumSales = data.reduce((accumulator, item) => {
        const salesNumber = Number(item.sales.replace(/[^0-9.-]+/g, ""));
        return accumulator + salesNumber;
    }, 0);
    const formattedSumSales = `$${sumSales.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;

    const [sortOrder, setSortOrder] = useState("asc");

    const sortedData = useMemo(() => {
        const sortableData = [...data];
        sortableData.sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);

            if (sortOrder === "asc") {
                return dateA - dateB;
            } else {
                return dateB - dateA;
            }
        });
        return sortableData;
    }, [sortOrder]);

    const toggleSortOrder = () => {
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    };

    return (
        <div className="dz" style={{ color: colors.SKcustom[10] }}>


            <div className="row10" style={{ marginBottom: "20px", alignItems: "center" }} >
                <img src={Logo} alt="DropZone" style={{ height: "100px" }} />

                <div className="column" style={{ width: "200px" }}>
                    <div className="statHeader2" style={{
                        margin: '0',
                        lineHeight: '1.2', // Adjust this value as needed
                        marginBottom: '5px',
                        color: colors.SKcustom[10]
                    }}>General Sign-ups: <span style={{ marginLeft: '10px', fontWeight: 400, color: colors.SKcustom[17] }}>{klaviyo}</span></div>

                    <div className="statHeader2" style={{
                        margin: '0',
                        lineHeight: '1.2', // Adjust this value as needed
                        marginBottom: '5px',
                        color: colors.SKcustom[10]
                    }}>Specific Sign-ups: <span style={{ marginLeft: '10px', fontWeight: 400, color: colors.SKcustom[17] }}>{sumSigns}</span></div>
                </div>


                <div className="column" style={{
                    borderLeft: "1px solid",
                    borderColor: colors.SKcustom[10],
                    paddingLeft: "10px",
                    width: "200px"
                }}>
                    <p className="statHeader2" style={{
                        margin: '0',
                        lineHeight: '1.2', // Adjust this value as needed
                        marginBottom: '5px',
                        color: colors.SKcustom[10]
                    }}>Sales:</p>
                    <p style={{
                        margin: '0',
                        lineHeight: '1.2',
                        fontSize: '1.4em',
                        color: colors.SKcustom[20] // Ensure consistent line-height
                    }}>{formattedSumSales}</p>
                </div>

            </div>

            <p style={{ color: "magenta" }}>AJ & SK to review for accuracy</p>
            <table className="sktable">
                <thead style={{ textAlign: 'left', color: `${colors.SKcustom[10]}` }}>
                    <tr>
                        <th className="headers" style={{ backgroundColor: `${colors.SKcustom[32]}`, cursor: 'pointer' }} onClick={toggleSortOrder}>
                            Date {sortOrder === "asc" ?
                                <ArrowUpwardIcon style={{ fontSize: 'medium', verticalAlign: 'middle' }} /> :
                                <ArrowDownwardIcon style={{ fontSize: 'medium', verticalAlign: 'middle' }} />}
                        </th>
                        <th className="headers" style={{ backgroundColor: `${colors.SKcustom[32]}` }}>Product</th>
                        <th className="headers" style={{ backgroundColor: `${colors.SKcustom[32]}` }}>Sign-ups</th>
                        <th className="headers" style={{ backgroundColor: `${colors.SKcustom[32]}` }}>Units sold</th>
                        <th className="headers" style={{ backgroundColor: `${colors.SKcustom[32]}` }}>Sales</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedData.map((row, index) => (
                        <tr key={index}>
                            <td className="cells" style={{ width: "10%", borderTop: `0.5px solid ${colors.SKcustom[0]}`, borderBottom: "none", borderLeft: "none", borderRight: "none" }}>{row.date}</td>
                            <td className="cells" style={{ width: "20%", borderTop: `0.5px solid ${colors.SKcustom[0]}`, borderBottom: "none", borderLeft: "none", borderRight: "none" }}>{row.product}</td>
                            <td className="cells" style={{ width: "6%", borderTop: `0.5px solid ${colors.SKcustom[0]}`, borderBottom: "none", borderLeft: "none", borderRight: "none" }}>{row.sign}</td>
                            <td className="cells" style={{ width: "6%", borderTop: `0.5px solid ${colors.SKcustom[0]}`, borderBottom: "none", borderLeft: "none", borderRight: "none" }}>{row.units}</td>
                            <td className="cells" style={{ width: "6%", borderTop: `0.5px solid ${colors.SKcustom[0]}`, borderBottom: "none", borderLeft: "none", borderRight: "none" }}>{row.sales}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default DropZone;
