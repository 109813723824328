import React, { useState } from 'react';
import axios from 'axios';

import SNheader from '../components/SN/brandSN/SNheader';

import Question from '../components/SN/Question';

// import { tokens } from "../theme";
// import { useTheme } from "@mui/material";

const Questionnaire = () => {

    // const theme = useTheme();
    // const colors = tokens(theme.palette.mode);

    const [response, setResponse] = useState({
        recommendationScale: "",
        reason: "",
        level: "",
        beverage: "",
        sites: [],
        feelings: "",
        tactic: [],
    });

    const [currentQuestion, setCurrentQuestion] = useState(0);
    // const [answeredQuestions, setAnsweredQuestions] = useState([]);

    const questions = [
        {
            label: 'How likely are you to recommend Spirits Network to family or friends?',
            type: 'selectOne',
            name: 'recommendationScale',
            choices: [
                '1', '2', '3', '4', '5'
            ]
        },
        {
            label: 'Tell us why.',
            type: 'string',
            name: 'reason',
        },
        {
            label: 'Which of the following describes you as a spirits and cocktail fan?',
            type: 'selectOne',
            name: 'level',
            choices: [
                'Beginner',
                'Been to a bar',
                'Connoisseur'
            ],
        },
        {
            label: 'What beverage most interests you?',
            type: 'string',
            name: 'beverage',
        },
        {
            label: 'Which of the following sites have you heard of:',
            type: 'checkbox',
            name: 'sites',
            choices: [
                'Reserve Bar',
                'Drizly',
                'MiniBar'
            ],
        },
        {
            label: 'Which of the following statements best describes how you feel about Spirits Network?',
            type: 'selectOneV',
            name: 'feelings',
            choices: [
                'Never heard of Spirits Network',
                'I have heard the name Spirits Network',
                'I know what Spirits Network does',
                'Spirits Network stands for something I care about',
                'The Spirits Network brand represents who I am',
                'I would forgive Spirits Network if they messed up',
                'I would defend Spirits Network if they messed up'

            ],
        },

        {
            label: 'Select all you have heard of:',
            type: 'checkbox',
            name: 'tactic',
            choices: [
                'Spirits Network Bottle of the Month',
                'Spirits Network Gifting Site',
                'Spirits Network Engraving',
                'Spirits Network DropZone',
                'Spirits Network Rewards',
                'none'
            ],
        },
    ];

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (type === "checkbox" || type === "selectOne" || type === "selectOneV") {
            if (checked) {
                setResponse(prevState => ({
                    ...prevState,
                    [name]: Array.isArray(prevState[name])
                        ? [...prevState[name], String(value)]
                        : [String(value)]
                }));
            } else {
                setResponse(prevState => ({
                    ...prevState,
                    [name]: prevState[name].filter(item => item !== value)
                }));
            }
        } else if (value.trim() !== "") { // Only capture non-empty strings
            setResponse(prevState => ({ ...prevState, [name]: value }));
        }
    };


    const handleSubmit = async (e) => {
        console.log('response when submitting form', response);
        e.preventDefault();
        try {
            await axios.post(`${process.env.REACT_APP_SERVER_URL}/survey2023`, response);
            alert("Thanks for your feedback!")
        } catch (error) {
            alert("E-R-R-O-R");
        }
    };

    return (

        <div style={{ backgroundColor: "#000", height: "1000px" }}>
            <div style={{ height: "1em" }}></div>
            <div>
                <SNheader />
            </div>

            <div> <h3 style={{ color: "#ab966d", fontWeight: 500, textAlign: "center" }}> Please answer each question then click next.</h3> </div>

            <div className="survey-container">

                <form onSubmit={handleSubmit}>
                    {questions.map((question, index) => (
                        index === currentQuestion && (
                            <Question
                                key={index}
                                index={index}
                                question={question}
                                currentQuestion={currentQuestion}
                                handleChange={handleChange}
                                response={response}
                            />
                        )
                    ))}

                    {/* if we're not at final */}
                    {currentQuestion < questions.length - 1 ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>

                            <button className="surveyB" type="button" onClick={() => { setCurrentQuestion(currentQuestion + 1); }}>Next</button>

                        </div>
                    ) : (
                        <button type="submit">Submit</button>
                    )}
                </form>
            </div>
        </div>
    );
};

export default Questionnaire;