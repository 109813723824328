import React, { useState } from 'react';
import { tokens } from '../../theme';
import { useTheme } from '@mui/material';

const ClientFormButton = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [inputValue, setInputValue] = useState('');
    const [name, setName] = useState('');
    const [checkboxOptions, setCheckboxOptions] = useState({
        email: false,
        slack: false,
        text: false,
    });

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleCheckboxChange = (e) => {
        setCheckboxOptions({
            ...checkboxOptions,
            [e.target.name]: e.target.checked,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let message = `Name: ${name}\nSubmitted Text: ${inputValue}\n\nSelected Options:\n`;
        message += `Email: ${checkboxOptions.email ? 'Yes' : 'No'}\n`;
        message += `Slack: ${checkboxOptions.slack ? 'Yes' : 'No'}\n`;
        message += `Text: ${checkboxOptions.text ? 'Yes' : 'No'}`;

        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/send-email`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    to: 'shannon@nbtvinc.com',
                    subject: 'NBTV request',
                    text: message,

                }),
            });

        const data = await response.text();
        console.log(data); // Handle the response from the email service

        // Resetting form
        setInputValue('');
        setName(''); // Reset name field
        setCheckboxOptions({ email: false, slack: false, text: false });
    };

    return (
        <div className="ask" style={{ backgroundColor: "#1F2A40", color: "#fff" }}>
            <form onSubmit={handleSubmit}>

                <label>
                    Tell us what you need:
                    <input type="text" value={inputValue} onChange={handleInputChange} />
                </label>

                <div>
                    <p style={{ marginBottom: "2px" }}>Where would you like us to respond?</p>
                    <label>
                        Email:
                        <input type="checkbox" name="email" checked={checkboxOptions.email} onChange={handleCheckboxChange} />
                    </label>
                    <label>
                        Slack:
                        <input type="checkbox" name="slack" checked={checkboxOptions.slack} onChange={handleCheckboxChange} />
                    </label>
                    <label>
                        Text:
                        <input type="checkbox" name="text" checked={checkboxOptions.text} onChange={handleCheckboxChange} />
                    </label>
                </div>

                <label>
                    Name:
                    <input type="text" value={name} onChange={handleNameChange} />
                </label>

                <button type="submit" className="neonButton" style={{ marginTop: "10px", marginLeft: "5px", color: colors.SKcustom[17] }}>Submit</button>
            </form>
        </div>
    );
};

export default ClientFormButton;
