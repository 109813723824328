import { useEffect, useState } from "react"
import axios from "axios";
import '../../../../components/visuals/design/sk.css';

const UsbcHistory = () => {

    const [allTimeGross, setAllTimeGross] = useState('');
    const [allTimeOrders, setAllTimeOrders] = useState();
    const [annualOrders, setAnnualOrders] = useState([])
    const [data, setData] = useState([]);

    const [annualCart, setAnnualCart] = useState([])
    const [whipCart, setWhipCart] = useState([])

    const [loading, setLoading] = useState(true);
    const [loadingBackgroundColor, setLoadingBackgroundColor] = useState('lightblue');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [totalGrossResponse, totalOrdersResponse, totalAnnualOrders, annualGrossResponse, totalCartResponse, totalWhipcartResponse] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_SERVER_URL}/usbc/totalgross`),
                    axios.get(`${process.env.REACT_APP_SERVER_URL}/usbc/totalorders`),
                    axios.get(`${process.env.REACT_APP_SERVER_URL}/usbc/annualorders`),
                    axios.get(`${process.env.REACT_APP_SERVER_URL}/usbc/annualgross`),

                    axios.get(`${process.env.REACT_APP_SERVER_URL}/usbcmath/avgcart`),
                    axios.get(`${process.env.REACT_APP_SERVER_URL}/whipmath/avgcart`),
                ]);

                setAllTimeGross(totalGrossResponse.data);
                setAllTimeOrders(totalOrdersResponse.data);
                setAnnualOrders(totalAnnualOrders);
                setData(annualGrossResponse.data);

                setAnnualCart(totalCartResponse.data);
                setWhipCart(totalWhipcartResponse.data);
                setLoading(false);

            } catch (error) {
                console.error("There was an error fetching the data!", error);
            }
        };
        fetchData();

        const colorInterval = setInterval(() => {
            setLoadingBackgroundColor(prevColor => prevColor === 'lightblue' ? 'lightgreen' : 'lightblue');
        }, 1000);
        return () => clearInterval(colorInterval);
    }, []);


    if (loading) {
        return (
            <div style={{
                backgroundColor: loadingBackgroundColor,
                padding: '20px',
                textAlign: 'center',
                color: '#000',
                fontSize: '14px'
            }}>
                Loading...plz hold
            </div>
        );
    }


    return (
        <div className="gradientNeon" style={{ padding: "5px", height: '280px', width: "200px", marginLeft: '5px' }}>
            <div className="metricColumn">
                <div className="metricRow">

                    <table style={{ borderCollapse: "collapse" }}>
                        <thead>
                            <tr>
                                <th style={{ border: "none", padding: "8px" }}></th>
                                <th colSpan="2" style={{ border: "none", padding: "4px", textAlign: "center", backgroundColor: "#003262", color: "#e0e0e0", fontSize: "12px" }}>US Bottles Only</th>
                            </tr>
                            <tr style={{ color: "#000" }}>
                                <th style={{ border: "none", padding: "8px", width: "10%" }}></th>
                                <th style={{ borderBottom: "1px solid black", padding: "8px", fontSize: "12px" }}>Gross Rev</th>
                                <th style={{ borderLeft: "1px solid black", borderBottom: "1px solid black", padding: "8px", fontSize: "12px" }}>Avg. Cart </th>

                            </tr>

                        </thead>
                        <tbody style={{ color: "#000" }}>
                            {data.map((item, index) => (
                                <tr key={index}>
                                    <td style={{ padding: "8px", width: "10%" }}>{item.year}</td>
                                    <td style={{ borderLeft: "1px solid black", padding: "8px" }}>{item.totalGross}</td>

                                    <td style={{ borderLeft: "1px solid black", padding: "8px" }}>{annualCart[index]}</td>
                                    {/* <td style={{ border: "1px solid black", padding: "8px", width: "20%" }}>{whipCart[index]}</td> */}
                                </tr>
                            ))}
                            <tr>
                                <td style={{ border: "none", fontWeight: "bold", padding: "8px", width: "10%" }}>Total</td>
                                <td style={{ borderLeft: "1px solid black", borderBottom: "none", padding: "8px" }}>{allTimeGross}</td>

                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div >
    );
};

export default UsbcHistory;