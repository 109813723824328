import React, { useEffect, useState } from 'react';
import { useTheme } from "@mui/material";
import { Chart } from 'react-google-charts';

import { tokens } from "../../theme";
import "../../components/visuals/design/skSpecial.css";
import hexToRgba from '../visuals/design/hexToRgba';

const VizioGenderSN = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [data, setData] = useState([
        ['Segment', 'Percent', { role: 'style' }, { role: 'annotation' }]
    ]);



    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch gender data
                const genderResponse = await fetch(`${process.env.REACT_APP_SERVER_URL}/viziodemo/gender`);
                if (genderResponse.ok) {
                    const genderFetchedData = await genderResponse.json();

                    // Map gender data to chart format with custom colors and annotations
                    const genderChartData = [['Segment', 'Percent', { role: 'style' }, { role: 'annotation' }]];
                    genderFetchedData.forEach((item, index) => {
                        const barColor = index === 0 ? '#028cf3' : '#f76cc6';
                        genderChartData.push([item.segment, item.percent, barColor, `${item.percent}%`]);
                    });
                    setData(genderChartData);
                } else {
                    console.error('Failed to fetch gender data');
                }


            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const optionsGender = {
        chartArea: { width: '100%', top: 30 },

        title: "",

        titleTextStyle: {
            color: colors.SKcustom[10],
            fontSize: 16,
            bold: true,
        },

        hAxis: {
            minValue: 0,
            gridlines: { color: 'transparent' },
            ticks: [],
            textStyle: {
                fontSize: 12,
                color: '#fff',
            },
        },
        vAxis: {
            minValue: 0,
            textPosition: 'in',
            gridlines: { color: 'transparent' },
            baselineColor: 'transparent',
            ticks: [],
            textStyle: {
                color: '#000'
            },
        },
        legend: { position: 'none' },
        backgroundColor: 'transparent',
        bars: 'vertical',
        bar: { groupWidth: '90%' },
        colors: ['#1c3ba2', '#ab966d'],

        annotations: {
            alwaysOutside: true,
            textStyle: {
                fontSize: 14,
                fontWeight: 700,
                color: '#000',
            },
            stem: { color: 'transparent', length: 10 },
            enableInteractivity: false,
        },
    };


    return (

        <div className="rowPlain" style={{ height: '200px', width: '100%', padding: '0px 10px 0px 0px' }}>
            <div style={{ width: '150px' }} >
                <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="100%"
                    data={data}
                    options={optionsGender}
                />
            </div>


        </div>

    );
};

export default VizioGenderSN;
