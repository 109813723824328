import React, { useState, useEffect } from "react";
import { Box, TextField } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";

import Bc24_line from "./linegraphs/Bc24_line";
import Bc23_line from "./linegraphs/Bc23_line";
import Bc22_line from "./linegraphs/Bc22_line";
import Bc21_line from "./linegraphs/Bc21_line";
import Bc20_line from "./linegraphs/Bc20_line";

import BottleColumns from "../../visuals/columns/BottleColumns";

//functiionality
const BottlesBC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [searchTerm, setSearchTerm] = useState("");
  const [bottles, setBottles] = useState([]);
  const [activeYear, setActiveYear] = useState(2024);

  const fetchAndFormatBottles = async (year) => {
    const yearMap = {
      2024: "four",
      2023: "three",
      2022: "two",
      2021: "one",
      2020: "zero",
    };
    const url = `${process.env.REACT_APP_SERVER_URL}/bc/${yearMap[year]}`;
    const response = await fetch(url);
    const data = await response.json();
    return data.sort((a, b) => new Date(b.date) - new Date(a.date));
  };

  useEffect(() => {
    fetchAndFormatBottles(activeYear).then(setBottles);
  }, [activeYear]);

  const handleButtonClick = (year) => setActiveYear(year);

  const filterRows = (rows, searchTerm) =>
    rows.filter((row) => {
      const fieldValue = Object.values(row)
        .map((value) => (value || "").toString().toLowerCase())
        .join(" ");
      return fieldValue.includes(searchTerm.toLowerCase());
    });

  let filteredRows = filterRows(bottles, searchTerm);

  const graphComponents = {
    2024: <Bc24_line />,
    2023: <Bc23_line />,
    2022: <Bc22_line />,
    2021: <Bc21_line />,
    2020: <Bc20_line />,
  };

  const graphComponent = graphComponents[activeYear] || <Bc24_line />;

  //display
  return (
    <Box m="20px">
      <h2 style={{ marginBottom: "0px" }}>US Bottle Sales</h2>
      <div style={{ marginBottom: "-20px" }}>
        <button
          className="orangeButton"
          onClick={() => handleButtonClick(2020)}
        >
          2020
        </button>
        <button
          className="orangeButton"
          onClick={() => handleButtonClick(2021)}
        >
          2021
        </button>
        <button
          className="orangeButton"
          onClick={() => handleButtonClick(2022)}
        >
          2022
        </button>
        <button
          className="orangeButton"
          onClick={() => handleButtonClick(2023)}
        >
          2023
        </button>
        <button
          className="orangeButton"
          onClick={() => handleButtonClick(2024)}
        >
          2024
        </button>
      </div>
      {graphComponent}

      <TextField
        label="Search Orders"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        variant="standard"
        margin="normal"
        size="small"
        InputLabelProps={{
          style: {
            fontStyle: "italic",
            fontSize: "10px",
          },
        }}
      />

      <Box
        m="5px 0 0 0"
        height="100vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            lineHeight: "10px",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            fontSize: "12px",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
            maxHeight: 50,
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
            fontSize: "10px",
          },
        }}
      >
        <DataGrid
          getRowId={(row) => row._id}
          rows={filteredRows}
          headerHeight={25}
          rowHeight={20}
          columns={BottleColumns}
          components={{ Toolbar: GridToolbar }}
          sortingOrder={["asc", "desc"]}
        />
      </Box>
    </Box>
  );
};

export default BottlesBC;
