import React, { useEffect, useState } from "react"

import { tokens } from '../../theme'
import { useTheme } from '@mui/material'
import '../../components/visuals/design/titles.css'

import Tally1 from "../../components/visuals/tally1";
import Tally2 from "../../components/visuals/tally2";
import RTBstates from "./RTBstates"

//icons
import LiquorIcon from '@mui/icons-material/Liquor';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';


//functiionality
const RTB = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [orders, setOrders] = useState([]);
    useEffect(() => {
        const fetchOrders = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/usbc/rtb`);
            const data = await response.json();
            if (response.ok) {
                setOrders(data);
            }
        };
        fetchOrders();
    }, []);

    const [totals, setTotals] = useState([])
    useEffect(() => {

        const fetchTotals = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/usbc/rtbtotals`);
            const data = await response.json();
            if (response.ok) {
                setTotals(data);
            }
        }
        fetchTotals()
    }, []);

    const formatNumber = (number) => new Intl.NumberFormat('en-US').format(number);

    //top summary
    const totalUnits = Object.values(totals).reduce((acc, curr) => acc + curr.units, 0);
    const totalSubtotals = Object.values(totals).reduce((acc, curr) => acc + curr.subtotal, 0);

    //display
    return (
        <div style={{ marginLeft: 10 }}>

            <div className="top" style={{ color: colors.SKcustom[17], marginBottom: "5px" }}>Raising The Bar
                <span className="subTop" style={{ color: colors.SKcustom[10] }}> • Pernod Ricard</span>
            </div>


            <div className="Stackcontainer">
                <Tally1>
                    <LiquorIcon className="Shannon" />
                    <p style={{ margin: '0', fontSize: 18, fontWeight: 600, textTransform: 'uppercase', color: colors.SKcustom[17] }}>{totalUnits}</p>

                    <p style={{ margin: '0', fontSize: 11, textTransform: 'uppercase' }}>units sold</p>
                </Tally1>

                <Tally2>
                    <PaidOutlinedIcon className="Shannon" />

                    <h3 style={{ margin: '0', fontSize: 18, fontWeight: 600, color: colors.SKcustom[17] }}>${formatNumber(totalSubtotals)}</h3>

                    <p style={{ margin: '0', fontSize: 11, textTransform: 'uppercase' }}>sales</p>
                </Tally2>
            </div>


            <table className="tableSK" style={{ maxWidth: "600px" }}>
                <thead style={{ textAlign: 'left', color: `${colors.SKcustom[10]}` }}>
                    <tr style={{ color: colors.SKcustom[20], }}>
                        <th className="campcolH" style={{ width: "10%", fontWeight: 600 }}> Product</th>
                        <th className="campcolH" style={{ borderLeft: "0.5px solid", width: "3%", fontWeight: 600 }}>Total Units</th>
                        <th className="campcolH" style={{ borderLeft: "0.5px solid", width: "4%", fontWeight: 600 }}>Total Sales</th>
                        <th className="campcolH" style={{ borderLeft: "0.5px solid", width: "3%", fontWeight: 600 }}>2024 Units</th>
                        <th className="campcolH" style={{ borderLeft: "0.5px solid", width: "4%", fontWeight: 600 }}>2024 Sales</th>
                        <th className="campcolH" style={{ borderLeft: "0.5px solid", width: "3%", fontWeight: 600 }}>2023 Units</th>
                        <th className="campcolH" style={{ borderLeft: "0.5px solid", width: "4%", fontWeight: 600 }}>2023 Sales</th>

                    </tr>
                </thead>
                <tbody className="table" style={{ color: `${colors.SKcustom[10]}` }}>
                    {Object.entries(totals)
                        .sort((a, b) => b[1].subtotal - a[1].subtotal) // Sort by subtotal in descending order
                        .map(([product, data], index) => (
                            <tr key={index}>
                                <td className="cells">{product}</td>
                                <td className="cells" style={{ textAlign: "center", backgroundColor: colors.SKcustom[19], fontWeight: 800 }}>{data.units}</td>
                                <td className="cells" style={{ backgroundColor: colors.SKcustom[19], fontWeight: 800 }}>${formatNumber(data.subtotal)}</td>
                                <td className="cells" style={{ textAlign: "center" }}>{orders[product]?.[2024]?.units ?? 0}</td>
                                <td className="cells">${formatNumber(orders[product]?.[2024]?.subtotal ?? 0)}</td>
                                <td className="cells" style={{ textAlign: "center" }}>{orders[product]?.[2023]?.units ?? 0}</td>
                                <td className="cells">${formatNumber(orders[product]?.[2023]?.subtotal ?? 0)}</td>
                            </tr>
                        ))}
                </tbody>
            </table>

            <div style={{ maxWidth: "600px", marginTop: "20px" }}>
                <RTBstates />

            </div>


        </div >
    );
};

export default RTB;