import React, { useState } from "react";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";


import SNacq23 from "../../../hooks/snAcq23";
import SNorg23 from "../../../hooks/snOrg23b";
import SNacq22 from "../../../hooks/snAcq22";
import SNorg22 from "../../../hooks/snOrg22";

const SNprogrammingStats = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [year, setYear] = useState(2023);

    const Vizio2023 = "5,033,259";
    const Vizio2022 = "0"

    return (

        <div >

            {/* SN HIGHLIGHTS */}

            <div className="row" style={{ backgroundColor: colors.SKcustom[18], width: "365px" }}>

                <div className="sh3" style={{ color: "#fff", marginLeft: "10px" }}>{year} Spirits Network Highlights</div>

            </div>

            <div className="rowBorder" style={{ borderColor: colors.SKcustom[10] }}>

                <div className="columnFlexStart">
                    <div className="row"><p style={{ color: "#ab966d", width: "100px", marginTop: "0px", fontWeight: 600 }}>NEW EPISODES:</p></div>

                    <div className="row" style={{ marginTop: "-20px" }}>
                        <div className="columnFlexStart" style={{ marginLeft: "5px" }}>

                            {year === 2023 ? (
                                <div>
                                    <div className="sh2" style={{ color: "#ab966d", }}>
                                        <SNorg23 />
                                    </div>
                                    <p style={{ color: colors.SKcustom[10], marginTop: "-10px", fontSize: "0.8em" }}> ORIGINALS</p>
                                </div>
                            ) : (
                                <div>
                                    <div className="sh2" style={{ color: "#ab966d", }}>
                                        <SNorg22 />
                                    </div>
                                    <p style={{ color: colors.SKcustom[10], marginTop: "-10px", fontSize: "0.8em" }}> ORIGINALS</p>
                                </div>
                            )}
                        </div>

                        <div className="columnFlexStart" >

                            {year === 2023 ? (
                                <div>
                                    <div className="sh2" style={{ color: "#ab966d", }}>
                                        <SNacq23 />
                                    </div>
                                    <p style={{ color: colors.SKcustom[10], marginTop: "-10px", fontSize: "0.8em" }}> ACQUIRED</p>
                                </div>
                            ) : (
                                <div>
                                    <div className="sh2" style={{ color: "#ab966d", }}>
                                        <SNacq22 />
                                    </div>
                                    <p style={{ color: colors.SKcustom[10], marginTop: "-10px", fontSize: "0.8em" }}> ACQUIRED</p>
                                </div>
                            )}
                        </div >
                    </div>
                </div>

                {/* VIZIO */}
                <div className="columnFlexStart">
                    <div className="rowBorder" style={{ borderColor: colors.SKcustom[10] }}>
                        <div className="column">
                            <p style={{ color: "#ab966d", width: "200px", marginTop: "0px", fontWeight: 600 }}>VIZIO MIN STREAMED:</p>
                            <div
                                className="sh2"
                                style={{ color: "#ab966d", marginTop: "-20px" }}>
                                {year === 2023 ? Vizio2023 : Vizio2022}
                            </div>
                        </div>
                    </div>
                </div>
            </div  >

            <button className="neonButtonSquare"
                style={{ color: colors.SKcustom[18], outlineColor: colors.SKcustom[18], marginTop: "0px" }}
                onClick={() => setYear(year === 2023 ? 2022 : 2023)}>
                {year === 2023 ? '2022' : '2023'}
            </button>
        </div >

    )

};

export default SNprogrammingStats;