import React, { useState } from 'react';


const Counter = () => {

    const [count, setCount] = useState(1);

    const increment = () => setCount(prevCount => prevCount + 1);
    const decrement = () => setCount(prevCount => prevCount - 1);

    return (
        <div className="goldBorder"

            style={{
                marginTop: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                background: 'black',
                borderRadius: '20px',
                padding: '10px',
                color: 'white',
                width: '150px'
            }}>

            <button onClick={decrement} style={{ background: 'none', border: 'none', color: 'white', fontSize: '1.5em' }}>-</button>
            <span style={{ fontSize: '1.5em' }}>{count}</span>
            <button onClick={increment} style={{ background: 'none', border: 'none', color: 'white', fontSize: '1.5em' }}>+</button>
        </div>
    );
};


export default Counter