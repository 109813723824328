import { useState, useEffect } from 'react';
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { tokens } from "../theme";
import "react-pro-sidebar/dist/css/styles.css";


import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import ConnectedTvOutlinedIcon from '@mui/icons-material/ConnectedTvOutlined';
import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined";
import EqualizerOutlinedIcon from "@mui/icons-material/HomeOutlined";
import EqualizerIcon from '@mui/icons-material/Equalizer';
import LiquorIcon from '@mui/icons-material/Liquor';
import LocalDrinkOutlinedIcon from '@mui/icons-material/LocalDrinkOutlined';
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import useWindowSize from '../hooks/useWindowSize';
import QuizIcon from '@mui/icons-material/Quiz';
import EmojiFoodBeverageOutlinedIcon from '@mui/icons-material/EmojiFoodBeverageOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import RestaurantOutlinedIcon from '@mui/icons-material/RestaurantOutlined';
import GolfCourseIcon from '@mui/icons-material/GolfCourse';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography style={{ fontSize: 12 }}>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = (props) => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("");

  const windowSize = useWindowSize();
  // console.log('what is window size', windowSize)

  useEffect(() => {
    if (windowSize.width < 600) {
      setIsCollapsed(true)
    }
    else {
      setIsCollapsed(false)
    }

  }, [windowSize]);

  const resp = {
    "@media (maxWidth: 400px)": {
      ".pro-sidebar > .pro-sidebar-layout": {
        transform: "translateX(-100%)",
        transition: "transform 0.3s ease-in-out",
      },

      ".pro-sidebar.collapsed > .pro-sidebar-layout": {
        transform: "translateX(0)",
      },
    },
  };

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "2px 2px 2px 2px !important",
        },

        "& .pro-inner-list-item": {
          background: `${colors.primary[400]} !important`,
          width: '15em'
        },

        "& .pro-inner-item:hover": {
          color: "#f38447 !important",
        },
        "& .pro-menu-item.active": {
          color: "#ab966d !important",
        },
      }}
    >
      <ProSidebar
        style={resp}
        collapsed={isCollapsed}
        width="150px"
        collapsedWidth={"50px"}
      >
        <Menu iconShape="square">

          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "0 0 0 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="2px"
              >
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="10px">

              <Box className="notSureJustifyContent">
                <img
                  alt="profile-user"
                  width="70px"
                  height="70px"
                  src={`../../assets/bitmoji.png`}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>

              <Box textAlign="center">

                <Typography
                  variant="h3"
                  fontWeight="bold"
                  color="#ab966d"
                  sx={{ m: "10px 0 0 0", fontSize: 14 }}
                >
                  NBTV
                </Typography>

              </Box>

            </Box>
          )}

          {props.user ?

            <Box paddingLeft={isCollapsed ? undefined : "1%"}>



              <Item
                title="NBTV Resources"
                to="/Resources"
                icon={<FolderCopyOutlinedIcon sx={{ fontSize: 12 }} />}
                selected={selected}
                setSelected={setSelected}
              />

              <Item
                title="Dashboards"
                to="/"
                icon={<EqualizerIcon sx={{ fontSize: 12 }} />}
                selected={selected}
                setSelected={setSelected}
              />

              <Item style={{ color: `${colors.SKcustom[10]}` }}
                title="Mktg Calendar"
                to="/Calendar"
                icon={<CalendarMonthOutlinedIcon sx={{ fontSize: 12 }} />}
                selected={selected}
                setSelected={setSelected}
              />

              <Item style={{ color: `${colors.SKcustom[10]}` }}
                title="Content Library"
                to="/Programming"
                icon={<ConnectedTvOutlinedIcon sx={{ fontSize: 12 }} />}
                selected={selected}
                setSelected={setSelected}
              />




              <Typography
                variant="h4"
                color="#ab966d"
                sx={{ m: "15px 0 5px 5px", fontSize: 14 }}>
                SN Quick Links
              </Typography>


              <Item
                title="'24 Highlights"
                to="/Highlights"
                icon={<StarOutlineOutlinedIcon sx={{ fontSize: 12 }} />}
                selected={selected}
                setSelected={setSelected}
              />

              <Item
                title=" Campari 24"
                to="/Negroni24"
                icon={<LiquorIcon sx={{ fontSize: 12 }} />}
                selected={selected}
                setSelected={setSelected}
              />

              <Item
                title="The Pantry"
                to="/pantry"
                icon={<RestaurantOutlinedIcon sx={{ fontSize: 12 }} />}
                selected={selected}
                setSelected={setSelected}
              />

              <Item
                title=" BOTM"
                to="/BOTM"
                icon={<LiquorIcon sx={{ fontSize: 12 }} />}
                selected={selected}
                setSelected={setSelected}
              />


              <Item
                title="SN UK"
                to="/UK"
                icon={<EmojiFoodBeverageOutlinedIcon sx={{ fontSize: 12 }} />}
                selected={selected}
                setSelected={setSelected}
              />



              <Typography
                variant="h4"
                color="#ab966d"
                sx={{ m: "15px 0 5px 5px", fontSize: 14 }}>
                Beta
              </Typography>

              <Item
                title="Shoppable TV"
                to="/ShoppableTV"
                icon={<QuizIcon sx={{ fontSize: 12 }} />}
                selected={selected}
                setSelected={setSelected}
              />

              <Item
                title="Test for AJ"
                to="/Quest"
                icon={<QuizIcon sx={{ fontSize: 12 }} />}
                selected={selected}
                setSelected={setSelected}
              />

            </Box>
            //what if they dont exist
            : null
          }
        </Menu>
      </ProSidebar>
    </Box >
  );
};

export default Sidebar;