import { tokens } from "../theme"
import { useTheme } from "@mui/material"
import '../components/visuals/design/sk.css'
import GNLogo from "../img/GN.png"
import GNLogo2 from "../img/GN_blue.png"

import GNsalesList from "../components/GN/GNsalesList"

const SalesGN = () => {

    //aesthetics
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    function hexToRgba(hex, opacity) {
        hex = hex.replace('#', '');
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
    }

    return (
        <div style={{ marginLeft: '10px' }}>

            {/* //GNLOGO */}
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                    <img src={theme.palette.mode === 'dark' ? GNLogo : GNLogo2} alt="Image 3" style={{ width: '175px', marginBottom: "-10px", marginTop: '-10px' }} />
                </div>
                <GNsalesList />
            </div>

        </div>
    );
};

export default SalesGN;

