const HexToRgba = (hex, opacity) => {
    // Check if 'hex' is undefined or not a string
    if (typeof hex !== 'string') {
        console.error('Invalid input: hex value is not a string.');
        return 'rgba(0,0,0,0)'; // Return a default color 
    }
    hex = hex.replace('#', '');
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
}
export default HexToRgba