import React from 'react';

import channels_black from '../../../../src/img/channels_black.png'
import channels_white from '../../../../src/img/channels.png'


// NBTVLogo component
const ChannelsLogo = ({ mode }) => {
    // Choose the logo based on the mode
    const logo = mode === 'dark' ? channels_white : channels_black;

    return (
        <img src={logo} alt="Channels Logo" className="kit" />
    );
};

export default ChannelsLogo;