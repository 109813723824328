import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material';
import { tokens } from '../theme';

// import UniverseLogo from '../components/visuals/logoMode/logoModeUniverse';

import '../components/visuals/design/videoMetrics.css'



const AudienceGN = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    function hexToRgba(hex, opacity) {
        hex = hex.replace('#', '');
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
    }

    const [list, setList] = useState([]);
    useEffect(() => {
        const fetchList = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/profilebc/state`);
                if (response.ok) {
                    const data = await response.json();
                    setList(data);
                } else {
                    console.error('Failed to fetch data');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchList();
    }, []);

    const [activeRows, setActiveRows] = useState([]);

    const toggleRow = (index) => {
        if (activeRows.includes(index)) {
            setActiveRows(activeRows.filter(row => row !== index));
        } else {
            setActiveRows([...activeRows, index]);
        }
    };

    return (
        <div className="fullColumn">
            {/* 
            <SNcaseStudy /> */}

            <h1>Under Construction</h1>

            {[{ label: 'USER BEHAVIOR' }, { label: 'DEMOGRAPHICS' }].map((item, index) => (
                <div className="hRow" key={index} onClick={() => toggleRow(index)} style={{ cursor: 'pointer', width: '100%' }}>
                    {!activeRows.includes(index) && (
                        <div className="audienceBox" style={{
                            borderWidth: '2px',
                            borderColor: `${hexToRgba(colors.SKcustom[11], 2)}`,
                            borderStyle: 'solid',
                            padding: '0px 10px 3px 10px',
                            marginBottom: '10px'
                        }}>
                            <p style={{ color: colors.SKcustom[10] }}>{item.label}</p>
                        </div>
                    )}
                    {activeRows.includes(index) && (
                        <div style={{
                            width: '100%',
                            backgroundColor: 'lightgrey',
                            padding: '10px',
                            border: '1px solid grey',
                            boxSizing: 'border-box'
                        }}>
                            <p style={{ color: colors.SKcustom[15] }}> {item.label}</p>

                            <div >
                                {item.label === 'USER BEHAVIOR' && (
                                    <div style={{ marginTop: -10 }}>

                                        <div >
                                            <p style={{ fontSize: '9px', marginBottom: 0, color: '#525252', fontWeight: 600 }}>2024 BigCommerce • USA</p>
                                            <div className="hRow">
                                                <div className="chartRow">

                                                </div>
                                                <div className="chartRow">

                                                </div>
                                            </div>
                                        </div>

                                        <div >
                                            <p style={{ fontSize: '9px', marginBottom: 0, color: '#525252', fontWeight: 600 }}>2024 Google Analytics • SN USA ONLY</p>

                                        </div>

                                        <div >
                                            <p style={{ fontSize: '9px', marginBottom: 0, color: '#525252', fontWeight: 600 }}>2024 Meta • SN USA ONLY</p>

                                        </div>



                                    </div>

                                )}
                                {item.label === 'DEMOGRAPHICS' && (
                                    <div>

                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default AudienceGN;

//////SARATOGA MATH////////////////////////////////////////////////////////////
//OBJECTIVE
//cost per view v. all views received.Additionally, 
//can you provide any production value(e.g., custom hub) you’d like us to capture ? 
// const Test = () => {

//     const [ytSaratoga, setYtSaratoga] = useState(0);

//     useEffect(() => {

//         const fetchTotal = async () => {
//             try {
//                 const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantryyoutube/saratoga`);
//                 const data = await response.json();
//                 if (response.ok) {
//                     setYtSaratoga(Number(data));
//                 }
//             } catch (error) {
//                 console.error('Error fetching YouTube data:', error);
//             }
//         };

//         fetchTotal();

//     }, [])


//     return (
//         <div style={{ color: "white" }}>

//             <p>{ytSaratoga}</p>

//         </div>
//     )

// }
// export default Test

//////ENGRAVING////////////////////////////////////////////////////////////
// const Test = () => {

//     // const theme = useTheme();
//     // const colors = tokens(theme.palette.mode);

//     // color: rgba(255, 255, 255, 0.6);

//     const [pool, setPool] = useState([])
//     useEffect(() => {
//         const fetchPool = async () => {
//             const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pool`);
//             const data = await response.json();

//             if (response.ok) { setPool(data) }
//         }
//         fetchPool();
//     }, [])

// const downloadCSV = () => {
//     const headers = Object.keys(pool[0]).join(',');
//     const rows = pool.map(row => Object.values(row).join(',')).join('\n');
//     const csvContent = `data:text/csv;charset=utf-8,${headers}\n${rows}`;
//     const encodedUri = encodeURI(csvContent);
//     const link = document.createElement('a');
//     link.setAttribute('href', encodedUri);
//     link.setAttribute('download', 'pool_data.csv');
//     document.body.appendChild(link); // Required for FF
//     link.click();
//     document.body.removeChild(link);
// };



//     return (
//         <div>
//             <button onClick={downloadCSV}>Download CSV</button>
//             <table>
//                 <thead>
//                     <tr>
//                         {pool.length > 0 && Object.keys(pool[0]).map((key) => (
//                             <th key={key}>{key}</th>
//                         ))}
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {pool.map((item, index) => (
//                         <tr key={index}>
//                             {Object.values(item).map((value, idx) => (
//                                 <td key={idx}>{value}</td>
//                             ))}
//                         </tr>
//                     ))}
//                 </tbody>
//             </table>
//         </div>
//     )
// }