import { useEffect, useState, useRef } from 'react';
import { tokens } from "../theme";
import { useTheme } from '@mui/material';
import hexToRgba from '../components/visuals/design/hexToRgba';
import { useNavigate } from 'react-router-dom';
import '../components/visuals/design/treasure.css';


const Treasure5 = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate(); // Initialize useNavigate hook

    const [inputs, setInputs] = useState({ 1: '', 2: '', 3: '', 4: '' });
    const [isCodeCorrect, setIsCodeCorrect] = useState(false);

    const inputRefs = {
        1: useRef(null),
        2: useRef(null),
        3: useRef(null),
        4: useRef(null),
    };

    const handleInputChange = (e, index) => {
        const value = e.target.value;
        if (value.length <= 1 && /^[0-9]*$/.test(value)) {
            // Update input value
            const newInputs = { ...inputs, [index]: value };
            setInputs(newInputs);

            // Move to the next input if not the last one
            if (value && index < 4) {
                inputRefs[index + 1].current.focus();
            }

            // Check if the code is correct
            if (newInputs[1] === '0' && newInputs[2] === '3' && newInputs[3] === '0' && newInputs[4] === '6') {
                setIsCodeCorrect(true);
            } else {
                setIsCodeCorrect(false);
            }
        }
    };

    const handleBackspace = (e, index) => {
        if (e.key === 'Backspace' && index > 1 && !inputs[index]) {
            // Move to the previous input if backspace is pressed and current box is empty
            inputRefs[index - 1].current.focus();
        }
    };

    const handleSubmit = () => {
        if (isCodeCorrect) {
            navigate('/treasure4');
        } else {
            alert("Incorrect Code! Please try again.");
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ color: hexToRgba('#66FF00', 0.9), fontSize: '20px', display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                <div>The treasue is buried under the old bench.</div>

            </div>


        </div>
    );
};

export default Treasure5;
