import React from 'react';
// import { tokens } from "../../theme";
// import { useTheme } from "@mui/material";

import Logo from '../../../img/SNlogo.png';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import PersonIcon from '@mui/icons-material/Person';

function SNheader() {

    // const theme = useTheme();
    // const colors = tokens(theme.palette.mode);

    return (
        <div className="SNheader" style={{ backgroundColor: "#000" }}>
            <div >
                <img src={Logo} alt="Spirits Network" height="30em" />
            </div>

            <div className="header-buttons" style={{ color: "#ffffff" }}>
                <button onClick={() => window.location.href = "https://discover.spiritsnetwork.com/?_ga=2.152161721.1047278354.1696537150-732836063.1695174206#/taste"} style={{ color: "#ffffff" }}>LIVE</button>
                <button onClick={() => window.location.href = "https://discover.spiritsnetwork.com/#/home"} style={{ color: "#ffffff" }}>EXPLORE</button>
                <button onClick={() => window.location.href = "https://discover.spiritsnetwork.com/#/spirits"} style={{ color: "#ffffff" }}>SERIES</button>
                <button onClick={() => window.location.href = "https://discover.spiritsnetwork.com/#/spirits"} style={{ color: "#ffffff" }}>SPIRITS</button>
                <button onClick={() => window.location.href = "https://discover.spiritsnetwork.com/#/store"} style={{ color: "#ffffff" }}>SHOP</button>
                <button onClick={() => window.location.href = "https://discover.spiritsnetwork.com/#/brands"} style={{ color: "#ffffff" }}>BRANDS</button>
                <button onClick={() => window.location.href = "https://discover.spiritsnetwork.com/#/hosts"} style={{ color: "#ffffff" }}>HOSTS</button>
            </div>

            <div className="header-icons" style={{ color: "#ffffff" }}>
                <SearchIcon style={{ marginRight: '15px' }} />
                <ShoppingCartOutlinedIcon style={{ marginRight: '15px' }} />
                <PersonIcon />

            </div>
        </div>
    );
}

export default SNheader;
