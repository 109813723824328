import { useEffect, useState } from "react"
import { tokens } from "../../theme"
import { useTheme } from '@mui/material';

import { fetchTotalPantryImps } from "./TotalPantryImp";
import { fetchTotalPantryViews } from "./TotalPantryViews"

const CrownMaple = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const promisedImpressions = 400000
    const promisedViews = 40000

    //Meta
    const [metaImp, setMetaImp] = useState(0);
    useEffect(() => {
        const fetchMetaImp = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantryfb/crownimp`);
            const data = await response.json();
            if (response.ok) {
                setMetaImp(data);
            }
        }
        fetchMetaImp()
    }, [])

    //ALL IMP
    const [totalImp, setTotalImp] = useState(0);
    useEffect(() => {
        const fetchTotalImp = async () => {
            const total = await fetchTotalPantryImps();
            setTotalImp(total);
        };
        fetchTotalImp();
    }, []);

    //ALL Views
    const [totalViews, setTotalViews] = useState(0);
    useEffect(() => {
        const fetchTotalViews = async () => {
            const total = await fetchTotalPantryViews();
            setTotalViews(total);
        };
        fetchTotalViews();
    }, []);

    const percentDifferenceViews = ((totalViews - promisedViews) / promisedViews) * 100;
    const percentDifferenceImpressions = ((totalImp - promisedImpressions) / promisedImpressions) * 100;


    return (
        <div>
            <table>
                <thead>
                    <tr >
                        <th style={{ border: 'none', height: "10px" }}></th>
                        <th style={{ color: "#F6B65C", border: 'none', borderColor: "#F6B65C", fontSize: "0.9em", height: "10px", textAlign: 'left', paddingBottom: "0px" }}>IMPRESSIONS</th>
                        <th style={{ color: "#F6B65C", border: 'none', borderColor: "#F6B65C", fontSize: "0.9em", height: "10px", textAlign: 'left', paddingBottom: "0px" }}>VIEWS</th>
                    </tr>
                </thead>
                <tbody style={{ color: colors.SKcustom[10] }}>
                    <tr>
                        <td style={{ border: 'none', fontSize: "1em" }}>Promised</td>
                        <td style={{ fontSize: "1em", border: 'none' }}>{promisedImpressions.toLocaleString()}</td>
                        <td style={{ fontSize: "1em", border: 'none' }}>{promisedViews.toLocaleString()}</td>
                    </tr>
                    <tr>
                        <td style={{ border: 'none', fontSize: "1em" }}>Actual</td>
                        <td style={{ fontSize: "1em", border: 'none' }}>{totalImp.toLocaleString()}</td>
                        <td style={{ fontSize: "1em", border: 'none' }}>{totalViews.toLocaleString()}</td>
                    </tr>

                    <tr>
                        <td style={{ border: 'none', color: "#F6B65C", fontSize: "1em" }}>Difference</td>
                        <td style={{ fontSize: "1em", border: 'none', color: "#F6B65C" }}>{percentDifferenceImpressions.toFixed(2)}%</td>
                        <td style={{ fontSize: "1em", border: 'none', color: "#F6B65C" }}>{percentDifferenceViews.toLocaleString()}%</td>
                    </tr>
                    <tr>
                        <td style={{ border: 'none', fontSize: "1em", color: colors.SKcustom[33] }}>Custom Ads</td>
                        <td style={{ fontSize: "1em", border: 'none', color: colors.SKcustom[33] }}>{metaImp.toLocaleString()}</td>
                        <td style={{ fontSize: "1em", border: 'none', color: colors.SKcustom[33] }}>0</td>
                    </tr>
                </tbody>
            </table>
            <p style={{ color: colors.SKcustom[33], fontSize: "10px", marginLeft: "15px", marginTop: "0px" }}>*Custom Ads = how many of the Actuals were specifically branded for client</p>
        </div>
    );


};
export default CrownMaple