import { useEffect, useState } from "react"
import { Bar } from "react-chartjs-2";

//aesthetics
import { tokens } from "../../theme"
import { useTheme } from '@mui/material';

import '../../components/visuals/design/videoMetrics.css';
import '../../components/visuals/design/sk.css';

const PantryYT = () => {

    //aesthetics
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    function hexToRgba(hex, opacity) {
        hex = hex.replace('#', '');
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
    }

    const [ytSeries, setYtSeries] = useState(0)
    useEffect(() => {
        const fetchTotal = async () => {

            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantryyoutube`);
            const data = await response.json();

            if (response.ok) {
                setYtSeries(Number(data))
            }
        }
        fetchTotal()
    }, [])

    const [ytSaratoga, setYtSaratoga] = useState(0)
    useEffect(() => {
        const fetchTotal = async () => {

            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantryyoutube/saratoga`);
            const data = await response.json();

            if (response.ok) {
                setYtSaratoga(Number(data))
            }
        }
        fetchTotal()
    }, [])

    const googleAdData = [
        { video: "Series", imps: ytSeries },
        { video: "Saratoga", imps: ytSaratoga },
    ]

    const chartData = {
        labels: googleAdData.map(item => item.video),
        datasets: [{
            label: 'Imps',
            data: googleAdData.map(item => (item.imps)),
            borderColor: "white",
            borderWidth: 1,
            backgroundColor: colors.SKcustom[12],
            // Custom property to store original string reach for tooltips
            impsStrings: googleAdData.map(item => item.imps),
        }]
    };

    const options = {
        scales: {
            y: {
                beginAtZero: false,
                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    display: false,
                    color: colors.SKcustom[10],

                }
            },
            x: {
                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    font: {
                        size: "10px",
                    },
                    color: colors.SKcustom[10],
                }
            }
        },
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                anchor: 'end',
                align: 'top',
                formatter: (value, context) => { return value.toLocaleString(); },
                color: colors.SKcustom[10],
                font: {
                    size: 10,
                }
            },
        },
        layout: {
            padding: {
                top: 20,
                bottom: 10,
                left: 0,
                right: 0,
            },
        },
        maintainAspectRatio: false,
        backgroundColor: 'transparent',
    };

    return (
        <div style={{ width: "300px", height: "125px", backGround: 'transparent', marginLeft: "50px" }}>
            <Bar data={chartData} options={options} />
        </div>
    );
};
export default PantryYT