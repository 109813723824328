import { useEffect, useState, useRef } from 'react';
import { tokens } from "../theme";
import { useTheme } from '@mui/material';
import hexToRgba from '../components/visuals/design/hexToRgba';
import { useNavigate } from 'react-router-dom';
import '../components/visuals/design/treasure.css';


const Treasure2 = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate(); // Initialize useNavigate hook

    const [inputs, setInputs] = useState({ 1: '', 2: '', 3: '', 4: '' });
    const [isCodeCorrect, setIsCodeCorrect] = useState(false);

    const inputRefs = {
        1: useRef(null),
        2: useRef(null),
        3: useRef(null),
        4: useRef(null),
    };

    const handleInputChange = (e, index) => {
        const value = e.target.value;
        if (value.length <= 1 && /^[0-9]*$/.test(value)) {
            // Update input value
            const newInputs = { ...inputs, [index]: value };
            setInputs(newInputs);

            // Move to the next input if not the last one
            if (value && index < 4) {
                inputRefs[index + 1].current.focus();
            }

            // Check if the code is correct
            if (newInputs[1] === '0' && newInputs[2] === '3' && newInputs[3] === '0' && newInputs[4] === '6') {
                setIsCodeCorrect(true);
            } else {
                setIsCodeCorrect(false);
            }
        }
    };

    const handleBackspace = (e, index) => {
        if (e.key === 'Backspace' && index > 1 && !inputs[index]) {
            // Move to the previous input if backspace is pressed and current box is empty
            inputRefs[index - 1].current.focus();
        }
    };

    const handleSubmit = () => {
        if (isCodeCorrect) {
            navigate('/treasure3');
        } else {
            alert("Incorrect Code! Please try again.");
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ color: hexToRgba('#66FF00', 0.9), fontSize: '20px', display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                <div>1. Start where letters go to leave.</div>
                <div>2. March 20 paces north and 40 paces west.</div>
                <div>3. Enter the code.</div>
            </div>

            {/* Input fields for the 4-digit code */}
            <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
                {[1, 2, 3, 4].map(index => (
                    <input
                        key={index}
                        type="text"
                        value={inputs[index]}
                        onChange={(e) => handleInputChange(e, index)}
                        onKeyDown={(e) => handleBackspace(e, index)}
                        maxLength="1"
                        ref={inputRefs[index]}
                        style={{
                            width: '40px',
                            height: '40px',
                            fontSize: '24px',
                            textAlign: 'center',
                            border: `2px solid ${hexToRgba(colors.SKcustom[50], 0.9)}`,
                            borderRadius: '5px',
                            color: '#000',
                            backgroundColor: '#fff',
                        }}
                    />
                ))}
            </div>

            {/* Submit Button */}
            <button
                onClick={handleSubmit}
                className="next-button"
                style={{
                    padding: '10px 20px',
                    fontSize: '18px',
                    cursor: 'pointer',
                    backgroundColor: isCodeCorrect ? 'green' : 'gray', // Change button color based on code correctness
                    color: '#fff',
                    border: 'none',
                    borderRadius: '5px',
                }}
            >
                Submit
            </button>
        </div>
    );
};

export default Treasure2;
