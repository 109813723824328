import { tokens } from "../theme"
import { useTheme } from "@mui/material"


const BrandSalesGN = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (

        <div style={{ marginLeft: "10px" }}>

            <div>
                <h2 style={{ color: colors.SKcustom[10], fontWeight: 600, paddingLeft: "10px", marginBottom: "-5px" }}>Golf Nation</h2>

                <p style={{ backgroundColor: colors.grey[100], color: colors.SKcustom[15], paddingLeft: "5px", marginLeft: "10px", maxWidth: "320px", fontSize: "11px" }}>Not enough data.</p>

            </div>
        </div>

    );

};

export default BrandSalesGN;

