import React, { useEffect, useState } from "react";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import '../../visuals/design/tables.css'

import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';

const BrandComaprison = () => {

    //aesthetics
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    function hexToRgba(hex, opacity) {
        hex = hex.replace('#', '');
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
    }

    //functionality
    const [data, setData] = useState({ total: [], byYear: {} });

    useEffect(() => {
        const fetchHoldingData = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/usbc/holdinglist`);
            const json = await response.json();
            if (response.ok) {
                setData(json[0]); // Assuming the JSON structure provided is the exact response
            }
        };
        fetchHoldingData();
    }, []);

    //usd
    const formatUSD = (amount) => { return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount); };

    //row click
    const baseUrl = window.location.origin; // Changed to origin to get the base URL
    const handleRowClick = (holdingId) => {
        console.log('Navigating to holding:', holdingId);
        window.open(`${baseUrl}/client/${holdingId}`, '_blank');
    };

    //group by year
    // const getYearData = (holding, year) => {
    //     const key = `${holding}_${year}`;
    //     const yearData = data.byYear[key];
    //     if (yearData) {
    //         return {
    //             units: yearData.totalUnits,
    //             amount: formatUSD(yearData.totalAmount)
    //         };
    //     }
    //     return { units: 0, amount: formatUSD(0) };
    // };

    //render data
    const renderData = () => {
        let sortedData = [...data.total];

        // Sorting logic
        if (sortConfig.key !== null) {
            sortedData.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }

        return sortedData.map((item, index) => (
            <tr key={item._id} onClick={() => handleRowClick(item._id)}
                style={{
                    cursor: 'pointer',
                    backgroundColor: index % 2 === 1 ? 'transparent' : colors.SKcustom[700],
                    height: "1.8em"
                }}>
                <td>{item._id}</td>
                <td>{item.totalUnits}</td>
                <td>{formatUSD(item.totalAmount)}</td>

                {/* {[2024, 2023, 2022, 2021, 2020].map(year => {
                    const yearData = getYearData(item._id, year);
                    return (
                        <React.Fragment key={year}>
                            <td style={{ borderLeft: "0.5px solid" }}>{yearData.units}</td>
                            <td>{yearData.amount}</td>
                        </React.Fragment>
                    );
                })} */}
            </tr>
        ));
    };

    const [sortConfig, setSortConfig] = useState({ key: 'totalUnits', direction: 'descending' });

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'descending') {
            direction = 'ascending';
        } else {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };


    return (
        <div>

            <table className="tableSK" style={{ maxWidth: "800px" }}>
                <thead style={{ textAlign: 'left', color: `${colors.SKcustom[10]}` }}>
                    <tr style={{ color: colors.SKcustom[20] }}>
                        <th>Holding</th>
                        <th className="colHeader" onClick={() => requestSort('totalUnits')}>Total Units<ExpandMoreOutlinedIcon style={{ fontSize: "12px" }} /></th>
                        <th className="colHeader" onClick={() => requestSort('totalAmount')}>Total Rev<ExpandMoreOutlinedIcon style={{ fontSize: "12px" }} /></th>
                        {/* {['2024', '2023', '2022', '2021', '2020'].map(year => (
                            <>
                                <th className="colHeader" style={{ borderLeft: "0.5px solid" }}>{year} </th>
                                <th className="colHeader"  > </th>
                            </>
                        ))} */}
                    </tr>
                </thead>
                <tbody style={{ color: `${colors.SKcustom[10]}` }}>
                    {renderData()}
                </tbody>
            </table>
        </div>
    );
};
export default BrandComaprison;

//   <th className="campcolH" style={{ width: "10%", fontWeight: 600 }}> Holding</th>
//         <th className="campcolH" style={{ borderLeft: "0.5px solid", width: "3%", fontWeight: 600 }}>Total Units</th>
//         <th className="campcolH" style={{ borderLeft: "0.5px solid", width: "4%", fontWeight: 600 }}>Total Amount</th>
//         <th className="campcolH" style={{ borderLeft: "0.5px solid", width: "3%", fontWeight: 600 }}>2024 Units</th>
//         <th className="campcolH" style={{ borderLeft: "0.5px solid", width: "4%", fontWeight: 600 }}>2024 Amount</th>
//         <th className="campcolH" style={{ borderLeft: "0.5px solid", width: "3%", fontWeight: 600 }}>2023 Units</th>
//         <th className="campcolH" style={{ borderLeft: "0.5px solid", width: "4%", fontWeight: 600 }}>2023 Amount</th>
//         <th className="campcolH" style={{ borderLeft: "0.5px solid", width: "3%", fontWeight: 600 }}>2022 Units</th>
//         <th className="campcolH" style={{ borderLeft: "0.5px solid", width: "4%", fontWeight: 600 }}>2022 Amount</th>
//         <th className="campcolH" style={{ borderLeft: "0.5px solid", width: "3%", fontWeight: 600 }}>2021 Units</th>
//         <th className="campcolH" style={{ borderLeft: "0.5px solid", width: "4%", fontWeight: 600 }}>2021 Amount</th>
//         <th className="campcolH" style={{ borderLeft: "0.5px solid", width: "3%", fontWeight: 600 }}>2020 Units</th>
//         <th className="campcolH" style={{ borderLeft: "0.5px solid", width: "4%", fontWeight: 600 }}>2020 Amount</th>