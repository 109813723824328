import { useEffect, useState } from "react"
import { Bar } from "react-chartjs-2";

//aesthetics
import { tokens } from "../../theme"
import { useTheme } from '@mui/material';

import '../../components/visuals/design/videoMetrics.css';
import '../../components/visuals/design/sk.css';

const FreqMinWatched = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [freq1, setfreq1] = useState(0)
    useEffect(() => {
        const fetchfreq1 = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/temp/freq1`);
            const data = await response.json();
            if (response.ok) {
                setfreq1(data);
            }
        }
        fetchfreq1()
    }, [])

    const [freq2, setfreq2] = useState(0)
    useEffect(() => {
        const fetchfreq2 = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/temp/freq2`);
            const data = await response.json();
            if (response.ok) {
                setfreq2(data);
            }
        }
        fetchfreq2()
    }, [])

    const [freq3, setfreq3] = useState(0)
    useEffect(() => {
        const fetchfreq3 = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/temp/freq3`);
            const data = await response.json();
            if (response.ok) {
                setfreq3(data);
            }
        }
        fetchfreq3()
    }, [])

    const [freq4, setfreq4] = useState(0)
    useEffect(() => {
        const fetchfreq4 = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/temp/freq4`);
            const data = await response.json();
            if (response.ok) {
                setfreq4(data);
            }
        }
        fetchfreq4()
    }, [])

    const [freq5, setfreq5] = useState(0)
    useEffect(() => {
        const fetchfreq5 = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/temp/freq5`);
            const data = await response.json();
            if (response.ok) {
                setfreq5(data);
            }
        }
        fetchfreq5()
    }, [])

    const [freq6, setfreq6] = useState(0)
    useEffect(() => {
        const fetchfreq6 = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/temp/freq6`);
            const data = await response.json();
            if (response.ok) {
                setfreq6(data);
            }
        }
        fetchfreq6()
    }, [])


    //minutes watched
    const frequencyData = [
        { episode: "ep. 1 Marcus", views: freq1 },
        { episode: "ep. 2 Kristen", views: freq2 },
        { episode: "ep. 3 Liev", views: freq3 },
        { episode: "ep. 4 JJ", views: freq4 },
        { episode: "ep. 5 Stephanie", views: freq5 },
        { episode: "ep. 6 Spike", views: freq6 }
    ]

    const chartData = {
        labels: frequencyData.map(item => item.episode),
        datasets: [{
            label: 'Views',
            data: frequencyData.map(item => (item.views)), // For plotting
            backgroundColor: colors.SKcustom[12],
            // Custom property to store original string views for tooltips
            viewsStrings: frequencyData.map(item => item.views),
        }]
    };

    const options = {
        scales: {
            y: {
                beginAtZero: false,
                grid: {
                    display: false, // This will remove the grid lines
                    drawBorder: false, // This will remove the axis line
                },
                ticks: {
                    display: false,
                    color: colors.SKcustom[10],

                }
            },
            x: {
                grid: {
                    display: false, // This will remove the grid lines
                    drawBorder: false, // This will remove the axis line
                },
                ticks: {
                    font: {
                        size: "10px", // Example: setting the base font size to 20px; adjust as needed
                    },
                    color: colors.SKcustom[10],
                }
            }
        },
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                anchor: 'end',
                align: 'top',
                formatter: (value, context) => { return value.toLocaleString(); },
                color: colors.SKcustom[10],
                font: {
                    size: 9,
                }
            },
        },
        layout: {
            padding: {
                top: 20,
                bottom: 10,
                left: 0,
                right: 0,
            },
        },
        maintainAspectRatio: false,
        backgroundColor: 'transparent',
    };

    return (
        <div style={{ height: "125px", backGround: 'transparent' }}>
            <Bar data={chartData} options={options} />
        </div>
    );
};

export default FreqMinWatched;