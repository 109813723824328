import { useEffect, useState } from "react"
import { tokens } from "../../theme"
import { useTheme } from '@mui/material';
import { Scrollbars } from 'react-custom-scrollbars';

import { Line } from "react-chartjs-2";
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const PantryEmails = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [emailData, setEmailData] = useState([])
    useEffect(() => {
        const fetchEmailData = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantryemails/temp`);

            const data = await response.json();
            if (response.ok) {
                setEmailData(data)
            }
        }
        fetchEmailData()
    }, [])

    const chartData = {
        labels: emailData.map(item => item.title),
        datasets: [{
            label: 'Views',
            data: emailData.map(item => (item.opens)),
            borderColor: "white",
            borderWidth: 1,
            backgroundColor: colors.SKcustom[12],
            // Custom property to store original string open for tooltips
            openStrings: emailData.map(item => item.open),
        }]
    };

    const options = {
        scales: {
            y: {
                beginAtZero: false,
                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    display: false,
                    color: colors.SKcustom[10],

                }
            },
            x: {
                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    font: {
                        size: "10px",
                    },
                    color: colors.SKcustom[10],
                }
            }
        },
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                anchor: 'end',
                align: 'top',
                formatter: (value, context) => {
                    // Ensure the value is a number before formatting
                    const numberValue = Number(value);
                    return isNaN(numberValue) ? 'N/A' : numberValue.toLocaleString();
                },
                color: colors.SKcustom[10],
                font: {
                    size: 10,
                }
            },
        },
        layout: {
            padding: {
                top: 20,
                bottom: 10,
                left: 0,
                right: 0,
            },
        },
        maintainAspectRatio: false,
        backgroundColor: 'transparent',
    };


    return (

        <div style={{ overflowX: 'auto', maxWidth: '600px', backgroundColor: 'transparent' }}>
            <div style={{ width: '800px' }}>
                <Line data={chartData} options={options} />
            </div>
        </div>

    );
};

export default PantryEmails