import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";

import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';

const VideoMetrics23 = () => {

    //aesthetics
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    function hexToRgba(hex, opacity) {
        hex = hex.replace('#', '');
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
    }

    //platform metrics
    const [views, setViews] = useState([]);
    useEffect(() => {
        const fetchViews = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/sn2023/topviews`);
                if (!response.ok) {
                    throw new Error('SK - not connecting to database');
                }
                const data = await response.json();
                setViews(data);
            } catch (error) {
                console.error('Let Shannon know there is error', error);
            }
        };
        fetchViews();
    }, []);
    const requestSort = key => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    //search filter
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [searchTerm, setSearchTerm] = useState("");

    const filterViews = (shows, searchTerm) => {
        if (!searchTerm) {
            return shows;
        }

        return shows.filter((show) => {
            const fieldValue = Object.values(show)
                .map((value) => (value || "").toString().toLowerCase())
                .join(" ");

            return fieldValue.includes(searchTerm.toLowerCase());
        });
    };

    // Update the filtered shows whenever the search term or original data changes
    const [filteredViews, setFilteredViews] = useState([]);
    useEffect(() => {
        const filteredData = filterViews(views, searchTerm);
        setFilteredViews(filteredData);
    }, [views, searchTerm]);

    const sortedItems = React.useMemo(() => {
        let sortableItems = [...filteredViews];
        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [filteredViews, sortConfig]);

    return (
        <div style={{ marginLeft: "1em" }}>

            <div className="sh2" style={{ color: colors.SKcustom[11] }}>2023 SN Platform (USA) Video Metrics </div>
            <p style={{ marginTop: "0px", color: colors.SKcustom[33], fontStyle: "italic", fontSize: "0.9em" }}>***Missing: Raising the Bar ep1 & Wild About Whiskey ep1</p>

            <TextField
                label="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                variant="outlined"
                margin="normal"
                inputProps={{ style: { height: '15px' } }}
                InputLabelProps={{
                    shrink: true,
                    style: { fontSize: '12px' }
                }}
                InputProps={{
                    style: {
                        height: '25px',
                        paddingTop: '10px',
                        paddingBottom: '0px',
                    },
                }}
            />

            <table className="tableSK">
                <thead>

                    <tr style={{ color: colors.SKcustom[20] }}>
                        <th className="campcolM" rowSpan={2} onClick={() => requestSort('series')} style={{ fontSize: "9px" }}>Episode<ExpandMoreOutlinedIcon style={{ fontSize: "12px" }} /></th>
                        <th className="campcolS" rowSpan={2} onClick={() => requestSort('episode')} style={{ fontSize: "9px" }}>Title<ExpandMoreOutlinedIcon style={{ fontSize: "12px" }} /></th>
                        <th className="campcolxs" colSpan={3} style={{ color: "#000", paddingLeft: "5px", marginRight: "9px", backgroundColor: hexToRgba(colors.SKcustom[30], 0.4), fontWeight: 700 }}>VIEWS</th>
                        <th className="campcolS" colSpan={3} style={{ color: "#000", paddingLeft: "5px", marginRight: "9px", backgroundColor: hexToRgba(colors.SKcustom[30], 0.4), borderLeft: "2px solid black", fontWeight: 700 }}>BUY BAR</th>
                    </tr>

                    <tr style={{ color: colors.SKcustom[20] }}>
                        <th className="campcolxs" onClick={() => requestSort('totalViews')} style={{ fontSize: "9px" }}>Total<ExpandMoreOutlinedIcon style={{ fontSize: "12px" }} /></th>
                        <th className="campcolxs" onClick={() => requestSort('totalViewsCompleted')} style={{ fontSize: "9px" }}>Completed<ExpandMoreOutlinedIcon style={{ fontSize: "12px" }} /></th>
                        <th className="campcolS" onClick={() => requestSort('returnViews')} style={{ fontSize: "9px" }}>Return<ExpandMoreOutlinedIcon style={{ fontSize: "12px" }} /></th>
                        <th className="campcolS" onClick={() => requestSort('totalPStoQuickBuy')} style={{ fontSize: "9px" }}>Quick Buy<ExpandMoreOutlinedIcon style={{ fontSize: "12px" }} /></th>
                        <th className="campcolS" onClick={() => requestSort('TotalPStoPDPPage')} style={{ fontSize: "9px" }}>Click > PDP<ExpandMoreOutlinedIcon style={{ fontSize: "12px" }} /></th>
                        <th className="campcolxs" onClick={() => requestSort('TotalPStoAddtoCart')} style={{ fontSize: "9px" }}>Add to Cart<ExpandMoreOutlinedIcon style={{ fontSize: "12px" }} /></th>
                    </tr>
                </thead>
                <tbody style={{ color: colors.SKcustom[10] }}>
                    {sortedItems.map((view, index) => (
                        <tr key={index} style={{ cursor: 'pointer', backgroundColor: index % 2 === 0 ? hexToRgba(colors.SKcustom[25], 0.2) : 'transparent' }}>

                            <td className="campcell" style={{ paddingRight: "15px", borderColor: hexToRgba(colors.SKcustom[25], 0.2,) }}>{view.series}</td>

                            <td className="campcell" style={{ paddingRight: "15px", borderColor: hexToRgba(colors.SKcustom[25], 0.2) }}>{view.episode}</td>
                            <td className="campcell" style={{ borderColor: hexToRgba(colors.SKcustom[25], 0.2) }}>{view.totalViews ? view.totalViews.toLocaleString() : '0'}</td>
                            <td className="campcell" style={{ borderColor: hexToRgba(colors.SKcustom[25], 0.2) }}>{view.totalViewsCompleted ? view.totalViewsCompleted.toLocaleString() : '0'}</td>
                            <td className="campcell" style={{ borderColor: hexToRgba(colors.SKcustom[25], 0.2) }}>{view.returnViews ? view.returnViews.toLocaleString() : '0'}</td>
                            <td className="campcell" style={{ borderColor: hexToRgba(colors.SKcustom[25], 0.2) }}>{view.totalPStoQuickBuy ? view.totalPStoQuickBuy.toLocaleString() : '0'}</td>
                            <td className="campcell" style={{ borderColor: hexToRgba(colors.SKcustom[25], 0.2) }}>{view.TotalPStoPDPPage ? view.TotalPStoPDPPage.toLocaleString() : '0'}</td>
                            <td className="campcell" style={{ borderColor: hexToRgba(colors.SKcustom[25], 0.2) }}>{view.TotalPStoAddtoCart ? view.TotalPStoAddtoCart.toLocaleString() : '0'}</td>

                        </tr>
                    ))}
                </tbody>
            </table>
        </div >
    );
}
export default VideoMetrics23;

