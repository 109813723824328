import React, { useEffect, useState, useRef } from "react";
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button
} from "@mui/material";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import HexToRgba from '../../components/visuals/design/hexToRgba';
import "../../components/visuals/design/skSpecial.css";

const Negroni24 = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [data, setData] = useState([]);
    const scrollContainerRef = useRef(null);
    const topScrollContainerRef = useRef(null);
    const [tableWidth, setTableWidth] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/negroni`);
            const data = await response.json();

            if (response.ok) {
                setData(data);
            }
        };
        fetchData();

        if (scrollContainerRef.current) {
            setTableWidth(scrollContainerRef.current.scrollWidth);
        }
    }, [data]);

    const handleScroll = (scrollContainer, syncedScrollContainer) => {
        if (syncedScrollContainer.current && scrollContainer.current) {
            syncedScrollContainer.current.scrollLeft = scrollContainer.current.scrollLeft;
        }
    };

    // Function to convert data into CSV format and trigger download
    const downloadCSV = () => {
        const csvHeader = [
            "Prisma Code,Tactic,Flight Start,Flight End,Buy Model,Planned Impressions,Planned Dollars,Total Imps,Total Clicks,Total Views,Sept Imps,Sept Clicks,Sept Views,Oct Imps,Oct Clicks,Oct Views"
        ];

        const csvRows = data.map(row => [
            `"${row.prismaCode || ''}"`,
            `"${row.tactic || ''}"`,
            `"${row.flightStart || ''}"`,
            `"${row.flightEnd || ''}"`,
            `"${row.buyModel || ''}"`,
            `"${row.plannedImp || ''}"`,
            `"${row.plannedDollars || ''}"`,
            `"${row.totalImp || ''}"`,
            `"${row.totalClicks || ''}"`,
            `"${row.totalViews || ''}"`,
            `"${row.septImps || ''}"`,
            `"${row.septClicks || ''}"`,
            `"${row.septViews || ''}"`,
            `"${row.octImps || ''}"`,
            `"${row.octClicks || ''}"`,
            `"${row.octViews || ''}"`
        ].join(","));

        const csvContent = [csvHeader, ...csvRows].join("\n");

        // Create a blob from the CSV content
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

        // Create a link element and trigger a download
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute("download", "negroni_data.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div style={{ color: colors.SKcustom[10], marginLeft: "10px" }}>
            <p>Campari • Negroni Week '24 Campaign</p>

            <Button variant="contained" color="primary" onClick={downloadCSV} style={{ border: `1px solid ${colors.SKcustom[10]}` }}>
                Download CSV
            </Button>

            {/* Flexbox to ensure the top scroll container is placed above the table */}
            <div style={{ display: 'flex', flexDirection: 'column', maxWidth: "1200px" }}>
                {/* Top scrollbar div */}
                <div
                    ref={topScrollContainerRef}
                    style={{
                        overflowX: "auto",
                        marginBottom: "5px",

                    }}
                    onScroll={() => handleScroll(topScrollContainerRef, scrollContainerRef)}
                >
                    {/* Dummy content to create a scrollbar with the same width as the table */}
                    <div style={{ width: tableWidth, height: '1px' }} />
                </div>

                {/* Table container with bottom scrollbar */}
                <TableContainer
                    ref={scrollContainerRef}
                    component={Paper}
                    style={{
                        overflowX: "auto",
                    }}
                    onScroll={() => handleScroll(scrollContainerRef, topScrollContainerRef)}
                >
                    <Table>
                        <TableHead style={{ backgroundColor: '#2B4593' }}>
                            <TableRow>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Prisma Code</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Tactic</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Flight Start</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Flight End</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Buy Model</TableCell>

                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Planned Imps</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Planned Dollars</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Total Imps</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Total Clicks</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Total Views</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Sept Imps</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Sept Clicks</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Sept Views</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Oct Imps</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Oct Clicks</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Oct Views</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, index) => (
                                <TableRow
                                    key={index}
                                    style={{
                                        backgroundColor: row.group % 2 === 0 ? HexToRgba(colors.SKcustom[31], 0.3) : undefined,
                                    }}
                                >
                                    <TableCell style={{ fontSize: '10px', padding: '4px' }}>{row.prismaCode}</TableCell>
                                    <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>{row.tactic}</TableCell>
                                    <TableCell style={{ fontSize: '10px', padding: '4px' }}>{row.flightStart}</TableCell>
                                    <TableCell style={{ fontSize: '10px', padding: '4px' }}>{row.flightEnd}</TableCell>
                                    <TableCell style={{ fontSize: '10px', padding: '4px' }}>{row.buyModel}</TableCell>
                                    <TableCell style={{ fontSize: '10px', padding: '4px' }}>{row.plannedImp}</TableCell>
                                    <TableCell style={{ fontSize: '10px', padding: '4px' }}>{row.plannedDollars}</TableCell>
                                    <TableCell style={{ padding: '4px' }}>{row.totalImp}</TableCell>
                                    <TableCell style={{ padding: '4px' }}>{row.totalClicks}</TableCell>
                                    <TableCell style={{ padding: '4px' }}>{row.totalViews}</TableCell>
                                    <TableCell style={{ padding: '4px' }}>{row.septImps}</TableCell>
                                    <TableCell style={{ padding: '4px' }}>{row.septClicks}</TableCell>
                                    <TableCell style={{ padding: '4px' }}>{row.septViews}</TableCell>
                                    <TableCell style={{ padding: '4px' }}>{row.octImps}</TableCell>
                                    <TableCell style={{ padding: '4px' }}>{row.octClicks}</TableCell>
                                    <TableCell style={{ padding: '4px' }}>{row.octViews}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};

export default Negroni24;
