import Timeline from "../components/Resources/BDTimeline"

const BD = () => {

    return (

        <div style={{ marginLeft: "15px" }
        }>
            <Timeline />
        </div>

    );

};

export default BD;

