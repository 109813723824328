import React, { useEffect, useState } from 'react';
import { colors } from '@mui/material';
import { useTheme } from "@mui/material"
import { tokens } from "../../theme"


const GNsalesList = () => {


    //aesthetics
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [orders, setOrders] = useState([])

    useEffect(() => {
        const fetchOrders = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/gnbc/test`);
            const data = await response.json();

            if (response.ok) {
                setOrders(data);
            }
        };
        fetchOrders();
    }, []);

    return (
        <table style={{ color: colors.SKcustom[10], width: '70%' }}>
            <thead>
                <tr>
                    <th style={{ textAlign: 'left' }}>Date</th>
                    <th style={{ textAlign: 'left' }}>Order ID</th>
                    <th style={{ textAlign: 'left' }}>Units</th>
                    <th style={{ textAlign: 'left' }}>Total</th>
                    <th style={{ textAlign: 'left' }}>Product</th>
                </tr>
            </thead>
            <tbody>
                {orders.map((order) => (
                    <tr key={order._id}>
                        <td style={{ textAlign: 'left' }}>{order.date}</td>
                        <td style={{ textAlign: 'left' }}>{order.order_id}</td>
                        <td style={{ textAlign: 'left' }}>{order.units}</td>
                        <td style={{ textAlign: 'left' }}>{order.total}</td>
                        <td style={{ textAlign: 'left' }}>{order.product}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );

}

export default GNsalesList;