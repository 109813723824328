import PropPackages from '../components/Resources/progPackages'

//aesthetics
import { tokens } from "../theme"
import { useTheme } from '@mui/material';


const UnderTheHood = () => {

    //aesthetics
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


    const baseUrl = window.location.href;

    //functionality



    return (
        <div>
            <PropPackages />
        </div>

    );
};


export default UnderTheHood;