import React from "react";
import { useEffect, useState } from "react"
import { tokens } from "../../../theme"
import { useTheme } from "@mui/material"

const TopSellers = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [productData, setProductData] = useState([]);

    useEffect(() => {
        const fetchProduct = async () => {

            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/usbc/topusa`);
            const data = await response.json();

            data.sort((a, b) => b.year - a.year);
            if (response.ok) {
                setProductData(data);
            }
        };
        fetchProduct();
    }, []);

    const [productDataUK, setProductDataUK] = useState([]);

    useEffect(() => {
        const fetchProductUK = async () => {

            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/ukbc/top`);
            const data = await response.json();
            data.sort((a, b) => b.year - a.year);
            if (response.ok) {
                setProductDataUK(data);
            }
        };
        fetchProductUK();
    }, []);

    const [expandedYears, setExpandedYears] = useState({});

    const toggleYear = (year) => {
        setExpandedYears(prev => ({ ...prev, [year]: !prev[year] }));
    };

    const [expandedYearsUK, setExpandedYearsUK] = useState({});

    const toggleYearUK = (year) => {
        setExpandedYearsUK(prev => ({ ...prev, [year]: !prev[year] }));
    };


    return (
        <div>
            <h2 className="topSellerHeader" style={{ marginBottom: "-10px", color: colors.SKcustom[10] }}>USA</h2>

            <div style={{ color: colors.SKcustom[10] }}>

                <div className="row2">
                    {productData.length > 0 ? (productData.map((yearData) => (

                        <div key={yearData.year}>

                            <h2 className="topSellerHeader">{yearData.year}</h2>

                            <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>

                                {(expandedYears[yearData.year] ? yearData.topProducts : yearData.topProducts.slice(0, 5)).map((product, index) => (

                                    <li key={index}>

                                        <span style={{ fontSize: '1em', fontWeight: 600, color: colors.grey[22], marginRight: "" }}>
                                            {product.product}
                                        </span>

                                        <div style={{ marginBottom: "10px" }}>

                                            <span style={{ fontSize: '1em', fontWeight: 300, color: colors.SKcustom[21], marginRight: "" }}>
                                                {` ${product.totalSales}`}
                                            </span> •

                                            {` ${product.totalUnits}`} units</div>

                                    </li>
                                ))}
                            </ul>
                            <button className="neonButton" onClick={() => toggleYear(yearData.year)}>
                                {expandedYears[yearData.year] ? 'Show Less' : 'Show More'}
                            </button>
                        </div>
                    ))
                    ) : (
                        <p>Tell Shannon there is a problem</p>
                    )}
                </div>
            </div>

            <h2 className="topSellerHeader" style={{ marginBottom: "-10px", color: colors.SKcustom[10] }}>UK</h2>
            <div style={{ color: colors.SKcustom[10] }}>
                <div className="row3">
                    {productDataUK.length > 0 ? (productDataUK.map((yearDataUK) => (

                        <div key={yearDataUK.year}>

                            <h2 className="topSellerHeader">{yearDataUK.year}</h2>

                            <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>

                                {(expandedYearsUK[yearDataUK.year] ? yearDataUK.topProducts : yearDataUK.topProducts.slice(0, 5)).map((product, index) => (
                                    <li key={index}>

                                        <span style={{ fontSize: '1em', fontWeight: 600, color: colors.grey[22], marginRight: "" }}>
                                            {product.product}
                                        </span>

                                        <div style={{ marginBottom: "10px" }}>

                                            <span style={{ fontSize: '1em', fontWeight: 300, color: colors.SKcustom[21] }}>
                                                {` ${product.totalSales}`}
                                            </span> •

                                            {` ${product.totalUnits}`} units</div>

                                    </li>
                                ))}
                            </ul>
                            <button className="neonButton" onClick={() => toggleYearUK(yearDataUK.year)}>
                                {expandedYearsUK[yearDataUK.year] ? 'Show Less' : 'Show More'}
                            </button>

                        </div>
                    ))
                    ) : (
                        <p>Alert Shannon there is a problem</p>
                    )}
                </div>
            </div>
        </div >

    );

};

export default TopSellers;