import { React, useState } from 'react';

import '../components/visuals/design/sk.css'
import { tokens } from "../theme"

import { useTheme, Dialog, IconButton } from '@mui/material';

import SnHighlights from '../components/SN/SnHighlights';

const Highlights = () => {

    console.log('hello sk')

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    function hexToRgba(hex, opacity) {
        hex = hex.replace('#', '');
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
    }

    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: "10px" }}>


            <div className="rowHome">
                <SnHighlights />
            </div>






            {/* <div style={{ width: '300px', marginTop: -100 }} >
                <NBTVLogo mode={theme.palette.mode} />
            </div> */}


        </div >
    )
}

export default Highlights
