import React, { createContext, useContext, useState } from "react";

//create user context
export const UserContext = createContext()

//enable shareability across every componenet needs it
export const UserProvider = ({ children }) => {

    //create state
    const [user, setUser] = useState(null);

    return (
        //provide access to of values to children
        <UserContext.Provider value={{ user, setUser }}>
            {children}
        </UserContext.Provider>
    )
}

// export const useUserContext = () => useContext(UserContext)

// Default exports i.e export default HelloWorld; Can only have one per file
// Named exports i.e export const HelloWorld; Can have as many as you want
/**
 * What are the differences? To import a default export, import HelloWorld from './file', you can name it anything you want
 
 * To import a named export, you must put it in curly braces AND also name it exactly what it was exported as
 */