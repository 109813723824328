import { useEffect, useState } from "react"
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

//aesthetics
import { tokens } from "../../theme"
import { useTheme } from '@mui/material';

import '../../components/visuals/design/videoMetrics.css';
import '../../components/visuals/design/sk.css';

Chart.register(...registerables, ChartDataLabels);

const GoogleAds = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const fetchData = async (url, setter) => {
        const response = await fetch(url);
        const data = await response.json();
        if (response.ok) {
            setter(data);
        }
    };

    const [pMaxJune, setPMaxJune] = useState(0);
    const [pMaxJuly, setPMaxJuly] = useState(0);
    const [adApril, setAdApril] = useState(0);
    const [adMay, setAdMay] = useState(0);
    const [saratogaMay, setSaratogaMay] = useState(0);
    const [saratogaJune, setSaratogaJune] = useState(0);

    useEffect(() => {
        fetchData(`${process.env.REACT_APP_SERVER_URL}/googleads/pmaxjune`, setPMaxJune);
        fetchData(`${process.env.REACT_APP_SERVER_URL}/googleads/pmaxjuly`, setPMaxJuly);
        fetchData(`${process.env.REACT_APP_SERVER_URL}/googleads/april`, setAdApril);
        fetchData(`${process.env.REACT_APP_SERVER_URL}/googleads/may`, setAdMay);
        fetchData(`${process.env.REACT_APP_SERVER_URL}/googleads/saratogamay`, setSaratogaMay);
        fetchData(`${process.env.REACT_APP_SERVER_URL}/googleads/saratogajune`, setSaratogaJune);
    }, []);

    const adJune = 0;
    const saratogaApril = 0;


    const googleAdData = [
        {
            month: "April '24",
            adImps: adApril,
            saratogaImps: saratogaApril
        },
        {
            month: "May '24",
            adImps: adMay,
            saratogaImps: saratogaMay
        },
        {
            month: "June '24",
            adImps: adJune,
            saratogaImps: saratogaJune,
            conversionImps: pMaxJune
        },
        {
            month: "July '24",
            conversionImps: pMaxJuly
        },
    ]


    const chartData = {
        labels: googleAdData.map(item => item.month),
        datasets: [
            {
                label: 'Awareness',
                data: googleAdData.map(item => (item.adImps)),
                borderColor: "orange",
                borderWidth: 1,
                backgroundColor: "orange",
                impsStrings: googleAdData.map(item => item.adImps),
            },
            {
                label: 'Saratoga',
                data: googleAdData.map(item => (item.saratogaImps)),
                borderColor: "blue",
                borderWidth: 1,
                backgroundColor: "blue",
                impsStrings: googleAdData.map(item => item.saratogaImps),
            },
            {
                label: 'Conversion Campaign',
                data: googleAdData.map(item => (item.conversionImps)),
                borderColor: "magenta",
                borderWidth: 1,
                backgroundColor: "magenta",
                impsStrings: googleAdData.map(item => item.conversionImps),
            }
        ]
    };

    const options = {
        scales: {
            y: {
                beginAtZero: false,
                min: 0,
                max: 3000000,
                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    display: false,
                    color: colors.SKcustom[10],

                }
            },
            x: {
                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    font: {
                        size: "8px",
                    },
                    color: colors.SKcustom[10],
                }
            }
        },
        plugins: {
            legend: {
                display: true,
                labels: {
                    color: colors.SKcustom[10]
                }
            },
            datalabels: {
                display: true,
                anchor: 'end',
                align: 'top',
                color: colors.SKcustom[10],
                font: { size: 9 },
                rotation: -45,
                formatter: function (value, context) {

                    if (value === 0) {
                        return null; // Do not display labels with value 0
                    }
                    if (typeof value === 'number' || value instanceof Date) {
                        return value.toLocaleString();
                    } else {
                        console.error('Value is not a number or date:', value);
                        return value;
                    }
                }
            }
        },
        layout: {
            padding: {
                top: 0,
                bottom: 10,
                left: 0,
                right: 0,
            },
        },
        maintainAspectRatio: false,
        backgroundColor: 'transparent',
    };

    return (
        <div style={{ overflowX: 'auto', maxWidth: '600px', backgroundColor: 'transparent' }}>
            <div style={{ width: '350px', height: '300px' }}>
                <Bar data={chartData} options={options} />
            </div ></div >
    );
};

export default GoogleAds