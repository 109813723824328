import React from 'react';

//aesthetics
import { tokens } from "../../theme"
import { useTheme } from '@mui/material';

import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Logo from "../../../src/img/GolfNation_Small_Icon_White.png"
import USLogo from "../../../src/img/S_US.png"
import UKLogo from "../../../src/img/S_UK.png"


const ProgPackages = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const link1 = 'https://drive.google.com/drive/folders/1vx3rzKpM-_Jp5N1jedKz9ib53duGmxz5?usp=drive_link'
    const link2 = 'https://docs.google.com/document/d/1oZxPcJ_65t_vcs_QGoNpSIKNf5yr3MwrGDcfPsyK0Zg/edit?usp=sharing'
    const link3 = 'https://studio.frequency.com/'
    const link4 = 'https://drive.google.com/drive/folders/1Oegn2-knQFBX7T6CTlTN3TVXkUN3H2gP?usp=drive_link'
    const link5 = 'https://docs.frequency.com/en/content-delivery-guidelines.html'
    const ccGN = 'https://prod-mc.golfnation.com/#!/dashboard'
    const ccSN = 'https://prod-mc.spiritsnetwork.com/#!/login'
    const ccUK = 'https://prod-mc.spiritsnetwork.co.uk/#!/login'

    return (
        <div style={{ marginLeft: "15px" }}>

            <div className="sh1" style={{ color: "#6082B6", marginBottom: "10px" }}>Technical overview of what's required to get a completed show to air.</div>

            <div className="sh2" style={{ color: colors.SKcustom[10], backgroundColor: "#6082B6", padding: "0.5em", width: "27em" }}>NBTV OWNED-PLATFORMS i.e. SN & GN</div>

            <div className="visibleBorder2" style={{ width: "35em", marginBottom: "10px", padding: "10px", color: colors.SKcustom[16], backgroundColor: 'rgba(145, 163, 176, 0.3)', borderColor: colors.SKcustom[10] }}>

                {/* SERIES PACKAGE */}
                <div style={{ marginLeft: "1em" }}>
                    <div className="sh3" style={{ color: colors.SKcustom[16], marginBottom: "-10px" }}>SERIES PACKAGE</div>

                    <ul className="sh3" style={{ color: colors.SKcustom[10] }}>
                        <li>
                            Thumbnail image
                            <span style={{ fontWeight: 400, fontSize: "0.9em", color: colors.SKcustom[17] }}>
                                - stored on Mike's server
                            </span>
                        </li>
                        <li>
                            Series description
                        </li>
                        <li>
                            Series trailer
                            <span style={{ fontWeight: 400, fontSize: "0.9em", color: colors.SKcustom[17] }}>
                                - FYI might not exist for old packages
                            </span>
                        </li>
                    </ul>

                    {/* EPISODE PACKAGE */}
                    <div className="sh3" style={{ color: colors.SKcustom[16], marginBottom: "0px" }}>EPISODE PACKAGE</div>

                    <div className="sh3" style={{ color: colors.SKcustom[10] }} >

                        <li style={{ listStyleType: 'none' }}>
                            <strong>Metadata - <a href={link1} target="_blank" rel="noopener noreferrer" style={{ color: colors.SKcustom[17], fontWeight: 400 }}>click for master spreadsheet</a></strong>
                            <ul style={{ paddingLeft: "40x", listStyleType: 'disc', marginTop: "0px" }}>
                                <li>Episode Title</li>
                                <li>Description</li>
                                <li>Tags</li>
                                <li>Cue points</li>
                            </ul>
                        </li>

                        <li style={{ listStyleType: 'none' }}>
                            <strong>Media - <a href={link2} target="_blank" rel="noopener noreferrer" style={{ color: colors.SKcustom[17], fontWeight: 400 }}>click for spec sheet</a></strong>
                            <ul style={{ paddingLeft: "40x", listStyleType: 'disc', marginTop: "0px" }}>
                                <li>Video   <span style={{ fontWeight: 400, fontSize: "0.9em", color: colors.SKcustom[10] }}>
                                    - stored on Mike's server
                                </span></li>
                                <li>Teaser   <span style={{ fontWeight: 400, fontSize: "0.9em", color: colors.SKcustom[10] }}>
                                    - stored on Mike's server
                                </span></li>
                                <li>Thumbnail Images   <span style={{ fontWeight: 400, fontSize: "0.9em", color: colors.SKcustom[10] }}>
                                    - stored on Mike's server
                                </span></li>
                                <li>BTS Video  <span style={{ fontWeight: 400, fontSize: "0.9em", color: colors.SKcustom[10] }}>
                                    - stored on Mike's server
                                </span></li>
                                <li>Recipe Video (when applicable)   <span style={{ fontWeight: 400, fontSize: "0.9em", color: colors.SKcustom[10] }}>
                                    - stored on Mike's server
                                </span></li>
                            </ul>
                        </li>
                    </div>

                    {/* core content */}
                    <div className="row3">
                        <CloudUploadOutlinedIcon />
                        <div className="sh3" style={{ color: colors.SKcustom[16], marginBottom: "0px" }}>WHERE TO UPLOAD</div>
                    </div>

                    <div className="sp" style={{ color: colors.SKcustom[10], fontWeight: 600, fontSize: "1.1em" }}>

                        NBTV-owned platforms (i.e. SN & GN) are hosted on Core Content <br></br>

                        •   Core Content Instructions - <a href={link4} target="_blank" rel="noopener noreferrer" style={{ color: colors.SKcustom[17], fontWeight: 400 }}>link</a>

                        <br></br>

                        •   Links to access points are:

                        <div className="row" style={{ marginTop: "0.5em", alignItems: "center", backgroundColor: colors.SKcustom[2], paddingLeft: "1em" }}>

                            <a href={ccGN} target="_blank" rel="noopener noreferrer"><img src={Logo} alt="GN" style={{ height: "35px" }} /></a>
                            <br></br>
                            <a href={ccSN} target="_blank" rel="noopener noreferrer"> <img src={USLogo} alt="GN" style={{ height: "50px" }} /></a><br></br>
                            <a href={ccUK} target="_blank" rel="noopener noreferrer"> <img src={UKLogo} alt="GN" style={{ height: "50px" }} /></a>
                        </div>
                    </div>

                </div>


            </div>

            {/* FREQUENCY PACKAGE */}
            <div className="sh2" style={{ color: colors.SKcustom[10], marginTop: "20px", backgroundColor: "#6082B6", padding: "0.5em", width: "27em" }}>THIRD-PARTY PLATFORMS i.e. Vizio</div>

            <div className="visibleBorder2" style={{ width: "35em", marginBottom: "10px", padding: "10px", color: colors.SKcustom[16], backgroundColor: 'rgba(145, 163, 176, 0.3)', borderColor: colors.SKcustom[10] }}>


                <div className="sh3" style={{ color: colors.SKcustom[10] }} >

                    <li style={{ listStyleType: 'none' }}>

                        <ul style={{ paddingLeft: "40x", listStyleType: 'disc', marginTop: "0px" }}>
                            <li>Episode Title</li>
                            <li>
                                Description
                                <span style={{ fontWeight: 400, fontSize: "0.9em", color: colors.SKcustom[10] }}>
                                    - 110 character limit
                                </span>
                            </li>
                            <li>Video with QR codes   <span style={{ fontWeight: 400, fontSize: "0.9em", color: colors.SKcustom[10] }}>
                                - stored on Mike's server
                            </span></li>
                            <li>Thumbnail Image   <span style={{ fontWeight: 400, fontSize: "0.9em", color: colors.SKcustom[10] }}>
                                - stored on Mike's server
                            </span></li>
                            <li>Closed Caption File  <span style={{ fontWeight: 400, fontSize: "0.9em", color: colors.SKcustom[10] }}>
                                - srt, scc, vtt, stl, smi, ttml
                            </span></li>

                        </ul>
                    </li>

                    <div style={{ marginLeft: "1em" }}>
                        <div className="row3">
                            <CloudUploadOutlinedIcon style={{ color: colors.SKcustom[16] }} />
                            <div className="sh3" style={{ color: colors.SKcustom[16], marginBottom: "0px", fontSize: "1em" }}>WHERE TO UPLOAD</div>
                        </div>

                        <div className="sp" style={{ color: colors.SKcustom[10], fontWeight: 600, fontSize: "1em" }}>


                            •   Frequency Instructions - <a href={link5} target="_blank" rel="noopener noreferrer" style={{ color: colors.SKcustom[17], fontWeight: 400 }}>link</a>

                            <br></br>

                            •   Frequency Portal - <a href={link3} target="_blank" rel="noopener noreferrer" style={{ color: colors.SKcustom[17], fontWeight: 400 }}>link</a>


                        </div>
                    </div>




                </div>
            </div>
        </div>
    )
}

export default ProgPackages