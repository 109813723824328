import { tokens } from "../../theme"
import { useTheme } from '@mui/material';


//imgs
import ChannelsLogo from "../visuals/logoMode/logoModeChannels";
import GNLogo from '../visuals/logoMode/logoModeGN'
import sn from "../../img/SN.png"

const VideoBranding = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    function hexToRgba(hex, opacity) {
        hex = hex.replace('#', '');
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
    }

    return (

        <div style={{ paddingLeft: "10px" }}>
            <div className="responsive-div" style={{ color: colors.SKcustom[10] }}><p>Click a channel to access the animated bug and end-card.</p></div>

            <div className="rowPlain">

                <div className="kitCard" style={{ borderColor: hexToRgba(colors.SKcustom[10], 0.4) }}>
                    <a href={"https://www.dropbox.com/scl/fo/afgves7fkbf665d0nkeiv/h?rlkey=f0oozh4r5ebz8j5iqszvbgqwm&e=1&dl=0"} target="_blank">
                        <ChannelsLogo mode={theme.palette.mode} /></a>
                </div>

                <div className="kitCard" style={{ borderColor: hexToRgba(colors.SKcustom[10], 0.4) }}>
                    <a href={"https://www.dropbox.com/scl/fo/3e70yd3gvsnww25zkrhw1/h?rlkey=iox67cnvdfz1ye2gc8mp2gqok&e=1&dl=0"} target="_blank">
                        <img src={sn} alt="spirits network" className="kit" /></a>
                </div>

                <div className="kitCard" style={{ borderColor: hexToRgba(colors.SKcustom[10], 0.4) }}>
                    <a href={"https://www.dropbox.com/scl/fo/yvq9eng6jspece5423v6r/AI2mZA9b0Fsf8PnrsgFZJlQ?rlkey=o6729udlwvs0xbp8f320d9tfx&st=gcib6o1b&dl=0"} target="_blank">
                        <GNLogo mode={theme.palette.mode} style={{ width: "50px" }} /></a>
                </div>


            </div>
        </div>
    )
}

export default VideoBranding;