import React, { useEffect, useRef } from 'react';
import { tokens } from "../theme";
import { useTheme } from "@mui/material";

const VimeoPlayer = ({ videoId, autoplay, loop }) => {
    const playerRef = useRef(null);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://player.vimeo.com/api/player.js';
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            const player = new window.Vimeo.Player(playerRef.current, {
                id: videoId,
                autoplay: autoplay,
                loop: loop,
            });

            return () => {
                player.unload();
            };
        };
    }, [videoId, autoplay, loop]);

    return (
        <div ref={playerRef} style={{ width: '100%', height: 'auto' }}></div>
    );
};

const channels = async () => {
    try {
        await navigator.clipboard.writeText('https://app.frame.io/presentations/15329c9f-b17b-4ba8-86dc-1ed94280418a');
        alert('https://app.frame.io/presentations/15329c9f-b17b-4ba8-86dc-1ed94280418a copied to clipboard!');
    } catch (err) {
        console.error('Failed to copy URL: ', err);
    }
};

const spirits = async () => {
    try {
        await navigator.clipboard.writeText('https://vimeo.com/823214604');
        alert('https://vimeo.com/823214604 copied to clipboard!');
    } catch (err) {
        console.error('Failed to copy URL: ', err);
    }
};

const golf = async () => {
    try {
        await navigator.clipboard.writeText('https://vimeo.com/693683492');
        alert('https://vimeo.com/693683492 copied to clipboard!');
    } catch (err) {
        console.error('Failed to copy URL: ', err);
    }
};

const golf2 = async () => {
    try {
        await navigator.clipboard.writeText('https://vimeo.com/916096163');
        alert('https://vimeo.com/916096163 copied to clipboard!');
    } catch (err) {
        console.error('Failed to copy URL: ', err);
    }
};

const pantryBD = async () => {
    try {
        await navigator.clipboard.writeText('https://vimeo.com/914980684/0dbe17a5da');
        alert('https://vimeo.com/914980684/0dbe17a5da copied to clipboard!');
    } catch (err) {
        console.error('Failed to copy URL: ', err);
    }
};

const Sizzles = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <div className="Hcontainer">
            <div className="row10">
                <div className="sh3" style={{ marginBottom: "5px", color: colors.SKcustom[10] }}>2023 NBTV Channels</div>
                <button className="neonButtonSquare" style={{ color: colors.SKcustom[18] }} onClick={channels}>copy link</button>
            </div>
            <VimeoPlayer videoId="672698676" autoplay={false} loop={false} />

            <div className="row10">
                <div className="sh3" style={{ marginBottom: "5px", color: colors.SKcustom[10] }}>Spirits Network</div>
                <button className="neonButtonSquare" style={{ color: colors.SKcustom[18] }} onClick={spirits}>copy link</button>
            </div>
            <VimeoPlayer videoId="823214604" autoplay={false} loop={false} />

            <div className="row10">
                <div className="sh3" style={{ marginBottom: "5px", color: colors.SKcustom[10] }}>Golf Nation</div>
                <button className="neonButtonSquare" style={{ color: colors.SKcustom[18] }} onClick={golf2}>copy link</button>
            </div>
            <VimeoPlayer videoId="916096163" autoplay={false} loop={false} />

            {/* <div className="row10">
                <div className="sh3" style={{ marginBottom: "5px", color: colors.SKcustom[10] }}>Golf Nation • long</div>
                <button className="neonButtonSquare" style={{ color: colors.SKcustom[18] }} onClick={golf}>copy link</button>

            </div>
            <VimeoPlayer videoId="693683492" autoplay={false} loop={false} /> */}

            <div className="row10">
                <div className="sh3" style={{ marginBottom: "5px", color: colors.SKcustom[10] }}>The Pantry BD</div>
                <button className="neonButtonSquare" style={{ color: colors.SKcustom[18] }} onClick={pantryBD}>copy link</button>

            </div>
            {/* <VimeoPlayer videoId="914980684" autoplay={false} loop={false} /> */}

            <div style={{ marginTop: '10px' }}>
                <iframe src="https://player.vimeo.com/video/914980684?h=0dbe17a5da&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" title="The Pantry BD Sizzle"></iframe>
            </div>



        </div >

    )
}

export default Sizzles;