import React from "react";
import { useEffect, useState } from "react"

const SNacq23 = () => {

    const [acq23, setAcq23] = useState([]);

    useEffect(() => {
        const fetchAcq23 = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/shows/acq23`);
            const data = await response.json();

            setAcq23(data)

        };
        fetchAcq23()
    })

    return (
        <div>
            {acq23}
        </div>
    )

};

export default SNacq23