import { useState, useEffect } from 'react';
import axios from "axios";


function useUser() {

    const [user, setUser] = useState(null);

    useEffect(() => {

        async function getUser() {
            const token = localStorage.getItem('token')
            if (!token) {
                return false
            } else {
                const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/validate`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                console.log('response obj', res);
                setUser(res.data.user);

            }
        }

        getUser();


    }, [])

    return [user, setUser];
}

export default useUser;
