import React, { useState, useEffect } from 'react';
// import axios from 'axios';
import '../components/visuals/design/sk.css'

const Test = () => {
    const [list, setList] = useState([]);

    useEffect(() => {
        const fetchList = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/gopuff/zips`);
                if (response.ok) {
                    const data = await response.json();
                    setList(data.data);  // Adjust based on the response structure
                } else {
                    console.error('Failed to fetch data');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchList();
    }, []);

    const downloadCSV = () => {
        const csvRows = [];

        // Headers
        const headers = [
            'Zip',
            'Units 2019', 'Subtotal 2019', 'Orders 2019',
            'Units 2020', 'Subtotal 2020', 'Orders 2020',
            'Units 2021', 'Subtotal 2021', 'Orders 2021',
            'Units 2022', 'Subtotal 2022', 'Orders 2022',
            'Units 2023', 'Subtotal 2023', 'Orders 2023',
            'Units 2024', 'Subtotal 2024', 'Orders 2024'
        ];
        csvRows.push(headers.join(','));

        // Data
        list.forEach(row => {
            const rowData = [
                row.zip,
                row.units_2019 || 0, row.subtotal_2019 || 0, row.orders_2019 || 0,
                row.units_2020 || 0, row.subtotal_2020 || 0, row.orders_2020 || 0,
                row.units_2021 || 0, row.subtotal_2021 || 0, row.orders_2021 || 0,
                row.units_2022 || 0, row.subtotal_2022 || 0, row.orders_2022 || 0,
                row.units_2023 || 0, row.subtotal_2023 || 0, row.orders_2023 || 0,
                row.units_2024 || 0, row.subtotal_2024 || 0, row.orders_2024 || 0
            ];
            csvRows.push(rowData.join(','));
        });

        // Create a blob and download
        const csvString = csvRows.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', 'gopuff_data.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    return (
        <div style={{ marginLeft: "20px", color: "#fff" }}>
            <button onClick={downloadCSV}>Download CSV</button>
            <table style={{ marginLeft: "10px", color: "#fff", borderCollapse: "collapse" }}>
                <thead>
                    <tr>
                        <th style={{ border: "1px solid #fff", padding: "5px" }}>Zip</th>
                        <th style={{ border: "1px solid #fff", padding: "5px" }}>Units 2019</th>
                        <th style={{ border: "1px solid #fff", padding: "5px" }}>Subtotal 2019</th>
                        <th style={{ border: "1px solid #fff", padding: "5px" }}>Orders 2019</th>
                        <th style={{ border: "1px solid #fff", padding: "5px" }}>Units 2020</th>
                        <th style={{ border: "1px solid #fff", padding: "5px" }}>Subtotal 2020</th>
                        <th style={{ border: "1px solid #fff", padding: "5px" }}>Orders 2020</th>
                        <th style={{ border: "1px solid #fff", padding: "5px" }}>Units 2021</th>
                        <th style={{ border: "1px solid #fff", padding: "5px" }}>Subtotal 2021</th>
                        <th style={{ border: "1px solid #fff", padding: "5px" }}>Orders 2021</th>
                        <th style={{ border: "1px solid #fff", padding: "5px" }}>Units 2022</th>
                        <th style={{ border: "1px solid #fff", padding: "5px" }}>Subtotal 2022</th>
                        <th style={{ border: "1px solid #fff", padding: "5px" }}>Orders 2022</th>
                        <th style={{ border: "1px solid #fff", padding: "5px" }}>Units 2023</th>
                        <th style={{ border: "1px solid #fff", padding: "5px" }}>Subtotal 2023</th>
                        <th style={{ border: "1px solid #fff", padding: "5px" }}>Orders 2023</th>
                        <th style={{ border: "1px solid #fff", padding: "5px" }}>Units 2024</th>
                        <th style={{ border: "1px solid #fff", padding: "5px" }}>Subtotal 2024</th>
                        <th style={{ border: "1px solid #fff", padding: "5px" }}>Orders 2024</th>
                    </tr>
                </thead>
                <tbody>
                    {list.map((row, index) => (
                        <tr key={index}>
                            <td style={{ border: "1px solid #fff", padding: "5px" }}>{row.zip}</td>
                            <td style={{ border: "1px solid #fff", padding: "5px" }}>{row.units_2019 || 0}</td>
                            <td style={{ border: "1px solid #fff", padding: "5px" }}>{row.subtotal_2019 || 0}</td>
                            <td style={{ border: "1px solid #fff", padding: "5px" }}>{row.orders_2019 || 0}</td>
                            <td style={{ border: "1px solid #fff", padding: "5px" }}>{row.units_2020 || 0}</td>
                            <td style={{ border: "1px solid #fff", padding: "5px" }}>{row.subtotal_2020 || 0}</td>
                            <td style={{ border: "1px solid #fff", padding: "5px" }}>{row.orders_2020 || 0}</td>
                            <td style={{ border: "1px solid #fff", padding: "5px" }}>{row.units_2021 || 0}</td>
                            <td style={{ border: "1px solid #fff", padding: "5px" }}>{row.subtotal_2021 || 0}</td>
                            <td style={{ border: "1px solid #fff", padding: "5px" }}>{row.orders_2021 || 0}</td>
                            <td style={{ border: "1px solid #fff", padding: "5px" }}>{row.units_2022 || 0}</td>
                            <td style={{ border: "1px solid #fff", padding: "5px" }}>{row.subtotal_2022 || 0}</td>
                            <td style={{ border: "1px solid #fff", padding: "5px" }}>{row.orders_2022 || 0}</td>
                            <td style={{ border: "1px solid #fff", padding: "5px" }}>{row.units_2023 || 0}</td>
                            <td style={{ border: "1px solid #fff", padding: "5px" }}>{row.subtotal_2023 || 0}</td>
                            <td style={{ border: "1px solid #fff", padding: "5px" }}>{row.orders_2023 || 0}</td>
                            <td style={{ border: "1px solid #fff", padding: "5px" }}>{row.units_2024 || 0}</td>
                            <td style={{ border: "1px solid #fff", padding: "5px" }}>{row.subtotal_2024 || 0}</td>
                            <td style={{ border: "1px solid #fff", padding: "5px" }}>{row.orders_2024 || 0}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};


export default Test;


// STATES only   

// const [list, setList] = useState([])
// useEffect(() => {
//     const fetchList = async () => {
//         try {
//             const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/allorders`);
//             if (response.ok) {
//                 const data = await response.json();
//                 setList(data);
//             } else {
//                 console.error('Failed to fetch data');
//             }
//         } catch (error) {
//             console.error('Error fetching data:', error);
//         }
//     };
//     fetchList();
// }, []);


// const years = [2019, 2020, 2021, 2022, 2023, 2024];

// // Create a structure to hold the data organized by state and year
// const tableData = {};
// list.forEach(item => {
//     const { state, year, totalSubtotal } = item;
//     if (!tableData[state]) {
//         tableData[state] = {};
//     }
//     tableData[state][year] = totalSubtotal;
// });

// const downloadCSV = () => {
//     // Implement CSV download functionality here if needed
// };

// return (
//     <div style={{ marginLeft: "20px", color: "#fff" }}>
//         <button onClick={downloadCSV}>Download CSV</button>
//         <table style={{ marginLeft: "10px", color: "#fff", borderCollapse: "collapse" }}>
//             <thead>
//                 <tr>
//                     <th style={{ border: "1px solid black", padding: "5px" }}>State</th>
//                     {years.map(year => (
//                         <th key={year} style={{ border: "1px solid black", padding: "5px" }}>{year}</th>
//                     ))}
//                 </tr>
//             </thead>
//             <tbody>
//                 {Object.entries(tableData).map(([state, yearData]) => (
//                     <tr key={state}>
//                         <td style={{ border: "1px solid black", padding: "5px" }}>{state}</td>
//                         {years.map(year => (
//                             <td key={year} style={{ border: "1px solid black", padding: "5px" }}>
//                                 {yearData[year] ? yearData[year].toFixed(2) : 'N/A'}
//                             </td>
//                         ))}
//                     </tr>
//                 ))}
//             </tbody>
//         </table>
//     </div>
// );
// };
