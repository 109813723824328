import React from 'react';

import GN_blue from "../../../../src/img/GN_blue.png"
import GN_white from "../../../../src/img/GN.png"


// NBTVLogo component
const GNLogo = ({ mode }) => {

    const logo = mode === 'dark' ? GN_white : GN_blue;

    return (
        <img src={logo} alt="GN Logo" className="kit" />
    );
};

export default GNLogo;