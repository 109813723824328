import React, { useState, useEffect } from "react";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import HexToRgba from "../visuals/design/hexToRgba";

import '../../components/visuals/design/sk.css'

const PantryPlatform = () => {

    //aesthetics
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    function hexToRgba(hex, opacity) {
        hex = hex.replace('#', '');
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
    }

    //platform metrics
    const [episodes, setEpisodes] = useState([]);
    useEffect(() => {
        const fetchEpisodes = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantryplatform`);
                if (!response.ok) {
                    throw new Error('SK - not connecting to database');
                }
                const data = await response.json();
                setEpisodes(data);
            } catch (error) {
                console.error('Let Shannon know there is error', error);
            }
        };
        fetchEpisodes();
    }, []);
    return (
        <div >
            <table className="tablePantry" style={{ color: colors.SKcustom[10] }}>
                <thead>
                    <tr>
                        <th style={{ fontSize: "10px" }}>Episode</th>
                        <th className="wrapped-header" style={{ paddingLeft: '10px' }}>Total Views</th>
                        {/* <th className="wrapped-header">Unique Views</th> */}
                        <th className="wrapped-header">Video Completion</th>
                        <th className="wrapped-header" style={{ paddingLeft: '10px' }}>Add to Cart</th>
                        <th className="wrapped-header">Click PDP</th>
                        <th className="wrapped-header">Quick Buy</th>
                        <th className="wrapped-header">Favorite</th>
                        <th className="wrapped-header">Share</th>
                    </tr>
                </thead>
                <tbody>
                    {episodes.map((episode) => (
                        <tr key={episode._id}>
                            <td>{episode.episode}</td>
                            <td style={{ paddingLeft: '10px' }}>{episode.totalViews.toLocaleString()}</td>
                            {/* <td>{episode.uniqueViews}</td> */}
                            <td>{episode.videoCompletion}</td>
                            <td style={{ paddingLeft: '10px' }}>{episode.addCart}</td>
                            <td>{episode.clickPDP}</td>
                            <td>{episode.quickBuy}</td>
                            <td>{episode.favorite}</td>
                            <td>{episode.share}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="row" style={{ backgroundColor: HexToRgba(colors.SKcustom[34], 0.4), paddingLeft: '5px' }}>
                <p style={{ color: colors.SKcustom[10], fontSize: '10px', paddingLeft: '10px' }}> TOTAL</p>
                <p style={{ color: colors.SKcustom[10], fontSize: '12px' }}> 403,689</p>
            </div>

        </div >
    );
}
export default PantryPlatform;

