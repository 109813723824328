import { tokens } from "../theme";
import { useTheme } from "@mui/material";

import BOTM_bar from "../components/SN/BOTM_bar"
import { Doughnut } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import Logo from '../img/botmLogo.png'
import Logo2 from '../img/botmLogo_000.png'

Chart.register(ChartDataLabels);

const Botm = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const gfx = tokens(theme.palette.mode)

    const data = {
        labels:
            ['Agave', 'American Whiskey', 'World Whiskey']
        ,
        datasets: [
            {
                data: [10, 25, 27],
                backgroundColor: ['#11bfae', '#bf6211', '#e3ac17'],
                borderWidth: 1,
                datalabels: { display: false }
            },
        ],
    };

    const options = {
        // radius: 100,
        color: colors.SKcustom[20],
        cutoutPercentage: 20,
        maintainAspectRatio: false,
        responsive: true,
        // height: 400,
        // width: 800,
        plugins: {
            datalabels: {},
            legend: {
                position: 'left', display: true,
                labels: {
                    boxWidth: 30,
                    boxHeight: 30,
                    font: { size: 12 }
                }
            }
        },
    };
    return (

        <div>

            <div className="barChart">
                <img
                    src={theme.palette.mode === 'dark' ? Logo : Logo2}
                    alt="Image 3"
                    style={{ width: '250px', marginBottom: "10px" }} />

                <BOTM_bar />

            </div >

            <div style={{ color: colors.SKcustom[20], fontSize: "1.2em", fontWeight: 600, marginLeft: "10px" }}>Current Members By Category</div>

            <div className="donut">
                <Doughnut
                    data={data}
                    options={options} onClick={null} width="200" justifycontent="left" />
            </div>


        </div>)
}
export default Botm;

