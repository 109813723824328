import { Link } from 'react-router-dom';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { tokens } from "../theme"
import { useTheme } from '@mui/material';

//imgs
import nbtv from "../img/channels.png"
import gn from "../img/GN.png"
import petportal from "../img/petportal.png"
import sn from "../img/SN.png"
import wip from "../img/WIP.png"
import archive from "../img/archived.png"

const Keynotes = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    function hexToRgba(hex, opacity) {
        hex = hex.replace('#', '');
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
    }

    return (

        <div className="SContainer" style={{ marginLeft: "20px" }}>

            <div style={{ color: colors.SKcustom[10] }}><p>Click a logo below to access that company's KeynoteCloud. <br></br>You will need an AppleID to access.</p></div>

            <div className="image-row">

                <div className="shan">
                    <a href={"https://www.icloud.com/iclouddrive/0c9fTgbHcKLrxqSYve9Bd9CgQ#NBTV_CHANNELS"}><img src={nbtv} alt="nbtv channels" /></a>
                </div>
                <div className="shan">
                    <a href={"https://www.icloud.com/iclouddrive/0e6BNEAN50Buo9noobhwlYiZw#SPIRITS_NETWORK"}><img src={sn} alt="spirits network" /></a>
                </div>
                <div className="shan">
                    <a href={"https://www.icloud.com/iclouddrive/033JqPifoQtmEPoKVumNXvaRQ#GOLF_NATION"}><img src={gn} alt="golf nation" /></a>
                </div>
                <div className="shan">
                    <a href={"https://www.icloud.com/iclouddrive/04ayyuYZoxKaI5FFHGA05GYlg#PET_NETWORK"}><img src={petportal} alt="pet portal" /></a >
                </div>
                <div className="shan">
                    <a href={"https://www.icloud.com/iclouddrive/06bmzMVrJxvQYKNrPEUL6Sw2A#WIP"}><img src={wip} alt="wip" /></a >
                </div>
                <div className="shan">
                    <a href={"https://drive.google.com/drive/folders/1SI0zR35o09_3WwY3FdPzAV2IM_7Sd3zx?usp=drive_link"}><img src={archive} alt="archived" /></a >
                </div>
            </div>

            <Link to="https://docsend.com/view/p8hcenbddzxve9nv">
                <button style={{
                    height: "25px",
                    padding: "20px",
                    fontSize: "1.2em",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "25px",
                    fontWeight: 800,
                    background: "#c2c2c2",
                    marginTop: "15px",
                    // dropShadow: ("16px", "16px", "10px", "#000"),

                }}><InfoOutlinedIcon />&nbsp; How To Upload To DocSend</button>
            </Link>

        </div >
    )
}

export default Keynotes;