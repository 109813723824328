const ShowColumns = [
  {
    field: "series",
    headerName: "Series",
    flex: 0.3,
    renderCell: (params) => (
      <span style={{ fontSize: "11px" }}>{params.value}</span>
    ),
  },

  {
    field: "season",
    headerName: "szn",
    flex: 0.05,
    renderCell: (params) => (
      <span style={{ fontSize: "10px" }}>{params.value}</span>
    ),
  },
  {
    field: "episode",
    headerName: "ep",
    flex: 0.05,
    renderCell: (params) => (
      <span style={{ fontSize: "10px" }}>{params.value}</span>
    ),
  },
  {
    field: "title2",
    headerName: "Episode",
    flex: 0.35,
    renderCell: (params) => (
      <span style={{ fontSize: "10px" }}>{params.value}</span>
    ),
  },

  {
    field: "air",
    headerName: "Airdate",
    flex: 0.15,
    renderCell: (params) => (
      <span style={{ fontSize: "10px" }}>{params.value}</span>
    ),
  },

  {
    field: "brand",
    headerName: "Brand(s)",
    flex: 0.3,
    renderCell: (params) => (
      <span style={{ fontSize: "10px" }}>{params.value}</span>
    ),
  },

  {
    field: "talent",
    headerName: "Talent",
    flex: 0.4,
    renderCell: (params) => (
      <span style={{ fontSize: "10px" }}>{params.value}</span>
    ),
  },

  {
    field: "recipe",
    headerName: "Recipes",
    flex: 0.4,
    renderCell: (params) => (
      <span style={{ fontSize: "10px" }}>{params.value}</span>
    ),
  },
];

export default ShowColumns;
