import React, { useState, useEffect } from "react";

import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import '../../components/visuals/design/pantry.css'

import SNheader2 from "../../components/SN/brandSN/SNheader2";

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import ShoppingCartCheckoutOutlinedIcon from '@mui/icons-material/ShoppingCartCheckoutOutlined';

const PantryStore = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    function hexToRgba(hex, opacity) {
        hex = hex.replace('#', '');
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
    }

    const baseUrl = window.location.href;

    // functionality
    const [store, setStore] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [displayedProducts, setDisplayedProducts] = useState([]);

    useEffect(() => {
        const fetchStore = async () => {

            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantry/store`);
            const json = await response.json()

            if (response.ok) {
                setStore(json)

            }
        }
        fetchStore()
    }, []);

    console.log(store)

    const PRODUCTS_PER_PAGE = 5 * 5;

    const [selectedCategory, setSelectedCategory] = useState('');

    useEffect(() => {
        // Filter products by the selected category if there is one selected
        const filteredProducts = selectedCategory
            ? store.filter((product) => product.category === selectedCategory)
            : store;

        // Calculate the slice of products to be displayed
        const start = currentPage * PRODUCTS_PER_PAGE;
        const end = start + PRODUCTS_PER_PAGE;
        setDisplayedProducts(filteredProducts.slice(start, end));
    }, [store, currentPage, selectedCategory]);

    const handleNext = () => {
        // Increment current page to load the next set of products
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePrevious = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
        setCurrentPage(0); // Reset to the first page
    };

    //click
    const handleProductClick = (clip) => {
        // Ensure clip is URL-friendly. This might need adjustments based on your actual clip format.

        window.open(`${baseUrl}/pdp/${clip}`, '_blank');
    };

    return (
        <div style={{ paddingLeft: "10px" }}>
            <div>
                <SNheader2 />
            </div>
            <h2 style={{ color: "#fff", fontWeight: 600 }}>All products</h2>
            <div>
                <select value={selectedCategory} onChange={handleCategoryChange} className="select">
                    <option value="">All Categories</option>
                    <option value="ingredient">Ingredient</option>
                    <option value="cookbook">Cookbook</option>
                    <option value="syrup">Syrup</option>
                    <option value="cookware">Cookware</option>
                    <option value="donation">Donation</option>
                    <option value="meat">Meat</option>
                    <option value="seafood">Seafood</option>
                    <option value="spice">Spice</option>
                    <option value="whiskey">Whiskey</option>
                    <option value="apparel">Apparel</option>
                    <option value="apertif">Aperitif</option>
                    <option value="non-alc">Non-Alcoholic</option>
                </select>
            </div>
            <div className="product-grid">
                {displayedProducts.map(product => (
                    <div key={product._id} className="product-card" style={{ padding: '1rem' }} onClick={() => handleProductClick(product.clip)}>
                        <div className="product-card-header">
                            <span>FEATURED</span>
                            <span>❤</span> {/* Use an actual icon or image */}
                        </div>
                        <img src={product.skImage} alt={'img missing'} className="product-image" />
                        <h3 className="product-title">{product.productTitle}</h3>
                        <div className="product-card-footer">
                            <button><InfoOutlinedIcon />Info</button>
                            <button><ShoppingCartOutlinedIcon />Add to Cart</button>
                            <button><ShoppingCartCheckoutOutlinedIcon />Buy Now</button>
                        </div>
                    </div>
                ))}
            </div>


            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '1rem' }}>
                {currentPage > 0 && (
                    <button onClick={handlePrevious}>
                        Back
                    </button>
                )}
                {(currentPage + 1) * PRODUCTS_PER_PAGE < store.length && (
                    <button onClick={handleNext}>
                        Next
                    </button>
                )}
            </div>
        </div>


    );
}
export default PantryStore;
