import { tokens } from "../theme"
import { useTheme } from "@mui/material"
import BrandComparison from "../components/SN/campaigns/BrandComparison";

const BrandSales = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (

        // <div style={{ marginLeft: "10px" }}>
        //     <h1>under contruction. BRB</h1>
        // </div>
        <div style={{ marginLeft: "10px" }}>
            {/* holding */}
            <div>
                <h2 style={{ color: colors.SKcustom[10], fontWeight: 600, paddingLeft: "10px", marginBottom: "-5px" }}>Spirits Network (USA)</h2>

                <p style={{ backgroundColor: colors.grey[100], color: colors.SKcustom[15], paddingLeft: "5px", marginLeft: "10px", maxWidth: "320px", fontSize: "11px" }}>Click on a holding company to view breakdown of sales by brand.</p>
                <div style={{ margin: "15px" }}><BrandComparison /></div>
            </div>
        </div>

    );

};

export default BrandSales;

