import React from 'react';
import {tokens} from '../../theme'
import { useTheme } from "@mui/material";

const Timeline = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const events = [
    {
        title: 'QUALIFY THE LEAD',
        date: 'STEP 1',
        description: [
            'Does this client have money and will they spend it on video?',
            'How much do they spend on video?',
            'How much do they spend on paid media?',
            'What are the problems the client is facing? i.e. Touchpoints are scattered, Cannot talk to their audience',
            'Level-set to ensure client understands offering i.e. Do they know CTV, Fast Channel, etc',
            'Send the sizzle & capabilities deck'
        ]
    },
    {
        title: 'EMAIL RECOMMENDATION',
        date: 'STEP 2',
        description: [
            '"Based on our last call you told me the challenges your company faces, here is what we recommend..."',
            '"Name of series" priced at "$" per episode with "X" guaranteed imp/views',
                        'Targeting these markets: "X, Y, Z"',
                        'Distributed on multi-devices including CTV, AppleTV, mobile, tablet, web',
                        'Show examples of unique NBTV tools i.e. Buy Bar, Brand Channel, DropZone'
        ]
    },
    {
        title: 'TAKE CLIENT FEEDBACK & CLOSE',
        date: 'STEP 3',
        description: ['Send follow-up email that includes:',
                        'DocuSign link to Partnership Agreement',
                        'Jumpstart Vendor Set-Up'
        ]
    },
        {
        title: 'ONCE THE DEAL IS CLOSED',
        date: 'STEP 4',
        description: ['Schedule a kick-off meeting',
                        'Create and share project brief',
                        'Set-up campaign in Monday.com'
        ]
    },
            {
        title: 'SEND WRAP UP REPORT',
        date: 'STEP 5',
        description: ['Include Impressions + Views',
                        'Include screenshots of Social Posts and Emails',
                        'Include link to Brand Channel & Episode'
        ]
    },
];

  return (
    <div className="timeline">
        <div className="timeline-blocks">

            {events.map((event, index) => (
                <div className="block" key={index}>

                    <div 
                    className="header"
                    style={{color:"#000", fontSize:"1.3em"}}>
                       {event.date}
                    <div style={{color:"#000"}}>
                        {event.title}</div>
                    </div>
                  
                    <ul>
                    {event.description.map((item, index) => (
                        <li className="pinho" 
                            key={index}
                            style={{color:colors.SKcustom[10], fontSize:"1.1em"}}>
                            {item}
                        </li>
                    ))}
                    </ul>
                    
                </div>
            ))}

        </div>
    </div>
  );
};

export default Timeline;
