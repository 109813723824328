import React, { useState, useEffect } from "react";
import { Box, TextField } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";

import Bc24_line from "./linegraphs/Uk24_line";
import Bc23_line from "./linegraphs/Uk23_line";
import Bc22_line from "./linegraphs/Uk22_line";
import Bc21_line from "./linegraphs/Uk21_line";

import BottleColumns from "../../visuals/columns/UKBottleColumns";

//functiionality
const BottlesBC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [searchTerm, setSearchTerm] = useState("");

  //2024
  const [bottles24, setBottles24] = useState([]);
  useEffect(() => {
    const fetchBottles = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/ukbc/four`
      );
      const data = await response.json();

      const formatted24 = data.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );

      setBottles24(formatted24);
    };
    fetchBottles();
  }, []);


  //2023
  const [bottles23, setBottles23] = useState([]);
  useEffect(() => {
    const fetchBottles = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/ukbc/three`
      );
      const data = await response.json();

      const formatted23 = data.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );

      setBottles23(formatted23);
    };
    fetchBottles();
  }, []);

  //2022
  const [bottles22, setBottles22] = useState([]);
  useEffect(() => {
    const fetchBottles = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/ukbc/two`
      );
      const data = await response.json();

      const formatted22 = data.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );

      setBottles22(formatted22);
    };
    fetchBottles();
  }, []);

  //2021
  const [bottles21, setBottles21] = useState([]);
  useEffect(() => {
    const fetchBottles = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/ukbc/one`
      );
      const data = await response.json();

      const formatted21 = data.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );

      setBottles21(formatted21);
    };
    fetchBottles();
  }, []);

  const [activeYear, setActiveYear] = useState(2024);
  const handleButtonClick = (year) => {
    setActiveYear(year);
  };

  const filterRows = (rows, searchTerm) => {
    let results = rows;
    if (searchTerm) {
      results = results.filter((row) => {
        const fieldValue = Object.values(row)
          .map((value) => (value || "").toString().toLowerCase())
          .join(" ");
        return fieldValue.includes(searchTerm.toLowerCase());
      });
    }

    return results;
  };

  let rows = [];
  let graphComponent = null;

  switch (activeYear) {
    case 2024:
      rows = bottles24;
      graphComponent = <Bc24_line />;
      break;
    case 2023:
      rows = bottles23;
      graphComponent = <Bc23_line />;
      break;
    case 2022:
      rows = bottles22;
      graphComponent = <Bc22_line />;
      break;
    case 2021:
      rows = bottles21;
      graphComponent = <Bc21_line />;
      break;
    default:
      rows = bottles24;
      graphComponent = <Bc24_line />;
      break;
  }

  // rows = some bottles from a year
  // lets filter those bottles down based on searchTerm
  rows = filterRows(rows, searchTerm);

  //display
  return (
    <Box m="20px">
      <h2>UK Bottle Sales</h2>
      <div>
        <button onClick={() => handleButtonClick(2021)}>2021</button>
        <button onClick={() => handleButtonClick(2022)}>2022</button>
        <button onClick={() => handleButtonClick(2023)}>2023</button>
        <button onClick={() => handleButtonClick(2024)}>2024</button>
      </div>
      {graphComponent}

      <TextField
        label="Search Campaigns"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        variant="standard"
        margin="normal"
        size="small"
        InputLabelProps={{
          style: {
            fontStyle: "italic",
            fontSize: "10px",
          },
        }}
      />

      <Box
        m="5px 0 0 0"
        height="100vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            lineHeight: "10px",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            fontSize: "12px",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
            maxHeight: 50,
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
            fontSize: "10px",
          },
        }}
      >
        <DataGrid
          getRowId={(row) => row._id}
          rows={rows}
          headerHeight={25}
          rowHeight={30}
          columns={BottleColumns}
          components={{ Toolbar: GridToolbar }}
          sortingOrder={["asc", "desc"]}
        />
      </Box>
    </Box>
  );
};

export default BottlesBC;
