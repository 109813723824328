const BottleColumns = [
    {
      field: "date",
      headerName: "Date",
      flex: 0.1,
      sortDirection: "desc",
      renderCell: (params) => (
      <span style={{ fontSize: '11px' }}>{params.value}</span>),

    },
    {
      field: "units",
      headerName: "Qty",
      flex: 0.05,
            renderCell: (params) => (
      <span style={{ fontSize: '11px' }}>{params.value}</span>),
      
    },
    {
      field: "product",
      headerName: "Product",
      flex: 0.2,
      renderCell: (params) => (
      <span style={{ fontSize: '11px' }}>{params.value}</span>),
    },
    {
      field: "subtotal",
      headerName: "Subtotal",
      flex: 0.1,
      valueFormatter: ({ value }) => (value ?? 0).toLocaleString("en-US", {
          style: "currency",
          currency: "USD"
            }), 
    },
    {
      field: "holding",
      headerName: "Holding",
      renderCell: (params) => (
      <span style={{ fontSize: '11px' }}>{params.value}</span>),
    },
            {
      field: "order_id",
      headerName: "order ID",
      flex: 0.1,
      renderCell: (params) => (
      <span style={{ fontSize: '11px' }}>{params.value}</span>),
    },
    
  ];

    export default BottleColumns