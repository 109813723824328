import { useEffect, useState } from "react"
import { Line } from "react-chartjs-2";
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

//aesthetics
import { tokens } from "../../theme"
import { useTheme } from '@mui/material';

import '../../components/visuals/design/videoMetrics.css';
import '../../components/visuals/design/sk.css';

Chart.register(...registerables, ChartDataLabels);

const PantryIG = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [instagramData, setInstagramData] = useState([])
    useEffect(() => {
        const fetchIG = async () => {

            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/meta`);
            const data = await response.json();

            if (response.ok) {
                setInstagramData(data.reachData);
            }
        }
        fetchIG()

    }, [])

    console.log('ig data', instagramData)

    const chartData = {
        labels: instagramData.map(item => item.title),
        datasets: [{
            label: 'Views',
            data: instagramData.map(item => (item.reach)),
            borderColor: "white",
            borderWidth: 1,
            backgroundColor: colors.SKcustom[12],
            // Custom property to store original string reach for tooltips
            reachStrings: instagramData.map(item => item.reach),
        }]
    };

    const options = {
        scales: {
            y: {
                beginAtZero: false,
                grid: {
                    display: false, // This will remove the grid lines
                    drawBorder: false, // This will remove the axis line
                },
                ticks: {
                    display: false,
                    color: colors.SKcustom[10],

                }
            },
            x: {
                grid: {
                    display: false, // This will remove the grid lines
                    drawBorder: false, // This will remove the axis line
                },
                ticks: {
                    font: {
                        size: "8px", // Example: setting the base font size to 20px; adjust as needed
                    },
                    color: colors.SKcustom[10],
                }
            }
        },
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                anchor: 'end',
                align: 'top',
                formatter: (value, context) => { return value.toLocaleString(); },
                color: colors.SKcustom[10],
                font: {
                    size: 9,
                }
            },
        },
        layout: {
            padding: {
                top: 20,
                bottom: 10,
                left: 0,
                right: 0,
            },
        },
        maintainAspectRatio: false,
        backgroundColor: 'transparent',
    };

    return (
        <div style={{ overflowX: 'auto', maxWidth: '600px', backgroundColor: 'transparent' }}>
            <div style={{ width: '400px' }}>
                <Line data={chartData} options={options} />
            </div>
        </div>
    );
};

export default PantryIG