import React from "react";
import { tokens } from "../theme";
import { useTheme } from "@mui/material";
// import { saveAs } from "file-saver";

import SNLogo from "../img/SN_whiteLogo.png"
import SNLogo2 from "../img/SN_goldLogo.png"

import SNCampTableALL from "../components/SN/campaigns/SnCampTableALL"

const Campaigns = () => {

    //aesthetics
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    function hexToRgba(hex, opacity) {
        hex = hex.replace('#', '');
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
    }

    return (
        <div style={{ marginLeft: '10px' }}>

            {/* //SN USA LOGO */}
            <div style={{ display: 'flex', flexDirectopn: 'row' }}>
                <div>
                    <img src={theme.palette.mode === 'dark' ? SNLogo2 : SNLogo2} alt="Image 3" style={{ width: '175px', marginBottom: "-10px", marginTop: '-10px' }} />
                </div>
                <div style={{ alignContent: 'center', fontSize: '20px', marginBottom: '5.5px', color: '#ab966d', marginLeft: '2px' }}>
                    •  USA
                </div>
            </div>



            <SNCampTableALL />

        </div>
    );
};
export default Campaigns;