// TotalPantryImps.js
export const fetchTotalPantryImps = async () => {
    // Fetch FB DB TOTAL
    const responseFb = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantryfb/total`);
    const dataFb = await responseFb.json();
    // const fb = responseFb.ok ? Number(dataFb) : 0;

    // Fetch IG LIVE TOTAL
    const responseInstagram = await fetch(`${process.env.REACT_APP_SERVER_URL}/meta/total`);
    const dataInstagram = await responseInstagram.json();
    // const totalInstagram = responseInstagram.ok ? Number(dataInstagram.sum) : 0;

    // Fetch GoogleAds DATABASE
    const responseGaImp = await fetch(`${process.env.REACT_APP_SERVER_URL}/googleads/total`);
    const dataGaImp = await responseGaImp.json();
    // const gaImp = responseGaImp.ok ? Number(dataGaImp) : 0;

    // Fetch email total DATABASE
    const responseEmail = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantryemails/total`);
    const dataEmail = await responseEmail.json();
    // const email = responseEmail.ok ? Number(dataEmail.sum) : 0;

    // Fetch page visits DATABASE
    const responsePageVisits = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantrypage/total`);
    const dataPageVisits = await responsePageVisits.json();
    // const pageVisits = responsePageVisits.ok ? Number(dataPageVisits) : 0;

    // Calculate total impressions
    const totalImp = dataFb + dataInstagram + dataGaImp + dataEmail + dataPageVisits;
    return totalImp;
};

export default fetchTotalPantryImps;
