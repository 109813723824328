import React, { useEffect, useState } from 'react';
import { useTheme } from "@mui/material";
import { Chart } from 'react-google-charts';

import { tokens } from "../../theme";
import "../../components/visuals/design/skSpecial.css"
import hexToRgba from '../visuals/design/hexToRgba';

import SNsignUps from './BigCommerce/UsBcUps';

const SNsubscribers = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const IGGenderData = [
        ['Gender', 'Percentage', { role: 'style' }, { role: 'annotation' }],
        ['F', 49.8, '#f76cc6', '49.8%'],
        ['M', 56.2, '#028cf3', '56.2%'],
    ];

    const FBGenderData = [
        ['Gender', 'Percentage', { role: 'style' }, { role: 'annotation' }],
        ['F', 62.6, '#f76cc6', '62.6%'],
        ['M', 47.4, '#028cf3', '47.4%'],
    ];

    const YTGenderData = [
        ['Gender', 'Percentage', { role: 'style' }, { role: 'annotation' }],
        ['F', 34.2, '#f76cc6', '34.2%'],
        ['M', 65.7, '#028cf3', '65.7%'],
    ];

    var options = {
        chartArea: { width: '60%' },
        hAxis: {
            minValue: 0,
            gridlines: { color: 'transparent' },
            textStyle: {
                fontSize: 12,

            },
        },
        vAxis: {
            minValue: 0,
            textPosition: 'none', // Hide the vertical axis labels
            gridlines: { color: 'transparent' },
            baselineColor: 'transparent',
            ticks: [],
        },
        legend: { position: 'none' }, // Hide the legend
        backgroundColor: 'transparent', // Removes the white background
        bars: 'vertical',
        bar: { groupWidth: '90%' }, // Adjusts bar width

        annotations: {
            alwaysOutside: true,
            textStyle: {
                fontSize: 12,
                fontWeight: 700,
                color: '#000', // Adjust the text color below the bars
            },
            stem: { color: 'transparent', length: 10 },
            enableInteractivity: false,
        },

    };

    //Klaviyo
    const [klaviyo, setKlaviyo] = useState();
    useEffect(() => {
        const fetchKlaviyo = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/klaviyo/active`);
                const data = await response.json();

                if (response.ok) {
                    setKlaviyo(data.profileCount);
                } else {
                    console.error('Response not ok:', data);
                }
            } catch (error) {
                console.error('Error fetching Klaviyo data:', error);
            }
        };
        fetchKlaviyo();
    }, []);

    const KlaviyoFormatted = klaviyo ? klaviyo.toLocaleString() : "Loading...";

    //Facebook
    const [facebook, setFacebook] = useState();
    useEffect(() => {
        const fetchFacebook = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/meta/snfb`);
                const data = await response.json();

                if (response.ok) {
                    setFacebook(data);
                } else {
                    console.error('Response not ok:', data);
                }
            } catch (error) {
                console.error('Error fetching Facebook data:', error);
            }
        };
        fetchFacebook();
    }, []);

    const FacebookFormatted = facebook.toLocaleString()

    //data
    const IG = 13718
    // const FB = 44315
    const YT = 2732



    const totalSubscribers = IG + facebook + YT + klaviyo

    return (
        <div className="columnLeft">

            {/* SUBSCRIBERS */}
            <div style={{ borderTop: '2px solid', borderColor: colors.SKcustom[802], width: '80%', height: '24px', color: colors.SKcustom[802], fontSize: '20px', fontWeight: 800, marginBottom: '15px' }}>
                Subscribers: <span style={{ color: colors.grey[300], fontWeight: 400 }}>{totalSubscribers.toLocaleString()} </span>
            </div>

            <div className="rowPlain" style={{ marginBottom: '20px' }} >

                {/* Klaviyo */}
                <div className="sourceStatL" style={{ color: colors.SKcustom[10], borderColor: colors.SKcustom[10] }}>
                    <div className="sourceStatHeader">Klaviyo:</div>
                    <div style={{ color: colors.SKcustom[20] }}>{KlaviyoFormatted}&nbsp;Active</div>

                    <div style={{ width: '50%', height: '100px', backgroundColor: hexToRgba(colors.SKgrey[100], 1), borderRadius: '10px', marginTop: '5px', paddingBottom: '5px' }}><p style={{ color: '#000', paddingLeft: '5px' }}>N/A</p></div>
                </div>

                {/* Instagram*/}
                <div className="sourceStatL" style={{ color: colors.SKcustom[10], borderColor: colors.SKcustom[10] }}>
                    <div className="sourceStatHeader"> Instagram:</div>
                    <div style={{ color: colors.SKcustom[20] }}>13,718&nbsp;followers</div>

                    <div style={{ width: '50%', height: '100px', backgroundColor: hexToRgba(colors.SKgrey[100], 1), borderRadius: '10px', marginTop: '5px', paddingBottom: '5px' }}><Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="100%"
                        data={IGGenderData}
                        options={options}
                    /></div>
                </div>

                {/* Facebook */}
                <div className="sourceStatL" style={{ color: colors.SKcustom[10], borderColor: colors.SKcustom[10] }}>
                    <div className="sourceStatHeader">Facebook:</div>
                    <div style={{ color: colors.SKcustom[20] }}>{FacebookFormatted}&nbsp;followers</div>

                    <div style={{ width: '50%', height: '100px', backgroundColor: hexToRgba(colors.SKgrey[100], 1), borderRadius: '10px', marginTop: '5px', paddingBottom: '5px' }}><Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="100%"
                        data={FBGenderData}
                        options={options}
                    /></div>
                </div>

                <div className="sourceStatR" style={{ color: colors.SKcustom[10], borderColor: colors.SKcustom[10] }}>
                    <div className="sourceStatHeader">YouTube:</div>
                    <div style={{ color: colors.SKcustom[20] }}>2,732&nbsp;followers</div>

                    <div style={{ width: '50%', height: '100px', backgroundColor: hexToRgba(colors.SKgrey[100], 1), borderRadius: '10px', marginTop: '5px', paddingBottom: '5px' }}><Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="100%"
                        data={YTGenderData}
                        options={options}
                    /></div>
                </div>


            </div>




        </div >
    )
}

export default SNsubscribers;
