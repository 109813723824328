import React from "react";
import { Box } from "@mui/material";
import { tokens } from "../theme";
import { useTheme } from "@mui/material";

import SNsubscribers from "../components/SN/SNsubscribers";
import UsBcUps from "../components/SN/BigCommerce/UsBcUps";
import BOTM from "../pages/BOTM";

const SNmembers = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const lineStyle = {
        width: "100%",
        height: "2px",
        backgroundColor: colors.SKcustom[10],
        marginTop: "20px",
        marginBottom: "20px"
    }

    return (
        // 
        <Box m="20px">

            <h2 style={{ color: colors.SKcustom[10], marginBottom: "10px", fontWeight: 800 }}>USA Subscribers</h2>

            <div style={{ marginBottom: '20px' }}>
                <SNsubscribers />
            </div>

            <div style={{ marginBottom: '20px' }}>
                <UsBcUps />
            </div>

            <div style={lineStyle}></div>
            <BOTM />



        </Box>
    )

};

export default SNmembers