import React from "react";
import { useEffect, useState, useContext } from "react"
import { useParams } from 'react-router-dom';
import { tokens } from "../theme"
import { useTheme } from "@mui/material";

const ShowPage = () => {

  //aesthetics: 
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [show, setShow] = useState([]);
  console.log(show);
  const { clip } = useParams();

  const metadata = {
    color: colors.SKcustom[20],
    margin: "0px 10px 0px 5px",
    fontWeight: 400
  }

  //occurs after render
  useEffect(() => {
    const fetchShow = async () => {
      //initiate HTTP request
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/shows/clip/${clip}`);
      const data = await response.json();
      console.log('data in showpage', data)
      setShow(data);
    }

    fetchShow()

  }, [clip]);

  if (!show) {
    return <div>Loading...</div>;
  }

  return (
    <div className="Showcontainer" style={{ color: colors.SKcustom[10] }}>

      <div className="row2">
        <h1 style={{ color: colors.SKcustom[10] }}>{show.series}<span className="sp" style={{ fontSize: ".8em" }}>   s:{show.season} e:{show.episode}</span></h1>
        <h1 style={{ color: colors.SKcustom[20] }}>{show.title2} </h1>
      </div>



      <img className="show" src={show.pic} />
      {/* <a href={show.pic} download={show.title}><FileDownloadOutlinedIcon /></a> */}

      {/* <button onClick={() => downloadImage(show.pic)}><FileDownloadOutlinedIcon /></button>
        <p style={{ color: colors.SKcustom[10] }}>{show.description}</p> */}

      <div className="column" style={{ color: colors.SKcustom[10], width: "800px" }}>

        <h1 style={{ color: colors.SKcustom[10], marginBottom: "0px" }}> Air date: <span style={metadata}>{show.air}</span></h1>

        <h1 style={{ color: colors.SKcustom[10], marginBottom: "0px" }}> Brand: <span style={metadata}>{show.brand}</span></h1>

        <h1 style={{ color: colors.SKcustom[10], marginBottom: "0px" }}> Talent: <span style={metadata}>{show.talent}</span></h1>

      </div>

    </div >
  );
};

export default ShowPage;

//pic download
// let downloadImage = url => {
//   let urlArray = url.split("/")
//   let bucket = urlArray[3]
//   let key = `${urlArray[4]}`
//   console.log('ff', urlArray, bucket, key)
//   let s3 = new AWS.S3({ params: { Bucket: bucket } })
//   let params = { Bucket: bucket, Key: key }
//   s3.getObject(params, (err, data) => {
//     console.log('err', err, data)
//     let blob = new Blob([data.Body], { type: data.ContentType });
//     let link = document.createElement('a');
//     link.href = window.URL.createObjectURL(blob);
//     link.download = url;
//     link.click();
//   })
// }