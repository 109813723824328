import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material';
import { tokens } from '../../theme'

import '../../components/visuals/design/videoMetrics.css'


import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, Tooltip, Legend, CategoryScale } from 'chart.js';

ChartJS.register(LineElement, PointElement, LinearScale, Title, Tooltip, Legend, CategoryScale);

const BehaviorMeta = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    function hexToRgba(hex, opacity) {
        hex = hex.replace('#', '');
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        return `rgba(${r},${g},${b},${opacity})`;
    }

    const [data, setData] = useState([]);

    const periodNameMapping = {
        '01-2024': 'Jan',
        '02-2024': 'Feb',
        '03-2024': 'Mar',
        '04-2024': 'Apr',
        '05-2024': 'May',
        '06-2024': 'Jun',
        '07-2024': 'Jul',
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/audience/meta`);
                if (response.ok) {
                    const fetchedData = await response.json();
                    setData(fetchedData);
                } else {
                    console.error('Failed to fetch data');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();
    }, []);

    const userData = {
        labels: data.map(item => periodNameMapping[item.period] || item.period),
        datasets: [
            {
                label: 'FB',
                data: data.map(item => item.reachFB),
                borderColor: 'rgba(75, 192, 192, 9)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                fill: false,
                tension: 0,
                borderWidth: 2,
                pointRadius: 3
            },
            {
                label: 'IG',
                data: data.map(item => item.reachIG),
                borderColor: 'rgba(153, 102, 255, 1)',
                backgroundColor: 'rgba(153, 102, 255, 0.2)',
                fill: false,
                tension: 0,
                borderWidth: 2,
                pointRadius: 3
            },
        ],
    };


    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    boxWidth: 10,
                    font: { size: 8 }
                },
            },
            title: {
                display: true,
                text: 'Reach',
                font: { size: 10 }
            },
            datalabels: {
                display: false,
                align: 'top',
                font: { size: 8 },
                formatter: function (value, context) {
                    if (typeof value === 'number' || value instanceof Date) {
                        return value.toLocaleString();
                    } else {
                        console.error('Value is not a number or date:', value);
                        return value;
                    }
                }
            }
        },
        scales: {
            y: {
                ticks: {
                    display: true,
                    font: { size: 8 },
                },
                grid: {
                    display: false,
                    drawBorder: false
                },
            },
            x: {
                ticks: { font: { size: 8 }, },
                grid: {
                    display: false,
                },
            },
        },
    };



    return (
        <div className="hRow">
            <div className="chartRow" style={{ marginRight: '20px' }}>
                <Line data={userData} options={options} />
            </div>

        </div>
    );
};

export default BehaviorMeta;