import React, { useEffect, useState } from 'react';

//aesthetics
import { tokens } from "../../theme"
import { useTheme, TextField } from '@mui/material';
import HexToRgba from "../visuals/design/hexToRgba";
import '../../components/visuals/design/videoMetrics.css';
import '../../components/visuals/design/sk.css';

import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';


ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);


const PantryOrders = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [showAll, setShowAll] = useState(false);
    const [orders, setOrders] = useState([]);
    const [displayedOrders, setDisplayedOrders] = useState([]);
    const [monthlySales, setMonthlySales] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');
    const itemsPerPage = 15;

    useEffect(() => {
        const fetchOrders = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantrysales/orders`);
            const data = await response.json();

            if (response.ok) {
                setOrders(data);
                aggregateMonthlySales(data);
                setDisplayedOrders(data.slice(0, itemsPerPage));
            }
        };
        fetchOrders();
    }, []);

    const aggregateMonthlySales = (data) => {
        const salesByMonth = data.reduce((acc, order) => {
            const month = new Date(order.date).toLocaleString('default', { month: 'short', year: 'numeric' });
            if (!acc[month]) acc[month] = 0;
            acc[month] += order.subtotal;
            return acc;
        }, {});

        const salesArray = Object.keys(salesByMonth).map(month => ({
            month,
            sales: salesByMonth[month]
        }));

        setMonthlySales(salesArray);
    };

    const chartData = {
        labels: monthlySales.map(sale => sale.month),
        datasets: [
            {
                label: 'Monthly Sales',
                data: monthlySales.map(sale => sale.sales),
                borderColor: colors.SKcustom[10],
                backgroundColor: HexToRgba(colors.SKcustom[10], 0.2),
                fill: true,
            },
        ],
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: 'Monthly Sales',
            },
        },
    };

    const handleSort = (field) => {
        if (sortField === field) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortOrder('asc');
        }
    };

    useEffect(() => {
        console.log('Filtering orders...');
        let filteredData = filterOrders(orders, searchTerm);

        if (sortField) {
            filteredData.sort((a, b) => {
                if (a[sortField] < b[sortField]) return sortOrder === 'asc' ? -1 : 1;
                if (a[sortField] > b[sortField]) return sortOrder === 'asc' ? 1 : -1;
                return 0;
            });
        }

        console.log('Filtered data:', filteredData);
        setDisplayedOrders(filteredData.slice(0, showAll ? filteredData.length : itemsPerPage));
    }, [orders, searchTerm, sortField, sortOrder, showAll]);

    const filterOrders = (orders, searchTerm) => {
        if (!searchTerm) {
            return orders;
        }

        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        return orders.filter((order) => {
            const product = (order.product || "").toString().toLowerCase();
            return product.includes(lowerCaseSearchTerm);
        });
    };

    return (
        <div>
            <h1 style={{ color: colors.SKcustom[10], fontSize: '1.3em' }}>Daily Sales</h1>

            {/* <div style={{ width: '500px' }}>
                <Line data={chartData} options={chartOptions} />
            </div>

            <TextField
                label="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                variant="standard"
                margin="normal"
                size="small"
                InputLabelProps={{
                    style: {
                        fontStyle: "italic",
                        fontSize: "10px"
                    }
                }}
            /> */}

            <table className="tableSK">
                <thead>
                    <tr style={{ color: colors.SKcustom[20] }}>
                        <th className="campcolS" rowSpan={2} onClick={() => handleSort('date')}>Date</th>
                        <th className="campcolM" rowSpan={2} onClick={() => handleSort('product')}>Product</th>
                        <th className="campcolS" rowSpan={2} onClick={() => handleSort('subtotal')}>Subtotal</th>
                        <th className="campcolS" rowSpan={2} onClick={() => handleSort('state')}>State</th>
                        <th className="campcolS" rowSpan={2} onClick={() => handleSort('units')}>Units</th>
                    </tr>
                </thead>
                <tbody style={{ color: colors.SKcustom[10] }}>
                    {displayedOrders.map((order, index) => (
                        <tr key={order.order_id} style={{ backgroundColor: index % 2 === 0 ? HexToRgba(colors.SKcustom[25], 0.2) : 'transparent' }}>
                            <td className="campcell" style={{ paddingRight: "15px", borderColor: HexToRgba(colors.SKcustom[25], 0.2,) }}>{order.date}</td>
                            <td align="left" style={{ border: 'none' }}>{order.product}</td>
                            <td align="left" style={{ border: 'none' }}>${order.subtotal}</td>
                            <td align="left" style={{ border: 'none' }}>{order.state}</td>
                            <td align="left" style={{ border: 'none' }}>{order.units}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div style={{ paddingTop: "5px", textAlign: "left" }}>
                <button
                    onClick={() => setShowAll(!showAll)}
                    style={{
                        background: 'transparent',
                        color: colors.SKcustom[10],
                        padding: '5px 5px',
                        border: 'solid 0.5px',
                        cursor: 'pointer',
                        fontSize: '10px'
                    }}
                >
                    {showAll ? 'Show Less' : 'View All'}
                </button>
            </div>
        </div>
    );
};

export default PantryOrders;