import React, { useEffect, useState } from "react";
import { tokens } from "../theme";
import { useTheme } from "@mui/material";

//icons
import LiquorIcon from '@mui/icons-material/Liquor';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';

//page components
import Ws21_line from "../components/SN/BigCommerce/linegraphs/Ws21_line";
import Ws22_line from "../components/SN/BigCommerce/linegraphs/Ws22_line";
import Ws23_line from "../components/SN/BigCommerce/linegraphs/Ws23_line";
import Ws24_line from "../components/SN/BigCommerce/linegraphs/Ws24_line";

//architecture
import { Box } from "@mui/material";

const Whipshots = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // Tally Cards
    const tally1 = {
        color: `${colors.SKcustom[10]}`,
        height: 110,
        width: 120,
        paddingTop: '22px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    };

    const tally2 = {
        color: `${colors.SKcustom[10]}`,
        height: 110,
        width: 160,
        paddingTop: '22px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderLeft: '1px solid'
    };

    const tally3 = {
        color: `${colors.SKcustom[10]}`,
        height: 100,
        width: 200,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        borderLeft: '1px solid'
    };

    const tally4 = {
        color: `${colors.SKcustom[10]}`,
        height: 110,
        width: 130,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        borderLeft: '1px solid',
        marginTop: '25px'
    };

    const [activeComponent, setActiveComponent] = useState(<Ws24_line />);

    const handleComponentChange = (year) => {
        let component;
        if (year === '2021') {
            component = <Ws21_line />;
        } else if (year === '2022') {
            component = <Ws22_line />;
        } else if (year === '2023') {
            component = <Ws23_line />;
        } else if (year === '2024') {
            component = <Ws24_line />;
        }

        setActiveComponent(component);
    };

    // Metrics
    const [totalUnits, setTotalUnits] = useState(0);
    const [totalSales, setTotalSales] = useState(0);

    useEffect(() => {
        const fetchTotalUnits = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/whipshots/units`);
            const data = await response.json();
            if (response.ok) {
                setTotalUnits(data);
            }
        };

        const fetchTotalSales = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/whipshots/sales`);
            const data = await response.json();
            if (response.ok) {
                setTotalSales(data);
            }
        };
        fetchTotalUnits();
        fetchTotalSales();
    }, []);

    // Units 2024
    const [total24Qty, setTotal24Qty] = useState([]);

    useEffect(() => {
        const fetchTotal24Quantity = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/whipshots/units24`);
            const data = await response.json();
            setTotal24Qty(data);
        };
        fetchTotal24Quantity();
    }, []);

    // Units 2023
    const [total23Qty, setTotal23Qty] = useState([]);

    useEffect(() => {
        const fetchTotal23Quantity = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/whipshots/units23`);
            const data = await response.json();
            setTotal23Qty(data);
        };
        fetchTotal23Quantity();
    }, []);

    // Units 2022
    const [total22Qty, setTotal22Qty] = useState([]);

    useEffect(() => {
        const fetchTotal22Quantity = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/whipshots/units22`);
            const data = await response.json();
            setTotal22Qty(data);
        };
        fetchTotal22Quantity();
    }, []);

    // Units 2021
    const [total21Qty, setTotal21Qty] = useState([]);

    useEffect(() => {
        const fetchTotal21Quantity = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/whipshots/units21`);
            const data = await response.json();
            setTotal21Qty(data);
        };
        fetchTotal21Quantity();
    }, []);

    // Sales
    const [total24Sales, setTotal24Sales] = useState([]);
    const [total23Sales, setTotal23Sales] = useState([]);
    const [total22Sales, setTotal22Sales] = useState([]);
    const [total21Sales, setTotal21Sales] = useState([]);

    // Sales 2024
    useEffect(() => {
        const fetchTotal24Sales = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/whipshots/sales24`);
                const data = await response.json();
                setTotal24Sales(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchTotal24Sales();
    }, []);

    // Sales 2023
    useEffect(() => {
        const fetchTotal23Sales = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/whipshots/sales23`);
                const data = await response.json();
                setTotal23Sales(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchTotal23Sales();
    }, []);

    // Sales 2022
    useEffect(() => {
        const fetchTotal22Sales = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/whipshots/sales22`);
                const data = await response.json();
                setTotal22Sales(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchTotal22Sales();
    }, []);

    // Sales 2021
    useEffect(() => {
        const fetchTotal21Sales = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/whipshots/sales21`);
                const data = await response.json();
                setTotal21Sales(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchTotal21Sales();
    }, []);

    // Display
    return (
        <div style={{ marginLeft: "20px", width: "95%" }}>
            <h2 style={{ color: `${colors.SKcustom[10]}` }}>WhipShots</h2>

            <div className="Stackcontainer">
                <div style={tally1}>
                    <LiquorIcon className="Shannon" />
                    <p style={{ margin: '0', fontSize: 16, textTransform: 'uppercase', color: colors.SKcustom[17] }}>{totalUnits}</p>
                    <p style={{ margin: '0', fontSize: 11, textTransform: 'uppercase' }}>all time units sold</p>
                </div>

                <div style={tally2}>
                    <PaidOutlinedIcon className="Shannon" />
                    <h3 style={{ margin: '0', fontSize: 16, fontWeight: '300', color: colors.SKcustom[17] }}>{totalSales}</h3>
                    <p style={{ margin: '0', fontSize: 11, textTransform: 'uppercase' }}>all time sales</p>
                </div>

                <div style={tally3}>
                    <div>
                        <GroupsOutlinedIcon className="Shannon" />
                        <h3 style={{ margin: '0', fontSize: 16, textTransform: 'uppercase', fontWeight: '300', color: colors.SKcustom[17] }}>448,651</h3>
                        <p style={{ margin: '0', fontSize: 11, textTransform: 'uppercase' }}>imps</p>
                    </div>

                    <div>
                        <OndemandVideoOutlinedIcon className="Shannon" />
                        <h3 style={{ margin: '0', fontSize: 16, textTransform: 'uppercase', fontWeight: '300', color: colors.SKcustom[17] }}>69,440</h3>
                        <p style={{ margin: '0', fontSize: 14, textTransform: 'uppercase' }}>views</p>
                    </div>
                </div>

                {/* other years */}
                <div style={tally4}>
                    <table style={{ color: colors.SKcustom[10] }}>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Units</th>
                                <th>Sales</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td className="t_header">2024</td>
                                <td style={{ color: colors.SKcustom[17] }}>{total24Qty}</td>
                                <td style={{ color: colors.SKcustom[17] }}>{total24Sales} </td>
                            </tr>
                            <tr>
                                <td className="t_header">2023</td>
                                <td style={{ color: colors.SKcustom[17] }}>{total23Qty}</td>
                                <td style={{ color: colors.SKcustom[17] }}>{total23Sales} </td>
                            </tr>
                            <tr>
                                <td className="t_header">2022</td>
                                <td style={{ color: colors.SKcustom[17] }}>{total22Qty}</td>
                                <td style={{ color: colors.SKcustom[17] }}>{total22Sales}</td>
                            </tr>
                            <tr>
                                <td className="t_header">2021</td>
                                <td style={{ color: colors.SKcustom[17] }}>{total21Qty}</td>
                                <td style={{ color: colors.SKcustom[17] }}>{total21Sales}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            {/* <div>
                <button style={{ margin: "5px" }} onClick={() => handleComponentChange('2021')}>2021</button>
                <button style={{ margin: "5px" }} onClick={() => handleComponentChange('2022')}>2022</button>
                <button style={{ margin: "5px" }} onClick={() => handleComponentChange('2023')}>2023</button>
                <button style={{ margin: "5px" }} onClick={() => handleComponentChange('2024')}>2024</button>
                {activeComponent}
                <Box
                    gricolumnFlexStart="span 12"
                    gridRow="span 6"
                    overflow="auto">
                </Box>
            </div> */}


        </div>
    );
};

export default Whipshots;
