import { useEffect, useState } from "react"
import { Bar } from "react-chartjs-2";

//aesthetics
import { tokens } from "../../theme"
import { useTheme } from '@mui/material';

import '../../components/visuals/design/videoMetrics.css';
import '../../components/visuals/design/sk.css';

const PageVisits = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [pageDataMarch, setPageDataMarch] = useState([]);
    const [pageDataApril, setPageDataApril] = useState([]);
    const [pageDataMay, setPageDataMay] = useState([]);
    const [pageDataJune, setPageDataJune] = useState([]);
    const [pageDataJuly, setPageDataJuly] = useState([]);

    useEffect(() => {
        const fetchData = async (month) => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantrypage/${month}`);
                if (!response.ok) {
                    throw new Error(`SK - not connecting to database for ${month}`);
                }
                const data = await response.json();
                return data.results || [];
            } catch (error) {
                console.error(`Let Shannon know there is an error with ${month}`, error);
                return [];
            }
        };

        const loadAllData = async () => {
            const marchData = await fetchData('march');
            setPageDataMarch(marchData);
            const aprilData = await fetchData('april');
            setPageDataApril(aprilData);
            const mayData = await fetchData('may');
            setPageDataMay(mayData);
            const juneData = await fetchData('june');
            setPageDataJune(juneData);
            const julyData = await fetchData('july');
            setPageDataJuly(julyData);
        };

        loadAllData();
    }, []);

    const commonPages = [...new Set([...pageDataMarch, ...pageDataApril, ...pageDataMay].map(item => item.page))];

    const chartData = {
        labels: commonPages,
        datasets: [
            {
                label: 'March',
                data: commonPages.map(page => {
                    const item = pageDataMarch.find(item => item.page === page);
                    return item ? item.views : 0;
                }),

                borderColor: '#ffff3f',
                backgroundColor: '#ffff3f',
                borderWidth: 1,
                barThickness: 15
            },
            {
                label: 'April',
                data: commonPages.map(page => {
                    const item = pageDataApril.find(item => item.page === page);
                    return item ? item.views : 0;
                }),
                borderColor: '#16db65',
                backgroundColor: '#16db65',
                borderWidth: 1,
                barThickness: 15
            },
            {
                label: 'May',
                data: commonPages.map(page => {
                    const item = pageDataMay.find(item => item.page === page);
                    return item ? item.views : 0;
                }),
                borderColor: '#00b4d8',
                backgroundColor: '#00b4d8',
                borderWidth: 1,
                barThickness: 15
            },
            {
                label: 'June',
                data: commonPages.map(page => {
                    const item = pageDataJune.find(item => item.page === page);
                    return item ? item.views : 0;
                }),
                borderColor: '#FF9966',
                backgroundColor: '#FF9966',
                borderWidth: 1,
                barThickness: 15
            },

            {
                label: 'July',
                data: commonPages.map(page => {
                    const item = pageDataJuly.find(item => item.page === page);
                    return item ? item.views : 0;
                }),
                borderColor: '#FF1DCE',
                backgroundColor: '#FF1DCE',
                borderWidth: 1,
                barThickness: 15
            }
        ]
    };

    const options = {
        scales: {
            y: {
                beginAtZero: false,
                min: 0,
                max: 1000,
                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    display: true,
                    color: colors.SKcustom[10],
                }
            },
            x: {
                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    font: {
                        size: "10px",
                    },
                    color: colors.SKcustom[10],
                }
            }
        },
        plugins: {
            legend: {
                display: true,
                labels: {
                    color: colors.SKcustom[10]
                }
            },
            datalabels: {
                display: true,
                anchor: 'end',
                align: 'top',
                color: colors.SKcustom[10],
                font: { size: 9 },
                rotation: -45,
                formatter: function (value, context) {
                    return value === 0 ? '' : value.toLocaleString();
                }
            }
        },
        layout: {
            padding: {
                top: 20,
                bottom: 10,
                left: 0,
                right: 0,
            },
        },
        maintainAspectRatio: false,
        backgroundColor: 'transparent',
    };

    return (
        <div style={{ overflowX: 'auto', maxWidth: '900px', backgroundColor: 'transparent' }}>
            <div style={{ width: '800px', height: '300px' }}>
                <Bar data={chartData} options={options} />
            </div>
        </div>
    );
};

export default PageVisits