import React from 'react';
import UKSNsignUps from '../components/SN/BigCommerce/UKSNsignUps';
import UkBottleBC from "../components/SN/BigCommerce/UkBottleBC";
import { Box } from "@mui/material";
import { tokens } from "../theme";
import { useTheme } from "@mui/material";


//functiionality
const UK = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    //display
    return (
        <Box m="20px">

            <h2 style={{ color: colors.SKcustom[10], marginBottom: "10px", fontWeight: 800 }}>UK Subscribers</h2>


            <div style={{ marginBottom: '20px' }}>
                <UKSNsignUps />
            </div>

            <UkBottleBC />
        </Box>
    );
};

export default UK;
