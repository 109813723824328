import React from "react";
import { useEffect, useState } from "react"
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import { saveAs } from "file-saver";

//icons
import LiquorIcon from '@mui/icons-material/Liquor';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';

function TastingKits() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  function hexToRgba(hex, opacity) {
    hex = hex.replace('#', '');
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
  }

  //Tally Cards
  const tally1 = {
    color: `${colors.SKcustom[10]}`,
    // backgroundColor: `${colors.SKcustom[40]}`,
    outlineStyle: 'solid',
    outlineColor: `${colors.SKcustom[10]}`,
    borderRadius: '5px',
    height: 110,
    width: 120,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '25px',
    paddingBottom: '10px',
    marginBottom: '5px',
    marginRight: '2px'
  };

  const tally2 = {
    color: `${colors.SKcustom[10]}`,
    // backgroundColor: `${colors.SKcustom[40]}`,
    outlineStyle: 'solid',
    outlineColor: `${colors.SKcustom[10]}`,
    borderRadius: '5px',
    height: 110,
    width: 160,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '25px',
    paddingBottom: '10px',
    marginBottom: '5px',
    marginRight: '2px'
  };

  const [orders, setOrders] = useState([]);
  const [totalUnits, setTotalUnits] = useState([]);
  const [totalSales, setTotalSales] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      //req.params.holding
      // Fetch product data
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/usbc/kit`);
      const data = await response.json();
      if (response.ok) {
        setOrders(data);
      }
    };
    fetchOrders();
  }, []);

  useEffect(() => {
    const fetchTotalUnits = async () => {
      //req.params.holding
      // Fetch product data
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/usbc/unitsallorders/kit`);
      const data = await response.json();
      if (response.ok) {
        setTotalUnits(data);
      }
    };

    fetchTotalUnits();

  }, []);

  useEffect(() => {
    const fetchTotalSales = async () => {
      //req.params.holding
      // Fetch product data
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/usbc/totalsales/kit`);
      const data = await response.json();
      if (response.ok) {
        setTotalSales(data);
      }
    };
    fetchTotalSales();

  }, []);
  const generateCSVContent = (data) => {
    const rows = data.map((order) => {
      return [
        order.product,
        order.total.units,
        order.total.subtotal,
        order['2024'].units,
        order[2024].subtotal,
        order['2023'].units,
        order[2023].subtotal,
        order['2022'].units,
        order[2022].subtotal,
        order['2021'].units,
        order[2021].subtotal,
        order['2020'].units,
        order[2020].subtotal,
      ];
    });

    const header = ["Product", "All Units", "All Sales", "2024 Units", "2024 Sales", "2023 Units", "2023 Sales", "2022 Units", "2022 Sales", "2021 Units", "2021 Sales", "2020 Units", "2020 Sales",]; // Add other headers as needed

    const csvContent = [header, ...rows].map((row) => row.join(",")).join("\n");
    return csvContent;
  };
  const handleDownloadCSV = (data) => {
    const csvContent = generateCSVContent(data);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "table_data.csv");
  };

  return (
    <div style={{ marginLeft: "10px" }}>

      <h2 style={{ color: `${colors.SKcustom[10]}` }}>Tasting Kits</h2>
      <div className="Stackcontainer">
        <div style={tally1}>
          <LiquorIcon className="Shannon" />
          <p style={{ margin: '0', fontSize: 16, textTransform: 'uppercase', color: colors.SKcustom[17] }}>{totalUnits}</p>

          <p style={{ margin: '0', fontSize: 11, textTransform: 'uppercase', color: colors.SKcustom[10] }}>all time units sold</p>
        </div>
        <div style={tally2}>
          <PaidOutlinedIcon className="Shannon" />

          <h3 style={{ margin: '0', fontSize: 16, fontWeight: '300', color: colors.SKcustom[17] }}>{totalSales}</h3>

          <p style={{ margin: '0', fontSize: 11, textTransform: 'uppercase', color: colors.SKcustom[10] }}>all time sales</p>
        </div>
      </div>


      <button className="neonButton" style={{ marginTop: "20px" }} onClick={() => handleDownloadCSV(orders)}>Download</button>

      <table>
        <thead style={{ textAlign: 'left', color: `${colors.SKcustom[10]}` }}>
          <tr>
            <th className="headers" style={{ backgroundColor: `${colors.SKcustom[0]}` }}>Product</th>
            <th className="headers" style={{ backgroundColor: hexToRgba(colors.SKcustom[30], 0.6) }}>All Units</th>
            <th className="headers" style={{ backgroundColor: hexToRgba(colors.SKcustom[30], 0.6) }}>All Sales</th>
            <th className="headers" style={{ backgroundColor: `${colors.SKcustom[0]}` }} >2024</th>
            <th className="headers" style={{ backgroundColor: `${colors.SKcustom[0]}` }}>Sales</th>
            <th className="headers" style={{ backgroundColor: `${colors.SKcustom[0]}` }} >2023</th>
            <th className="headers" style={{ backgroundColor: `${colors.SKcustom[0]}` }}>Sales</th>
            <th className="headers" style={{ backgroundColor: `${colors.SKcustom[0]}` }}>2022</th>
            <th className="headers" style={{ backgroundColor: `${colors.SKcustom[0]}` }}>Sales</th>
            <th className="headers" style={{ backgroundColor: `${colors.SKcustom[0]}` }}>2021</th>
            <th className="headers" style={{ backgroundColor: `${colors.SKcustom[0]}` }}>Sales</th>
            <th className="headers" style={{ backgroundColor: `${colors.SKcustom[0]}` }}>2020</th>
            <th className="headers" style={{ backgroundColor: `${colors.SKcustom[0]}` }}>Sales</th>
          </tr>
        </thead>

        <tbody className="table" style={{ color: `${colors.SKcustom[10]}` }}>
          {orders.map((order, index) => (
            <tr key={index}>
              <td className="cells" style={{ width: "20%", borderTop: `0.5px solid ${colors.SKcustom[0]}`, borderBottom: "none", borderLeft: "none", borderRight: "none" }}>{order.product}</td>

              <td className="cells" style={{ backgroundColor: hexToRgba(colors.SKcustom[30], 0.6), color: `${colors.SKcustom[10]}`, width: "5%", borderTop: `0.5px solid ${colors.SKcustom[0]}`, borderBottom: "none", borderLeft: "none", borderRight: "none" }}>{order.total.units}</td>

              <td className="cells" style={{ backgroundColor: hexToRgba(colors.SKcustom[30], 0.6), color: `${colors.SKcustom[10]}`, width: "7%", borderTop: `0.5px solid ${colors.SKcustom[0]}`, borderBottom: "none", borderLeft: "none", borderRight: "none" }}>{order.total.subtotal}</td>

              <td className="cells" style={{ width: "5%", borderTop: `0.5px solid ${colors.SKcustom[0]}`, borderBottom: "none", borderLeft: "none", borderRight: "none", textAlign: "center" }}>{order['2024'].units}</td>

              <td className="cells" style={{ width: "6%", borderTop: `0.5px solid ${colors.SKcustom[0]}`, borderBottom: "none", borderLeft: "none", borderRight: `0.5px solid ${colors.SKcustom[0]}` }}>{order[2024].subtotal}</td>

              <td className="cells" style={{ width: "5%", borderTop: `0.5px solid ${colors.SKcustom[0]}`, borderBottom: "none", borderLeft: "none", borderRight: "none", textAlign: "center" }}>{order['2023'].units}</td>

              <td className="cells" style={{ width: "6%", borderTop: `0.5px solid ${colors.SKcustom[0]}`, borderBottom: "none", borderLeft: "none", borderRight: `0.5px solid ${colors.SKcustom[0]}` }}>{order[2023].subtotal}</td>

              <td className="cells" style={{ width: "5%", borderTop: `0.5px solid ${colors.SKcustom[0]}`, borderBottom: "none", borderLeft: "none", borderRight: "none", textAlign: "center" }}>{order[2022].units}</td>

              <td className="cells" style={{ width: "6%", borderTop: `0.5px solid ${colors.SKcustom[0]}`, borderBottom: "none", borderLeft: "none", borderRight: `0.5px solid ${colors.SKcustom[0]}` }}>{order[2022].subtotal}</td>

              <td className="cells" style={{ width: "5%", borderTop: `0.5px solid ${colors.SKcustom[0]}`, borderBottom: "none", borderLeft: "none", borderRight: "none", textAlign: "center" }}>{order[2021].units}</td>

              <td className="cells" style={{ width: "6%", borderTop: `0.5px solid ${colors.SKcustom[0]}`, borderBottom: "none", borderLeft: "none", borderRight: `0.5px solid ${colors.SKcustom[0]}` }}>{order[2021].subtotal}</td>

              <td className="cells" style={{ width: "5%", borderTop: `0.5px solid ${colors.SKcustom[0]}`, borderBottom: "none", borderLeft: "none", borderRight: "none", textAlign: "center" }}>{order[2020].units}</td>

              <td className="cells" style={{ width: "6%", borderTop: `0.5px solid ${colors.SKcustom[0]}`, borderBottom: "none", borderLeft: "none", borderRight: `0.5px solid ${colors.SKcustom[0]}` }}>{order[2020].subtotal}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TastingKits;