import React, { useEffect, useState } from "react";
import { tokens } from '../../../theme';
import { useTheme, Dialog, IconButton, Select, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { saveAs } from "file-saver";
import '../../visuals/design/skSpecial.css';
import Breakdown from "./Breakdown";
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
// import Tally3 from "../../visuals/tally3";

const CampaignsTableAll = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const hexToRgba = (hex, opacity) => {
        hex = hex.replace('#', '');
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return `rgba(${r},${g},${b},${opacity})`;
    };

    const [loading, setLoading] = useState(true);
    const [loadingBackgroundColor, setLoadingBackgroundColor] = useState('lightblue');

    //DATA
    const [campaigns, setCampaigns] = useState([]);

    //TOTALS
    const [totalImps, setTotalImps] = useState(0);
    const [totalPlatformViews, setTotalPlatformViews] = useState(0);
    const [totalIGreach, setTotalIGreach] = useState(0);

    const [open, setOpen] = useState(false);
    const [selectedBrand, setSelectedBrand] = useState(null);

    // Group campaigns by "campaign" key and aggregate data
    const groupCampaigns = (campaigns) => {
        const grouped = {};

        campaigns.forEach((item) => {
            if (!grouped[item.campaign]) {
                grouped[item.campaign] = {
                    holding: item.holding,
                    brands: item.brands,
                    campaign: item.campaign,
                    live: item.live,
                    g_view_old: item.g_view_old,
                    IGreach: item.IGreach,
                    assets: [item.asset],
                };
            } else {
                // Earliest live date
                if (new Date(item.live) < new Date(grouped[item.campaign].live)) {
                    grouped[item.campaign].live = item.live;
                }

                // Sum IG reach
                grouped[item.campaign].IGreach += item.IGreach;

                // Sum viewTotal
                grouped[item.campaign].g_view_old += item.g_view_old;

                // Add asset to the list
                grouped[item.campaign].assets.push(item.asset);
            }
        });

        return Object.values(grouped);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/campaign2`);
                if (!response.ok) throw new Error('Database not reached.');

                const jsonData = await response.json();
                const groupedCampaigns = groupCampaigns(jsonData.campaigns);

                setTotalImps(jsonData.totalImps);
                setTotalPlatformViews(jsonData.totalPlatformViews)
                setTotalIGreach(jsonData.totalIGreach);
                setCampaigns(groupedCampaigns);
                setLoading(false); // Set loading to false after data is fetched
            } catch (error) {
                console.error('Fetch error:', error);
                setLoading(false); // Set loading to false even if there's an error
            }
        };
        fetchData();

    }, [props.holding]);

    const handleAssetClick = (asset, campaignDetails) => {
        setSelectedBrand({
            ...campaignDetails,
            asset: asset
        });
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const generateCSVContent = (campaigns) => {
        const rows = campaigns.map((item) => {
            return [
                item.holding,
                item.brands,
                item.campaign,
                item.live,
                item.g_view_old,
                item.IGreach
            ];
        });
        const header = ["Holding", "Brand", "Campaign", "Live", "View Total", "IG Reach"];
        const csvContent = [header, ...rows].map((row) => row.join(",")).join("\n");
        return csvContent;
    };

    const handleDownloadCSV = (campaigns) => {
        const csvContent = generateCSVContent(campaigns);
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
        saveAs(blob, "NBTVcampaigns.csv");
    };

    return (
        <div id="lato">

            <div>

                {loading ? (
                    <div style={{
                        backgroundColor: loadingBackgroundColor,
                        padding: '20px',
                        textAlign: 'left',
                        color: '#000',
                        fontSize: '14px'
                    }}>
                        Loading... plz hold.
                    </div>
                ) : (
                    <>
                        <div style={{
                            marginBottom: '10px', backgroundColor: 'white', paddingLeft: '5px', width: '200px'
                        }}>Not all data is here yet.</div>

                        <div style={{
                            paddingTop: '10px', marginTop: '10px', marginBottom: '10px', color: colors.SKcustom[10], fontWeight: 800
                        }}>SPIRITS    NETWORK    TOTALS:</div>


                        <div className="tally31" style={{ color: colors.SKcustom[10] }
                        } >

                            {/* IMP */}
                            < div >
                                <GroupsOutlinedIcon />
                                <h3 style={{ margin: '0', fontSize: 16, textTransform: 'uppercase', fontWeight: '500', color: colors.SKcustom[17] }}>
                                    {totalImps.toLocaleString()}
                                </h3>
                                <p style={{ margin: '0', fontSize: 11, textTransform: 'uppercase' }}>imps</p>
                            </div>

                            {/* VIEWS */}
                            <div>
                                <OndemandVideoOutlinedIcon className="Shannon" />
                                <h3 style={{ margin: '0', fontSize: 16, textTransform: 'uppercase', fontWeight: '500', color: colors.SKcustom[17] }}>
                                    {totalPlatformViews.toLocaleString()}
                                </h3>
                                <p style={{ margin: '0', fontSize: 11, textTransform: 'uppercase' }}>VIEWS</p>
                            </div>

                            {/* REACH */}
                            <div>
                                <OndemandVideoOutlinedIcon className="Shannon" />
                                <h3 style={{ margin: '0', fontSize: 16, textTransform: 'uppercase', fontWeight: '500', color: colors.SKcustom[17] }}>
                                    {totalIGreach.toLocaleString()}
                                </h3>
                                <p style={{ margin: '0', fontSize: 11, textTransform: 'uppercase' }}>reach</p>
                            </div>
                        </div>

                        <div style={{
                            paddingTop: '10px', marginTop: '10px', marginBottom: '10px', color: colors.SKcustom[10], fontWeight: 800, borderTop: '1px solid'
                        }}>BREAKDOWN BY CAMPAIGN:</div>

                        <table className="tableSK">
                            < thead >
                                <tr style={{ color: colors.SKcustom[20] }}>
                                    <th className="campcolS">Client <ExpandMoreOutlinedIcon style={{ fontSize: "12px" }} /></th>
                                    <th className="campcolS">Brand<ExpandMoreOutlinedIcon style={{ fontSize: "12px" }} /></th>
                                    <th className="campcolS">Campaign<ExpandMoreOutlinedIcon style={{ fontSize: "12px" }} /></th>
                                    <th className="campcolS">Live<ExpandMoreOutlinedIcon style={{ fontSize: "12px" }} /></th>
                                    <th className="campcolS">View Total<ExpandMoreOutlinedIcon style={{ fontSize: "12px" }} /></th>
                                    <th className="campcolS">IG Reach<ExpandMoreOutlinedIcon style={{ fontSize: "12px" }} /></th>
                                    <th className="campcolS">Assets</th>
                                </tr>
                            </thead>
                            <tbody style={{ color: colors.SKcustom[10] }}>
                                {campaigns.length > 0 ? (
                                    campaigns.map((item, index) => (
                                        <tr key={index} style={{ cursor: 'pointer', backgroundColor: index % 2 === 0 ? hexToRgba(colors.SKcustom[25], 0.2) : 'transparent' }}>
                                            <td className="campcell" style={{ borderColor: hexToRgba(colors.SKcustom[25], 0.2) }}>{item.holding}</td>
                                            <td className="campcell" style={{ borderColor: hexToRgba(colors.SKcustom[25], 0.2) }}>{item.brands}</td>
                                            <td className="campcell" style={{ borderColor: hexToRgba(colors.SKcustom[25], 0.2) }}>{item.campaign}</td>
                                            <td className="campcell" style={{ borderColor: hexToRgba(colors.SKcustom[25], 0.2) }}>{item.live}</td>
                                            <td className="campcell" style={{ borderColor: hexToRgba(colors.SKcustom[25], 0.2) }}>{item.g_view_old}</td>
                                            <td className="campcell" style={{ borderColor: hexToRgba(colors.SKcustom[25], 0.2) }}>{item.IGreach}</td>
                                            <td className="campcell" style={{ borderColor: hexToRgba(colors.SKcustom[25], 0.2) }}>
                                                <Select
                                                    value=""
                                                    displayEmpty
                                                    onChange={(e) => handleAssetClick(e.target.value, item)}
                                                >
                                                    <MenuItem value="" disabled>Select Asset</MenuItem>
                                                    {item.assets.map((asset, assetIndex) => (
                                                        <MenuItem key={assetIndex} value={asset}>{asset}</MenuItem>
                                                    ))}
                                                </Select>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={7} style={{ textAlign: "center" }}>No data available</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                            <IconButton
                                onClick={handleClose}
                                style={{ position: 'absolute', right: '10px', top: '10px' }}
                            >
                                <CloseIcon />
                            </IconButton>
                            {selectedBrand && <Breakdown details={selectedBrand} />}
                        </Dialog>
                    </>
                )
                }
            </div >
        </div >

    );
};

export default CampaignsTableAll;
