import React, { useState, useEffect } from 'react';
import '../components/visuals/design/sk.css';
import { tokens } from "../theme";
import { useTheme } from '@mui/material';

import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';

const Film = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [schedule, setSchedule] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: 'location', direction: 'asc' });

    useEffect(() => {
        const fetchSchedule = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/waitress/days`);
                if (response.ok) {
                    const data = await response.json();
                    setSchedule(data);
                } else {
                    console.error('Failed to fetch data');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchSchedule();
    }, []);

    const sortData = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }

        const sortedData = [...schedule].sort((a, b) => {
            if (key === 'characterTags') {
                const aCount = a.characterTags.length;
                const bCount = b.characterTags.length;
                if (aCount < bCount) return direction === 'asc' ? -1 : 1;
                if (aCount > bCount) return direction === 'asc' ? 1 : -1;
                return 0;
            } else {
                return 0;
            }
        });

        setSortConfig({ key, direction });
        setSchedule(sortedData);
    };

    // Function to generate CSV
    const downloadCSV = () => {
        const header = ['tag', 'days', 'scenes'];
        const rows = schedule.map(character => [
            character.tag,
            character.days,
            character.scenes.join('; ') // Use ';' as a separator to handle scenes array
        ]);

        const csvContent = [
            header.join(','), // Join header columns
            ...rows.map(row => row.map(cell => `"${cell}"`).join(','))
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', 'days.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div>
            <h2>Film Shooting Schedule</h2>
            <button onClick={downloadCSV} style={{ marginBottom: '10px' }}>
                <DownloadOutlinedIcon /> Download CSV
            </button>
            <table style={{ color: colors.SKcustom[10], width: '90%', marginLeft: '10px', borderCollapse: 'collapse' }}>
                <thead>
                    <tr>
                        <th onClick={() => sortData('tag')}>Character Tag</th>
                        <th onClick={() => sortData('days')}>Days</th>
                        <th onClick={() => sortData('scenes')}>Scenes</th>
                    </tr>
                </thead>
                <tbody>
                    {schedule.map((character, index) => (
                        <tr key={index}>
                            <td>{character.tag}</td>
                            <td>{character.days}</td>
                            <td>{character.scenes.join(', ')}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Film;