import React, { useState, useEffect } from 'react';
import { Chart } from "react-google-charts";

import '../visuals/design/sk.css'

const ByState = () => {

    const stateAbbreviations = {
        'Alabama': 'AL', 'Alaska': 'AK', 'Arizona': 'AZ', 'Arkansas': 'AR', 'California': 'CA',
        'Colorado': 'CO', 'Connecticut': 'CT', 'Delaware': 'DE', 'Florida': 'FL', 'Georgia': 'GA',
        'Hawaii': 'HI', 'Idaho': 'ID', 'Illinois': 'IL', 'Indiana': 'IN', 'Iowa': 'IA',
        'Kansas': 'KS', 'Kentucky': 'KY', 'Louisiana': 'LA', 'Maine': 'ME', 'Maryland': 'MD',
        'Massachusetts': 'MA', 'Michigan': 'MI', 'Minnesota': 'MN', 'Mississippi': 'MS',
        'Missouri': 'MO', 'Montana': 'MT', 'Nebraska': 'NE', 'Nevada': 'NV',
        'New Hampshire': 'NH', 'New Jersey': 'NJ', 'New Mexico': 'NM', 'New York': 'NY',
        'North Carolina': 'NC', 'North Dakota': 'ND', 'Ohio': 'OH', 'Oklahoma': 'OK',
        'Oregon': 'OR', 'Pennsylvania': 'PA', 'Rhode Island': 'RI', 'South Carolina': 'SC',
        'South Dakota': 'SD', 'Tennessee': 'TN', 'Texas': 'TX', 'Utah': 'UT', 'Vermont': 'VT',
        'Virginia': 'VA', 'Washington': 'WA', 'West Virginia': 'WV', 'Wisconsin': 'WI', 'Wyoming': 'WY'
    };

    const [list, setList] = useState([]);

    useEffect(() => {
        const fetchList = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/profilebc/state`);
                if (response.ok) {
                    const data = await response.json();

                    // Sort data by percentage and take top 15 states
                    const sortedData = data.sort((a, b) => b.percentage - a.percentage).slice(0, 15);

                    // Prepare data for the BarChart
                    const chartData = [
                        ['State', 'Percentage', { role: 'annotation' }], // Headers
                        ...sortedData.map((state) => [
                            `${stateAbbreviations[state.state] || state.state}`,
                            parseFloat(state.percentage),
                            `${state.percentage}%`
                        ]),
                    ];

                    setList(chartData);
                } else {
                    console.error('Failed to fetch data');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchList();
    }, []);

    return (
        <div >

            <div style={{ backgroundColor: 'transparent' }}>

                {list.length > 1 ? (
                    <Chart
                        chartType="BarChart"
                        height="100%"
                        data={list}
                        options={{
                            title: 'BigCommerce',
                            backgroundColor: 'transparent',
                            titleTextStyle: { color: 'black', fontSize: '8px', },
                            chartArea: { width: '25%', height: '100%', backgroundColor: 'transparent', top: 30, left: 30 },
                            hAxis: {
                                title: 'Based on purchases',
                                titleTextStyle: { color: 'transparent' },
                                textStyle: { color: 'transparent' },
                                minValue: 0,
                                format: 'decimal',
                                gridlines: { color: 'transparent' }
                            },
                            vAxis: {

                                titleTextStyle: { color: 'white' },
                                textStyle: { color: 'black', fontSize: 9 }
                            },
                            legend: { position: 'none' },
                            bars: 'horizontal',
                            bar: { groupWidth: '80%' },
                            colors: ['#4CAF50'],
                            annotations: {
                                alwaysOutside: true,
                                textStyle: {
                                    fontSize: 9,
                                    auraColor: 'none',
                                    color: 'black'
                                },
                                stem: {
                                    color: 'transparent', // Hide the line (stem)
                                },
                            },
                        }}
                    />
                ) : (
                    <p style={{ color: 'white' }}>Loading data...</p>
                )}
            </div>
        </div>

    );
};

export default ByState;