import React from 'react';
// import { tokens } from "../../theme";
// import { useTheme } from "@mui/material";

import Logo from '../../../img/SNlogo.png';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import PersonIcon from '@mui/icons-material/Person';

function SNheader2() {

    // const theme = useTheme();
    // const colors = tokens(theme.palette.mode);

    return (
        <div className="SNheader" style={{ backgroundColor: "#000" }}>
            <div >
                <img src={Logo} alt="Spirits Network" height="30em" />
            </div>

            <div className="header-buttons" style={{ color: "#ffffff" }}>

                <button onClick={() => window.location.href = "https://discover.spiritsnetwork.com/#/home"} style={{ color: "#ffffff" }}>DISCOVER</button>
                <button onClick={() => window.location.href = "https://discover.spiritsnetwork.com/#/spirits"} style={{ color: "#ffffff" }}>WATCH</button>
                <button onClick={() => window.location.href = "https://discover.spiritsnetwork.com/#/store"} style={{ color: "#ffffff", border: "2px solid #a99873", padding: "8px", borderRadius: "20px" }}>SHOP</button>
                <button onClick={() => window.location.href = "https://discover.spiritsnetwork.com/#/brands"} style={{ color: "#ffffff" }}>MAKE</button>

            </div>

            <div className="header-icons" style={{ color: "#ffffff" }}>
                <SearchIcon style={{ marginRight: '15px' }} />
                <ShoppingCartOutlinedIcon style={{ marginRight: '15px' }} />
                <PersonIcon />

            </div>
        </div>
    );
}

export default SNheader2;
