import React from "react";

const data = [
    { date: '01/01/24', product: 'New Years Day' },
    { date: '01/15/24', product: 'MLK Day' },
    { date: '05/27/24', product: 'Memorial Day' },
    { date: '06/19/24', product: 'Juneteenth' },
    { date: '07/04/24', product: 'Independence Day' },
    { date: '09/02/24', product: 'Labor Day' },
    { date: '10/14/24', product: 'Columbus Day' },
    { date: '11/28/24', product: 'Thanksgiving' },
    { date: '12/25/24', product: 'Christmas Day' },
];

const Holidays = () => {
    return (
        <div className="dz">
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Holiday</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, index) => (
                        <tr key={index}>
                            <td>{row.date}</td>
                            <td>{row.product}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Holidays;
