import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Logo from "../img/GN.png"
import Logo2 from "../img/GN_blue.png"
import SNLogo from "../img/SN_whiteLogo.png"
import SNLogo2 from "../img/SN_goldLogo.png"

import '../components/visuals/design/skSpecial.css'
import { tokens } from "../theme";
import { useTheme } from "@mui/material";
import hexToRgba from '../components/visuals/design/hexToRgba';

const Attic = () => {


    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
    };


    return (
        <div>

            <p> Attic</p>
            <div className="kingdom" >
                {/* SPIRITS NETWORK */}
                <div className="columnPlain">
                    <img src={theme.palette.mode === 'dark' ? SNLogo2 : SNLogo2} alt="Image 3" style={{ width: '250px', marginBottom: "-30px", marginLeft: '10px' }} />


                    <div className="phylum" style={{ backgroundColor: hexToRgba(colors.SKcustom[50], 0.8) }} >
                        <div className="columnPlain">
                            <div className="h1bsk" style={{ marginBottom: '6px' }}>
                                Members
                            </div>
                            <div className="rowCenter" style={{ width: '205px' }}>
                                <button className="phylumButton" onClick={() => handleNavigation('/SNmemberReach')} style={{ paddingRight: '10px', background: 'none', color: 'white', borderRight: '1px solid white' }}>
                                    Quantity
                                </button>
                                <button className="phylumButton" onClick={() => handleNavigation('/Audience')} style={{ marginLeft: '0px', background: 'none', color: 'white' }}>
                                    Quality
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="phylum" style={{ backgroundColor: hexToRgba(colors.SKcustom[50], 0.8) }} >
                        <div className="columnPlain">
                            <div className="h1bsk" style={{ marginBottom: '6px' }}>
                                Sales
                            </div>
                            <div className="rowCenter" style={{ width: '205px' }}>
                                <button className="phylumButton" onClick={() => handleNavigation('/Bottles')} style={{ paddingRight: '10px', background: 'none', color: 'white', borderRight: '1px solid white' }}>
                                    Overview
                                </button>
                                <button className="phylumButton" onClick={() => handleNavigation('/Bottles')} style={{ marginLeft: '0px', background: 'none', color: 'white' }}>
                                    Itemized
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="phylum" style={{ backgroundColor: hexToRgba(colors.SKcustom[50], 0.8) }} >
                        <div className="columnPlain">
                            <div className="h1bsk" style={{ marginBottom: '6px' }} onClick={() => handleNavigation('/Campaigns')}>
                                Campaigns
                            </div>
                            {/* <div className="rowCenter" style={{ width: '205px' }}>
                                <button className="phylumButton" onClick={() => handleNavigation('/Campaigns')} style={{ paddingRight: '10px', background: 'none', color: 'white', borderRight: '1px solid white' }}>
                                    Reach
                                </button>
                                <button className="phylumButton" onClick={() => handleNavigation('/')} style={{ marginLeft: '0px', background: 'none', color: 'white' }}>
                                    Sales
                                </button>
                            </div> */}
                        </div>
                    </div>


                    <div className="phylum" style={{ backgroundColor: hexToRgba(colors.SKcustom[50], 0.8) }} >
                        <div className="columnPlain">
                            <div className="h1bsk" style={{ marginBottom: '6px' }}>
                                CTV
                            </div>
                            <div className="rowCenter" style={{ width: '205px' }}>
                                <button className="phylumButton" onClick={() => handleNavigation('/')} style={{ paddingRight: '10px', background: 'none', color: 'white', borderRight: '1px solid white' }}>
                                    Vizio
                                </button>
                                <button className="phylumButton" onClick={() => handleNavigation('/')} style={{ marginLeft: '0px', background: 'none', color: 'white' }}>
                                    Other
                                </button>
                            </div>
                        </div>
                    </div>





                </div>

                {/* GOLF NATION */}
                <div className="columnPlain">
                    <img src={theme.palette.mode === 'dark' ? Logo : Logo2} alt="Image 3" style={{ width: '250px', marginBottom: "-30px", marginLeft: '10px' }} />


                    <div className="phylum" >
                        <div className="columnPlain">
                            <div className="h1bsk" style={{ marginBottom: '6px' }}>
                                Members
                            </div>
                            <div className="rowCenter" style={{ width: '205px' }}>
                                <button className="phylumButton" onClick={() => handleNavigation('/GNreach')} style={{ paddingRight: '10px', background: 'none', color: 'white', borderRight: '1px solid white' }}>
                                    Quantity
                                </button>
                                <button className="phylumButton" onClick={() => handleNavigation('/GNaudience')} style={{ marginLeft: '0px', background: 'none', color: 'white' }}>
                                    Quality
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="phylum" >
                        <div className="columnPlain">
                            <div className="h1bsk" style={{ marginBottom: '6px' }}>
                                Sales
                            </div>
                            <div className="rowCenter" style={{ width: '205px' }}>
                                <button className="phylumButton" onClick={() => handleNavigation('/GNreach')} style={{ paddingRight: '10px', background: 'none', color: 'white', borderRight: '1px solid white' }}>
                                    Overview
                                </button>
                                <button className="phylumButton" onClick={() => handleNavigation('/GNaudience')} style={{ marginLeft: '0px', background: 'none', color: 'white' }}>
                                    Itemized
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="phylum" >
                        <div className="columnPlain">
                            <div className="h1bsk" style={{ marginBottom: '6px' }}>
                                Campaigns
                            </div>
                            {/* <div className="rowCenter" style={{ width: '205px' }}>
                                <button className="phylumButton" onClick={() => handleNavigation('/GNMembers')} style={{ paddingRight: '10px', background: 'none', color: 'white', borderRight: '1px solid white' }}>
                                    Reach
                                </button>
                                <button className="phylumButton" onClick={() => handleNavigation('/GNaudience')} style={{ marginLeft: '0px', background: 'none', color: 'white' }}>
                                    Sales
                                </button>
                            </div> */}
                        </div>
                    </div>

                    <div className="phylum" >
                        <div className="columnPlain">
                            <div className="h1bsk" style={{ marginBottom: '6px' }}>
                                CTV
                            </div>
                            <div className="rowCenter" style={{ width: '205px' }}>
                                <button className="phylumButton" onClick={() => handleNavigation('/GNMembers')} style={{ paddingRight: '10px', background: 'none', color: 'white', borderRight: '1px solid white' }}>
                                    Vizio
                                </button>
                                <button className="phylumButton" onClick={() => handleNavigation('/GNaudience')} style={{ marginLeft: '0px', background: 'none', color: 'white' }}>
                                    Other
                                </button>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
}

export default Attic
