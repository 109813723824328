import React from 'react';
import { useEffect, useState } from "react"

//aesthetics
import { tokens } from "../../theme"
import { useTheme } from '@mui/material';
import HexToRgba from '../../components/visuals/design/hexToRgba';
import '../visuals/design/skSpecial.css'


const SnHighlights = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [ascotUnits, setAscotUnits] = useState(0)
    const [ascotRev, setAscotRev] = useState(0)
    const [topAscot, setTopAscot] = useState([])

    const [engravedUnits, setEngravedUnits] = useState(0)
    const [engravedRev, setEngravedRev] = useState(0)
    const [topEngraved, setTopEngraved] = useState([])

    const [topMonthly, setTopMonthly] = useState([])
    const [topMonthlyNE, setTopMonthlyNE] = useState([])

    const [totalUnits, setTotalUnits] = useState(0);
    const [totalSales, setTotalSales] = useState("");

    useEffect(() => {
        const fetchAscotUnits = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/usbc/ascotunits`);
            const data = await response.json();

            if (response.ok) {
                setAscotUnits(data)
            }
        }
        const fetchAscotRev = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/usbc/ascotrev`);
            const data = await response.json();

            if (response.ok) {
                setAscotRev(data);
            }
        };

        const fetchTopAscot = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/usbc/ascot24top`);
            const data = await response.json();

            if (response.ok) {
                setTopAscot(data[0].topProducts);
            }
        };

        const fetchEngravedUnits = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/usbc/engraved24units`);
            const data = await response.json();

            if (response.ok) {
                setEngravedUnits(data);
            }
        };
        const fetchEngravedRev = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/usbc/engraved24rev`);
            const data = await response.json();

            if (response.ok) {
                setEngravedRev(data);
            }
        };

        const fetchTopEngraved = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/usbc/engraved24top`);
            const data = await response.json();

            if (response.ok) {
                setTopEngraved(data[0].topProducts);
            }
        };

        const fetchTopMonthly = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/usbc/monthly`);
            const data = await response.json()

            if (response.ok) {
                setTopMonthly(data.topProducts);
                setTotalUnits(data.totalUnits);
                setTotalSales(data.totalSales);
            }
        }

        const fetchTopMonthlyNE = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/usbc/monthlyne`);
            const data = await response.json();

            if (response.ok) {
                setTopMonthlyNE(data.topProducts);
            }
        };

        fetchAscotUnits()
        fetchAscotRev();
        fetchTopAscot();

        fetchEngravedUnits()
        fetchEngravedRev();
        fetchTopEngraved();

        fetchTopMonthly();
        fetchTopMonthlyNE()
    }, [])

    const formattedAscotRev = ascotRev.toLocaleString()
    const formattedEngravedRev = engravedRev.toLocaleString()


    return (

        <div className="centerDiv">
            <div className="h1sk" style={{ color: colors.SKcustom[10], marginBottom: '10px' }}>2024 Highlights</div>

            {/* Best In Past 30 days */}
            <div style={{ border: `1px solid ${HexToRgba(colors.SKcustom[10], 0.5)}`, padding: '5px', marginBottom: '10px', width: '800px' }}>
                <div className="h1sk" style={{ color: colors.SKcustom[10], textAlign: 'left', marginBottom: '10px' }}>Top Sellers</div>

                <div className="rowPlain">

                    <div className="columnPlain" style={{ width: '500px' }}>
                        <div className="topHeader2">ALL BOTTLES</div>
                        <div style={{ color: colors.SKcustom[10] }}>
                            <ol style={{ paddingLeft: 20, }}>
                                {topMonthly.map((item, index) => (
                                    <li key={index}>
                                        <span style={{ fontSize: '0.9em', fontWeight: 600, color: colors.grey[22], marginRight: '5px' }}>
                                            {item.product}
                                        </span> •
                                        <span style={{ fontSize: '0.8em', fontWeight: 400, color: colors.SKcustom[33], marginLeft: '5px' }}>
                                            {`${item.totalUnits}`} units
                                        </span>
                                    </li>
                                ))}
                            </ol>
                        </div>
                    </div>

                    <div className="columnPlain2">
                        <div className="topHeader2">NOT INCLUDING ENGRAVED</div>
                        <div style={{ color: colors.SKcustom[10] }}>
                            <ol style={{ paddingLeft: 20, }}>
                                {topMonthlyNE.map((item, index) => (
                                    <li key={index}>
                                        <span style={{ fontSize: '0.9em', fontWeight: 600, color: colors.grey[22], marginRight: '5px' }}>
                                            {item.product}
                                        </span> •
                                        <span style={{ fontSize: '0.8em', fontWeight: 400, color: colors.SKcustom[33], marginLeft: '5px' }}>
                                            {`${item.totalUnits}`} units
                                        </span>
                                    </li>
                                ))}
                            </ol>
                        </div>
                    </div>
                </div>

            </div>


            <div className="rowPlain">



                {/* Engraved */}
                <div className="columnBox" style={{ borderColor: HexToRgba(colors.SKcustom[10], 0.5) }}>
                    <div className="h1sk" style={{ color: colors.SKcustom[10] }}>Engraved Bottles</div>
                    <div className="rowStats">
                        <div className="h2sk" style={{ color: colors.SKcustom[10] }}>${formattedEngravedRev}</div>
                        <div style={{ backgroundColor: colors.SKcustom[10], width: '1px', margin: '2px 5px 2px 5px' }}></div>
                        <div className="h2sk" style={{ color: colors.SKcustom[10] }}>{engravedUnits} Sold</div>
                    </div>
                    <div className="topHeader">Top Sellers</div>
                    <div style={{ color: colors.SKcustom[10] }}>
                        <ol style={{ paddingLeft: 10 }}>
                            {topEngraved.map((item, index) => (
                                <li key={index}>

                                    <span style={{ fontSize: '0.9em', fontWeight: 600, color: colors.grey[22], marginRight: "" }}>
                                        {item.product}
                                    </span> •

                                    <span style={{ fontSize: '0.8em', fontWeight: 400, color: colors.SKcustom[33], marginRight: "" }}>
                                        {` ${item.totalUnits}`} units
                                    </span>

                                    {/* <span style={{ fontSize: '0.8em' }}>
                                        {` ${item.totalSales}`}</span> */}

                                </li>
                            ))}
                        </ol>
                    </div>
                </div>

                {/* Ascot */}
                <div className="columnBox" style={{ borderColor: HexToRgba(colors.SKcustom[10], 0.5) }}>
                    <div className="h1sk" style={{ color: colors.SKcustom[10] }}>Ascot Awards</div>
                    <div className="rowStats">
                        <div className="h2sk" style={{ color: colors.SKcustom[10] }}>${formattedAscotRev}</div>
                        <div style={{ backgroundColor: colors.SKcustom[10], width: '1px', margin: '2px 5px 2px 5px' }}></div>
                        <div className="h2sk" style={{ color: colors.SKcustom[10] }}>{ascotUnits} Sold</div>
                    </div>

                    {/* topsellers */}
                    <div className="topHeader">Top Sellers</div>

                    <div style={{ color: colors.SKcustom[10] }}>
                        <ol style={{ paddingLeft: 10 }}>
                            {topAscot.map((item, index) => (
                                <li key={index}>

                                    <span style={{ fontSize: '0.8em', fontWeight: 600, color: colors.grey[22], marginRight: "" }}>
                                        {item.product}
                                    </span> •

                                    <span style={{ fontSize: '0.8em', fontWeight: 400, color: colors.SKcustom[33], marginRight: "" }}>
                                        {` ${item.totalUnits}`} units
                                    </span>

                                    {/* <span style={{ fontSize: '0.8em' }}>
                                        {` ${item.totalSales}`}</span> */}

                                </li>
                            ))}
                        </ol>
                    </div>
                </div>

            </div>
        </div>


    )
}

export default SnHighlights

