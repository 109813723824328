import { useEffect, useState, useRef } from 'react';
import { tokens } from "../theme";
import { useTheme } from '@mui/material';
import hexToRgba from '../components/visuals/design/hexToRgba';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom for navigation
import '../components/visuals/design/treasure.css';
import Sound from "../img/adventure_01.mp3";

const Treasure1 = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [isVisible, setIsVisible] = useState(false);
    const [showButton, setShowButton] = useState(false); // State to control button visibility
    const audioRef = useRef(null);

    const startAnimation = () => {
        setIsVisible(true);
        if (audioRef.current) {
            audioRef.current.play(); // Play sound when the user clicks to start animation
        }

        // Show button after animation completes (e.g., 3 seconds)
        setTimeout(() => {
            setShowButton(true);
        }, 1000); // Adjust the delay to match the animation duration
    };

    return (
        <div className={`container ${isVisible ? 'animate' : ''}`} onClick={startAnimation} style={{ cursor: isVisible ? 'default' : 'pointer' }}>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                <h1 className="animated-text" style={{ color: hexToRgba('#66FF00', 0.9), fontSize: '40px' }}>
                    Hello TJ, Tyler & Brooklyn
                </h1>

                {/* Audio element */}
                <audio ref={audioRef}> <source src={Sound} /></audio>

                {!isVisible && (<p className="click-text" style={{ color: hexToRgba(colors.SKcustom[50], 0.6) }}></p>)}


                {showButton && (
                    <Link to="/treasure2">
                        <button
                            className="next-button"
                            style={{ padding: '10px 20px', fontSize: '18px', cursor: 'pointer', }}>
                            CLUE 1
                        </button>
                    </Link>
                )}
            </div>
        </div>
    );
};

export default Treasure1;
