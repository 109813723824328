import { useEffect, useState, useCallback } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../visuals/calendar/customCalendarStyles.scss';
import '../visuals/design/calendar.css'
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";

import Popup from 'reactjs-popup';

import moment from 'moment'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';

import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';

const localizer = momentLocalizer(moment)
const DnDCalendar = withDragAndDrop(Calendar);

//execute
function ShanCalendar() {

    //aesthetics
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [open, setOpen] = useState(false);
    const [eventDetails, setEventDetails] = useState(null);
    const [showEventPopup, setShowEventPopup] = useState(false);

    //populate events from database
    const [events, setEvents] = useState([]);
    useEffect(() => {
        const fetchEvents = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/calendar`);
            if (!response.ok) {
                throw new Error(`calendar error ${response.status}`);
            }
            const data = await response.json();

            // Convert the start and end times of each event to Date objects
            const convertedEvents = data.map(event => ({
                ...event,
                start: new Date(event.start),
                end: new Date(event.end)
            }));

            setEvents(convertedEvents);
        };

        fetchEvents();
    }, []);

    //add new event
    const [newEvent, setNewEvent] = useState({ title: '', start: null, end: null, channel: [] });
    const handleSelectSlot = async ({ start, end }) => {

        setNewEvent({ ...newEvent, start: start, end: end, channel: '', notes: '' });
        setOpen(true);
    };

    const eventPropGetter = (event) => {
        let newStyle = {};
        if (event.channel === 'golf') {
            newStyle.backgroundColor = colors.blueAccent[50];
        } else if (event.channel === 'spirits') {
            newStyle.backgroundColor = '#ab967d';
        } else if (event.channel === 'pantry') {
            newStyle.backgroundColor = '#C0C0C0'
            newStyle.color = '#000';
        }

        return {
            style: newStyle
        };
    };

    const handleChannelSelect = (channel) => {
        setNewEvent({ ...newEvent, channel });
    };

    const handleNotesChange = (e) => {
        setNewEvent({ ...newEvent, notes: e.target.value });
    };

    //event popup
    const getPopupBackgroundColor = (channel) => {
        switch (channel) {
            case 'golf':
                return '#1c3ba2';
            case 'spirits':
                return '#ab967d';
            case 'pantry':
                return '#C0C0C0';
            default:
                return '#fff';
        }
    };

    //submit event
    const [isEditing, setIsEditing] = useState(false);

    const handleSubmit = async () => {
        const { title, start, end, channel, notes } = newEvent;

        console.log(eventDetails)

        let response;

        if (isEditing)//toggles boolean to true  
        {
            // Update existing event
            response = await fetch(`${process.env.REACT_APP_SERVER_URL}/calendar/${eventDetails._id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ title, start, end, channel, notes }),
            });
        } else {
            // Add new event
            response = await fetch(`${process.env.REACT_APP_SERVER_URL}/calendar`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ title, start, end, channel, notes }),
            });
        }

        if (response.ok) {
            const updatedOrNewEvent = await response.json();
            if (isEditing) {
                setEvents(events.map(event => event._id === eventDetails._id ? updatedOrNewEvent : event));
            } else {
                setEvents([...events, updatedOrNewEvent]);
            }
            setOpen(false); // Close form popup
        } else {
            console.error("Failed to save the event");
        }

        // Reset states
        setIsEditing(false);
        setNewEvent({ title: '', start: null, end: null, channel: '', notes: '' });
    };


    //view event details
    const handleSelectEvent = useCallback(
        (event) => {
            setEventDetails(event);
            setShowEventPopup(true);

        },
        []
    )

    //not sure
    const activeStyle = {
        backgroundColor: '#00B7EB', // Or any highlight color
        color: 'white'
    };

    //event drop
    const onEventDrop = async ({ event, start, end }) => {
        // Construct the data you want to update
        const updatedEventData = {
            title: event.title, // Assuming you have the title in the event object
            start: start,
            end: end,
            notes: event.notes, // Assuming you have notes in the event object
            channel: event.channel, // Assuming you have channel in the event object
        };

        try {
            // Replace `YOUR_API_ENDPOINT` with the actual endpoint where you handle updates
            // e.g., http://localhost:5000/api/events/{event.id}
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/calendar/${event._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedEventData),
            });

            if (!response.ok) throw new Error('Network response was not ok.');

            // Handle the response. For example, you might want to update the state to re-render the component
            console.log('Event updated successfully');

            //Update the event that user dragged and dropped in events array/state
            const updatedEvents = events.map(originalEvent => {

                if (originalEvent._id === event._id) {

                    return { ...originalEvent, ...updatedEventData }
                }
                else {
                    return { ...originalEvent }
                }

            })

            console.log(updatedEvents)

            //tells react to re-render the events
            setEvents(updatedEvents)

        } catch (error) {
            console.error('Failed to update the event:', error);
        }
    };

    const onEventResize = async ({ event, start, end }) => {
        // Prepare the updated event object with new start and end times
        const updatedEvent = { ...event, start, end };

        // Update the event in the backend
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/calendar/${event._id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(updatedEvent),
        });

        if (response.ok) {
            // If the backend update is successful, update the event in the frontend state
            setEvents(events.map(evt => evt._id === event._id ? { ...evt, start, end } : evt));
        } else {
            // Handle any errors
            console.error('Failed to update the event.');
        }
    };

    return (
        <div style={{ marginRight: "0px" }}>
            <DnDCalendar
                events={events}
                views={{ month: true }}
                localizer={localizer}
                defaultView="month"
                onEventDrop={onEventDrop}
                resizable
                onEventResize={onEventResize}
                eventPropGetter={eventPropGetter}

                startAccessor="start"
                endAccessor="end"

                style={{ height: 800 }}
                selectable
                onSelectEvent={handleSelectEvent}
                onSelectSlot={handleSelectSlot}
                className="calendar-custom-style"
            />

            {/* new event */}
            <Popup style={{ width: "400px" }} open={open} closeOnDocumentClick onClose={() => setOpen(false)}>
                <form
                    className="popup-content2"
                    style={{ backgroundColor: "#c2c2c2" }}
                    onSubmit={(e) => {
                        e.preventDefault(); // Prevents the default form submit action
                        handleSubmit();
                    }}>
                    <input
                        style={{ width: "200px", marginBottom: "5px" }}
                        type="text"
                        value={newEvent.title}
                        onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
                        placeholder="Enter Event Title"
                    />
                    <input
                        style={{ height: "200px", marginBottom: "5px" }}
                        type="text"
                        value={newEvent.notes}
                        onChange={handleNotesChange}
                        placeholder="Notes"
                    />

                    <div>
                        {['spirits', 'golf', 'pantry'].map((channel) => (
                            <button
                                key={channel}
                                type="button" // This is important to prevent form submission
                                style={newEvent.channel === channel ? activeStyle : {}}
                                onClick={() => handleChannelSelect(channel)}
                            >
                                {channel.charAt(0).toUpperCase() + channel.slice(1)}
                            </button>
                        ))}
                    </div>

                    <div><p style={{ color: "#000" }}>To change the date, just drag and drop in calendar.</p></div>

                    <div className="cRow">
                        <button className="calButton" style={{ color: '#1F2A40', outline: "1px solid", width: "50px", padding: "5px", backgroundColor: colors.SKcustom[16] }} type="submit">Add</button>

                        <button onClick={async () => {
                            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/calendar/${eventDetails._id}`, {
                                method: 'DELETE',
                            });
                            if (response.ok) {
                                setEvents(events.filter(event => event._id !== eventDetails._id)); // Remove the event from the state
                                setShowEventPopup(false); // Close the popup
                            } else {
                                console.error("Failed to delete the event");
                            }
                        }}
                            className="calButton">Delete
                        </button>
                    </div>


                </form>
            </Popup>

            {/* existing event */}

            <Popup
                open={showEventPopup}
                closeOnDocumentClick onClose={() => setShowEventPopup(false)}
                contentStyle={{
                    width: "250px",
                    backgroundColor: eventDetails ? getPopupBackgroundColor(eventDetails.channel) : 'defaultColor'
                }} >
                <div>
                    {eventDetails && (
                        <div style={{ padding: "20px", backgroundColor: "#fff", margin: "15px" }}>
                            <h3 className="sh4" style={{ color: "#000" }}>{eventDetails.title}</h3>
                            <p className="sh6">{eventDetails.notes}</p>

                        </div>
                    )}
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        {/* Edit button */}
                        <button
                            style={{ border: "none", borderRadius: "20px", fontSize: "12px" }}
                            onClick={() => {
                                setNewEvent(eventDetails); // Populate form with event details
                                setIsEditing(true); // Set editing state to true
                                setOpen(true); // Open the form popup for editing
                                setShowEventPopup(false); // Close the current popup
                            }}
                        > <EditNoteOutlinedIcon /></button>
                    </div>
                </div>
            </Popup>

        </div >
    )
}

export default ShanCalendar