import React, { useEffect, useState } from 'react';
import { useTheme } from "@mui/material";
import { Chart } from 'react-google-charts';

import { tokens } from "../../theme";
import "../../components/visuals/design/skSpecial.css";
import hexToRgba from '../visuals/design/hexToRgba';

const VizioDemoSN = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [ageData, setAgeData] = useState([
        ['Segment', 'Percent', { role: 'style' }, { role: 'annotation' }]
    ]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const ageResponse = await fetch(`${process.env.REACT_APP_SERVER_URL}/viziodemo/age`);
                if (ageResponse.ok) {
                    const ageFetchedData = await ageResponse.json();

                    // Extract 'Segment' and 'Percent' for age data
                    const ageChartData = [['Segment', 'Percent', { role: 'style' }, { role: 'annotation' }]];
                    ageFetchedData.forEach((item, index) => {
                        const barColor = '#ab966d'; // Set your desired color
                        ageChartData.push([item.segment, item.percent, barColor, `${item.percent}%`]);
                    });
                    setAgeData(ageChartData);
                } else {
                    console.error('Failed to fetch age data');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const optionsAge = {
        chartArea: { width: '100%', top: 30 },
        title: "",
        titleTextStyle: {
            color: colors.SKcustom[10],
            fontSize: 16,
            bold: true,
        },
        hAxis: {
            minValue: 0,
            gridlines: { color: 'transparent' },
            ticks: [],
            textStyle: {
                fontSize: 12,
                color: '#ab966d',
            },
        },
        vAxis: {
            minValue: 0,
            textPosition: 'in',
            gridlines: { color: 'transparent' },
            baselineColor: 'transparent',
            ticks: [],
            textStyle: {
                color: '#ab966d'
            },
        },
        legend: { position: 'none' },
        backgroundColor: 'transparent',
        bars: 'vertical',
        bar: { groupWidth: '60%' },
        colors: ['#ab966d'],
        annotations: {
            alwaysOutside: true,
            textStyle: {
                fontSize: 12,
                fontWeight: 700,
                color: '#ab966d',
            },
            stem: { color: 'transparent', length: 10 },
            enableInteractivity: false,
        },
    };

    return (
        <div style={{ width: '300px' }}>
            <Chart
                chartType="ColumnChart"
                width="100%"
                height="100%"
                data={ageData}
                options={optionsAge}
            />
        </div>
    );
};

export default VizioDemoSN;