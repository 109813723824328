import { React, useState } from 'react';
import { tokens } from '../theme';
import { useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
// import { Box, TextField } from "@mui/material";

//img
import TimelineImage from "../img/pitchSteps.png"

//components
import Hats from '../components/Resources/Hats'

//execution
const BDTraining = () => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  function hexToRgba(hex, opacity) {
    hex = hex.replace('#', '');
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
  }

  //carousel 
  const [currentSlide, setCurrentSlide] = useState(0);

  const videos = [
    'https://player.vimeo.com/video/841646657?h=dae78d676f',
    'https://player.vimeo.com/video/841647508?h=801bad18cf',
  ];

  const handleNextSlide = () => {
    setCurrentSlide((currentSlide + 1) % videos.length);
  };

  const handlePrevSlide = () => {
    setCurrentSlide((currentSlide - 1 + videos.length) % videos.length);
  };

  return (
    <div style={{ marginLeft: "1em", }} >

      {/* BD */}
      < h2 style={{ marginBottom: "0.5em", color: hexToRgba(colors.SKcustom[20], 0.9), fontSize: "1.3em" }}> Lessons in Pitching </h2 >

      {/* carousel */}
      < div style={{ maxWidth: '600px', height: '400px', position: 'relative', overflow: 'hidden', marginBottom: "-150px" }}>

        <div style={{ display: 'flex', transform: `translateX(-${currentSlide * 100}%)`, transition: 'transform 0.5s' }}>

          <div style={{ flex: '0 0 300px', marginRight: '10px' }}>

            <Link to="/BD"><img src={TimelineImage} alt="Image 3" style={{ width: '300px', height: '180px' }} /> </Link>

          </div>
          {videos.map((video, index) => (
            <div key={index} style={{ flex: '0 0 300px', marginRight: '10px' }}>
              <iframe
                src={video}
                width="300"
                height="180"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                title={`Video ${index + 1}`}
              ></iframe>
            </div>
          ))}

        </div>

        <button className="sliderButton" onClick={handlePrevSlide} style={{ position: 'absolute', left: 0 }}>
          {'<'} Previous
        </button>
        <button className="sliderButton" onClick={handleNextSlide} style={{ position: 'absolute', right: 0 }}>
          Next {'>'}
        </button>

      </div >

      <Hats style={{ marginTop: "20em" }} />

    </div >
  );
};

export default BDTraining

