import React from "react";
import { useEffect, useState } from "react";

const SNorg23 = () => {

    const [org23, setOrg23] = useState([]);

    useEffect(() => {
        const fetchOrg23 = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/shows/org23`);
            const data = await response.json();

            setOrg23(data)

        };
        fetchOrg23()
    })

    return (
        <div>
            {org23}
        </div>
    )

};

export default SNorg23