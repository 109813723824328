import { useEffect, useState, useMemo } from "react"
import axios from "axios";
import '../../components/visuals/design/videoMetrics.css';
import '../../components/visuals/design/sk.css';
import GoogleAds from "./GoogleAds";

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const SaratogaMetrics = () => {

    const [ytSaratoga, setYtSaratoga] = useState(0);
    const [ytSeries, setYtSeries] = useState(0);
    const [googleAdsAll, setGoogleAdsAll] = useState(0);
    const [googleAdsAll2, setGoogleAdsAll2] = useState(0);
    const [googleclicks, setGoogleClicks] = useState(0);
    const [googleSaratogaClicks, setGoogleSaratogaClicks] = useState(0)
    //FB
    const [fb, setFb] = useState(0);
    const [fbClicks, setFbClicks] = useState(0);
    const [fbViews, setFbViews] = useState(0);
    const [fbCompletions, setFbCompletions] = useState(0);
    //IG
    const [instagramData, setInstagramData] = useState([])
    //email 
    const [emailData, setEmailData] = useState([])


    useEffect(() => {
        //Saratoga YouTube
        const fetchTotal = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantryyoutube/saratoga`);
                const data = await response.json();
                if (response.ok) {
                    setYtSaratoga(Number(data));
                }
            } catch (error) {
                console.error('Error fetching YouTube data:', error);
            }
        };

        //Series YouTube
        const fetchYtSeries = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantryyoutube`);
                const data = await response.json();
                if (response.ok) {
                    setYtSeries(Number(data));
                }
            } catch (error) {
                console.error('Error fetching YouTube data:', error);
            }
        };

        //Saratoga Paid GoogleAds
        const fetchGoogleAdsAll = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/googleads/saratogatotal`);
                const data = await response.json();
                if (response.ok) {
                    setGoogleAdsAll(Number(data));
                }
            } catch (error) {
                console.error('Error fetching Google Ads data:', error);
            }
        };

        //Saratoga Paid Google Clicks
        const fetchGoogleSaratogaClicks = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/googleads/saratogaclicks`);
                const data = await response.json();
                if (response.ok) {
                    setGoogleSaratogaClicks(Number(data));
                }
            } catch (error) {
                console.error('Error fetching Google Ads data:', error);
            }
        };

        //All GoogleAds
        const fetchGoogleAdsAll2 = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/googleads/total`);
                const data = await response.json();
                if (response.ok) {
                    setGoogleAdsAll2(Number(data));
                }
            } catch (error) {
                console.error('Error fetching Google Ads data:', error);
            }
        };

        //All GoogleAds Clicks
        const fetchGoogleClicks = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/googleads/totalclicks`);
                const data = await response.json();
                if (response.ok) {
                    setGoogleClicks(Number(data));
                }
            } catch (error) {
                console.error('Error fetching Google Ads data:', error);
            }
        };

        //All Paid Facebook
        const fetchFb = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantryfb/total`);
                const data = await response.json();
                if (response.ok) {
                    setFb(Number(data));
                }
            } catch (error) {
                console.error('Error fetching Google Ads data:', error);
            }
        };

        const fetchFbClicks = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantryfb/totalclicks`);
                const data = await response.json();
                if (response.ok) {
                    setFbClicks(Number(data));
                }
            } catch (error) {
                console.error('Error fetching Google Ads data:', error);
            }
        };

        const fetchFbViews = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantryfb/totalviews`);
                const data = await response.json();
                if (response.ok) {
                    setFbViews(Number(data));
                }
            } catch (error) {
                console.error('Error fetching Google Ads data:', error);
            }
        };

        const fetchFbCompletions = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantryfb/totalcompletions`);
                const data = await response.json();
                if (response.ok) {
                    setFbCompletions(Number(data));
                }
            } catch (error) {
                console.error('Error fetching Google Ads data:', error);
            }
        };

        //IG
        const fetchIG = async () => {

            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/meta/total`);
            const data = await response.json();

            if (response.ok) {
                setInstagramData(Number(data));
            }
        }

        //emails
        const fetchEmailData = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantryemails/total`)
            const data = await response.json()
            if (response.ok) {
                setEmailData(Number(data))
            }

        }



        fetchTotal();
        fetchYtSeries();
        fetchGoogleAdsAll();
        fetchGoogleAdsAll2();
        fetchGoogleClicks();
        fetchGoogleSaratogaClicks();
        fetchFb();
        fetchFbViews();
        fetchFbClicks();
        fetchFbCompletions();
        fetchIG();
        fetchEmailData()
    }, []);


    const email12 = 543533

    const googleAdsL = googleAdsAll2 - googleAdsAll
    const googleAdsRestClicks = googleclicks - googleSaratogaClicks

    const ytSaratogaCompletes = ytSaratoga * 0.98
    const ytSeriesCompletes = ytSeries * 0.98

    const formattedYtSaratogaCompletes = ytSaratogaCompletes.toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });

    const formattedYtSeriesCompletes = ytSeriesCompletes.toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });


    const ImpTotal = 70 + googleAdsAll + fb + 114825 + 114825 + 114825 + 114825 + 114825 + 8051 + 8051 + googleAdsL;
    const ViewTotal = 403689 + ytSaratoga + fbViews + instagramData + 96449 + 482297 + ytSeries;
    const CompleteTotal = 399652 + ytSaratogaCompletes + ytSeriesCompletes + fbCompletions;
    const ClickTotal = googleSaratogaClicks + fbClicks + 12449 + googleAdsRestClicks;
    const SendsTotal = 0;
    const OpensTotal = email12;



    const tableData = [
        ["Product Featured in All Episodes", "P2KMKXX", "n/a", "403,689", "399,652", "", "", ""],
        ["Saratoga Custom Hub on NBTV", "P2L2NCL", "70", "n/a", "n/a", "", "", ""],
        ["60 Second Trailer Driving to Episode Page ", "P2L2KR4", googleAdsAll.toLocaleString(), ytSaratoga.toLocaleString(), formattedYtSaratogaCompletes, googleSaratogaClicks.toLocaleString(), "", ""],
        ["2 Meta Posts - 1440 x 1440", "P2L2KSM", fb.toLocaleString(), fbViews.toLocaleString(), fbCompletions.toLocaleString(), fbClicks.toLocaleString(), "", ""],
        ["2 Meta Stories - 1080 x 1920", "P2L2KSW", "", instagramData.toLocaleString(), "n/a", "", "", ""],
        ["Vizio WatchFree+ Full Episode", "P2L2KT1", "", "96,449", "n/a", "", "", ""],
        ["Vizio WatchFree+ 30 Second Interstitial", "P2L2KT2", "", "482,297", "n/a", "", "", ""],
        ["Featured Carousel Placement - 1920 x 1080", "P2L2LNP", "114,825", "", "", "", "", ""],
        ["Mid Page Banners Desktop - 1920 x 640", "P2L2LNQ", "114,825", "", "", "", "", ""],
        ["Mid Page Banners Mobile - 1280 x 1600", "P2L2M2P", "114,825", "", "", "", "", ""],
        ["Mega Nav Menu Feature - 1770 x 1080", "P2L2M2T", "114,825", "", "", "", "", ""],
        ["Episode Landing Page - 1920 x 1080", "P2L2M3Z", "114,825", "", "", "", "", ""],
        ["2x Dedicated Emails Per Episode (12 Total)", "P2L2M4Q", "n/a", "", "", "12,449", "993,708", email12.toLocaleString()],
        ["AV Spirits Network Platform - Web, Mobile, AppleTV", "P2L2M75", "8,051", "", "", "", "", ""],
        ["AV Spirits Network FAST - Vizio", "P2L2M76", "8,051", "", "", "", "", ""],
        ["AV Spirits Network YouTube Channel", "P2L2M78", googleAdsL.toLocaleString(), ytSeries.toLocaleString(), formattedYtSeriesCompletes.toLocaleString(), googleAdsRestClicks.toLocaleString(), "", ""],
        ["Total", "", ImpTotal.toLocaleString(), ViewTotal.toLocaleString(), CompleteTotal.toLocaleString('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }), ClickTotal.toLocaleString(), "993,708", OpensTotal.toLocaleString()]
    ];

    const downloadCSV = () => {
        const headers = ['Placement', 'ID', 'Impressions', 'Video Views', 'Video Completes', 'Clicks', 'Email Sends', 'Email Opens']
        const rows = tableData.map(row =>
            Object.values(row).map(value => `"${value}"`).join(',')
        ).join('\n');
        const csvContent = `data:text/csv;charset=utf-8,${headers}\n${rows}`;
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'pool_data.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="gradientNeon" style={{ padding: "5px", height: '400px', display: 'flex', flexDirection: 'column' }}>

            <button onClick={downloadCSV} style={{ width: '20px', fontSize: '8px', alignSelf: 'flex-start', marginLeft: '60px', marginBottom: '5px' }}><FileDownloadOutlinedIcon style={{ height: '14px', marginLeft: '-10px' }} /></button>

            <table style={{ width: "90%", borderCollapse: "collapse", marginLeft: "60px", fontSize: "8px", fontWeight: 400 }}>

                <thead style={{}}>
                    <tr>
                        <th className="saratogaTH" style={{ border: "1px solid black", padding: "3px", width: "15%" }}>Placement</th>
                        <th className="saratogaTH" style={{ border: "1px solid black", padding: "3px", width: "2%" }}>ID</th>
                        <th className="saratogaTH" style={{ border: "1px solid black", padding: "3px", width: "5%" }}>Impressions</th>
                        <th className="saratogaTH" style={{ border: "1px solid black", padding: "3px", width: "5%" }}>Video Views</th>
                        <th className="saratogaTH" style={{ border: "1px solid black", padding: "3px", width: "5%" }}>Video Completes</th>
                        <th className="saratogaTH" style={{ border: "1px solid black", padding: "3px", width: "5%" }}>Clicks</th>
                        <th className="saratogaTH" style={{ border: "1px solid black", padding: "3px", width: "5%" }}>Email Sends</th>
                        <th className="saratogaTH" style={{ border: "1px solid black", padding: "3px", width: "5%" }}>Email Opens</th>
                    </tr>
                </thead>

                <tbody>
                    {tableData.map((row, index) => (
                        <tr key={index} style={index === tableData.length - 1 ? { fontWeight: 800 } : {}}>
                            {row.map((cell, cellIndex) => (
                                <td key={cellIndex} className="saratogaTR" style={{ border: "1px solid black", padding: "3px" }}>{cell}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>

            </table>
        </div>

    );
};

export default SaratogaMetrics;
