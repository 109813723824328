import React, { useState, useEffect, useRef } from "react";
import '../components/visuals/design/inVideo.css'
import { tokens } from "../theme";
import { useTheme } from "@mui/material";

import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import PauseOutlinedIcon from '@mui/icons-material/PauseOutlined';
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';



import footer1 from "../img/LG.png"
import footer2 from "../img/insiderIntelligence.png"
import footer3 from "../img/SN.png"
import footer4 from "../img/roku.png"
import footer5 from "../img/nbcu.png"
import footer6 from "../img/amazon.png"
import footer7 from "../img/disney.png"

const ShoppableTV = () => {

    //aesthetics
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    function hexToRgba(hex, opacity) {
        hex = hex.replace('#', '');
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
    }

    //video player
    const videoRef = useRef(null);

    const handlePlayClick = () => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    };

    const handlePauseClick = () => {
        if (videoRef.current) {
            videoRef.current.pause();
        }
    };

    const handleVolumeChange = () => {
        if (videoRef.current) {
            // Toggle volume between off (0) and medium (0.5)
            videoRef.current.volume = videoRef.current.volume > 0 ? 0 : 0.5;
            // Optionally toggle muted state as well
            videoRef.current.muted = videoRef.current.volume === 0;
        }
    };

    const handleProgressChange = (event) => {
        const time = event.target.value;
        if (videoRef.current) {
            videoRef.current.currentTime = (videoRef.current.duration / 100) * time;
        }
    };

    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);

    // Update current time and duration as video plays
    useEffect(() => {
        const video = videoRef.current;
        if (!video) return;

        const handleLoadedMetadata = () => {
            setDuration(video.duration);
        };

        const handleTimeUpdate = () => {
            setCurrentTime((video.currentTime / video.duration) * 100);
        };

        video.addEventListener('loadedmetadata', handleLoadedMetadata);
        video.addEventListener('timeupdate', handleTimeUpdate);

        return () => {
            video.removeEventListener('loadedmetadata', handleLoadedMetadata);
            video.removeEventListener('timeupdate', handleTimeUpdate);
        };
    }, [videoRef]);

    //states
    const [showFooter1, setShowFooter1] = useState(false);
    const [showFooter2, setShowFooter2] = useState(false);
    const [showFooter3, setShowFooter3] = useState(false);
    const [showFooter4, setShowFooter4] = useState(false);
    const [showFooter5, setShowFooter5] = useState(false);
    const [showFooter6, setShowFooter6] = useState(false);
    const [showFooter7, setShowFooter7] = useState(false);

    //Full Screen actions  
    const toggleFullScreen = async () => {
        const container = document.getElementById('video-container');
        const fullscreenApi = container.requestFullscreen
            || container.webkitRequestFullScreen
            || container.mozRequestFullScreen
            || container.msRequestFullscreen;
        if (!document.fullscreenElement) {
            fullscreenApi.call(container);
        }
        else {
            document.exitFullscreen();
        }
    };

    //cue points
    useEffect(() => {
        const video = videoRef.current;

        const handleTimeUpdate = () => {
            if (video.currentTime >= 12 && video.currentTime < 21) {
                setShowFooter1(true);
            } else {
                setShowFooter1(false);
            }
            if (video.currentTime >= 23 && video.currentTime < 29) {
                setShowFooter2(true);
            } else {
                setShowFooter2(false);
            }
            if (video.currentTime >= 31) {
                setShowFooter3(true);
            } else {
                setShowFooter3(false);
            }
            if (video.currentTime >= 31) {
                setShowFooter4(true);
            } else {
                setShowFooter4(false);
            }
            if (video.currentTime >= 31) {
                setShowFooter5(true);
            } else {
                setShowFooter5(false);
            }
            if (video.currentTime >= 31) {
                setShowFooter6(true);
            } else {
                setShowFooter6(false);
            }
            if (video.currentTime >= 31) {
                setShowFooter7(true);
            } else {
                setShowFooter7(false);
            }
        };

        video.addEventListener('timeupdate', handleTimeUpdate);

        return () => {
            video.removeEventListener('timeupdate', handleTimeUpdate);
        };
    }, []);

    //buy bar actions
    const footer1Click = () => {
        window.open('https://drive.google.com/file/d/1lBgsj_jG4TAd3FpMwiHDrKmwkljtq6Kd/view?usp=sharing', '_blank');
    };
    const footer2Click = () => {
        window.open('https://drive.google.com/file/d/1S6oYHef7iXamOm0TkSrt33qtoeGAdPNZ/view?usp=sharing', '_blank');
    };
    const footer3Click = () => {
        window.open('https://drive.google.com/file/d/1ryTo4SoiJOXi8VRsblJS_rCnIFoV7Z1U/view?usp=sharing')
    }
    const footer4Click = () => {
        window.open('https://drive.google.com/file/d/1QdB8I_uXXv7_XGzgZ9y-vfFbWM97OEDo/view?usp=sharing')
    }
    const footer5Click = () => {
        window.open('https://adscholars.com/walmart-and-nbcuniversal-join-forces-for-shoppable-ad-on-bravos-show/7')
    }
    const footer6Click = () => {
        window.open('https://www.retailtouchpoints.com/topics/retail-innovation/black-friday-football-post-game-report-a-win-for-amazon-but-room-for-growth')
    }
    const footer7Click = () => {
        window.open('https://www.retailtouchpoints.com/topics/digital-commerce/disney-dives-into-shoppable-tv-with-debut-of-new-shop-the-stream-tech')
    }

    //display
    return (
        <div className="shoppableTV">

            <div id="video-container">
                <div className="ratio ratio-16x9">
                    <video
                        id="video-player"
                        ref={videoRef}
                        className="shoppableTV2"
                        src="https://nbtv.s3.us-east-2.amazonaws.com/linx.mp4"
                        autoPlay playsInline >
                    </video>

                    <div id="bottom-overlay" >
                        <p onClick={handlePlayClick}><PlayArrowOutlinedIcon /></p>
                        <p onClick={handlePauseClick}><PauseOutlinedIcon /></p>
                        <p onClick={toggleFullScreen} id="fullscreen-toggle-btn"><FullscreenOutlinedIcon /></p>
                        <p onClick={handleVolumeChange}><VolumeUpIcon /></p>

                        <input className="video-line" type="range" min="0" max="100" value={currentTime} onChange={handleProgressChange} />

                    </div>
                </div>

                <div>
                    {showFooter1 && (
                        <img
                            src={footer1}
                            className="pop-up-from-bottom footer"
                            style={{
                                position: 'absolute',
                                bottom: '10%',

                                left: '10%',
                                cursor: 'pointer',
                            }}
                            onClick={footer1Click}
                            alt="link to article"
                        />
                    )}

                    {showFooter2 && (
                        <img
                            src={footer2}
                            className="pop-up-from-bottom footer"
                            style={{
                                position: 'absolute',
                                bottom: '10%',

                                left: '10%',
                                cursor: 'pointer',
                            }}
                            onClick={footer2Click}
                            alt="Insider Intelligence"
                        />
                    )}
                    {showFooter3 && (
                        <img
                            src={footer3}
                            className="pop-up-from-bottom footer"
                            style={{
                                position: 'absolute',
                                bottom: '9%',

                                left: '7%',
                                cursor: 'pointer',
                            }}
                            onClick={footer3Click}
                            alt="Insider Intelligence"
                        />
                    )}
                    {showFooter4 && (
                        <img
                            src={footer4}
                            className="pop-up-from-bottom footer"
                            style={{
                                position: 'absolute',
                                bottom: '9%',

                                left: '28%',
                                cursor: 'pointer',
                            }}
                            onClick={footer4Click}
                            alt="Insider Intelligence"
                        />
                    )}
                    {showFooter5 && (
                        <img
                            src={footer5}
                            className="pop-up-from-bottom footer"
                            style={{
                                position: 'absolute',
                                bottom: '9%',
                                left: '49%',
                                cursor: 'pointer',
                            }}
                            onClick={footer5Click}
                            alt="Insider Intelligence"
                        />
                    )}
                    {showFooter6 && (
                        <img
                            src={footer6}
                            className="pop-up-from-bottom footer"
                            style={{
                                position: 'absolute',
                                bottom: '9%',
                                left: '70%',
                                cursor: 'pointer',
                            }}
                            onClick={footer6Click}
                            alt="Insider Intelligence"
                        />
                    )}
                    {showFooter7 && (
                        <img
                            src={footer7}
                            className="pop-up-from-bottom footer"
                            style={{
                                position: 'absolute',
                                bottom: '9%',
                                left: '90%',
                                cursor: 'pointer',
                            }}
                            onClick={footer7Click}
                            alt="Insider Intelligence"
                        />
                    )}

                </div>
            </div>


        </div >
    );
}
export default ShoppableTV;