import React, { useEffect, useState } from 'react';
import { tokens } from "../theme";
import { useTheme } from "@mui/material";
import { Chart } from 'react-google-charts';

import '../components/visuals/design/skSpecial.css'

import SnLogo from "../img/SN_whiteLogo.png"

import VizioAgeSN from "../components/Vizio/VizioAgeSN"
import VizioGenderSN from "../components/Vizio/VizioGenderSN"

const Vizio = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // FAST CHANNEL //////////////////////////////////////
    const [snData, setSnData] = useState([]);

    useEffect(() => {
        const fetchSnData = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/vizio`);
            const data = await response.json();

            if (response.ok) {
                setSnData(data);
            }
        };
        fetchSnData();
    }, []);


    const barLineGraphData = [
        ["Date", "Devices Reached", { role: "annotation" }, "Viewing Hours", { role: "annotation", p: { style: 'color: #000' } }],
        ...snData.sort((a, b) => a.order - b.order).map((item) => [
            item.date,
            item.sn_devices,
            `${item.sn_devices.toLocaleString()} `,
            item.sn_viewingHours,
            `${item.sn_viewingHours.toLocaleString()} `
        ]),
    ];

    // SN FAST CHANNEL OPTIONS
    const barLineGraphOptions = {
        title: '',
        titleTextStyle: {
            color: colors.SKcustom[10],
            fontSize: 16,
            bold: true,
        },

        chartArea: {
            left: 20, right: 20,
            maxWidth: '1000px'
        },

        hAxis: {
            title: "",
            textStyle: { color: colors.SKcustom[10], fontSize: 12 },
            gridlines: { color: 'transparent' },
        },
        vAxis: {
            title: "",
            textPosition: 'none',
            textStyle: { color: colors.SKcustom[10], fontSize: 12 },
            gridlines: { color: 'transparent' },
        },
        seriesType: "bars",
        series: {
            0: {
                color: "#ab966d"
            },
            1: {
                type: "line",
                color: '#fff',
                enableInteractivity: true,
            }
        },
        legend: {
            position: 'top',
            alignment: 'center',
            textStyle: {
                color: colors.SKcustom[10]
            }
        },
        backgroundColor: 'transparent',
        bar: { groupWidth: '90%' },
        tooltip: { trigger: 'both' },
        annotations: {
            alwaysOutside: true,
            textStyle: {
                fontSize: 10,
                fontWeight: 600,
                // color: colors.SKcustom[10],
            },
            stem: { color: 'transparent', length: 10 },
            enableInteractivity: false,
        },
    };

    return (
        <div style={{ color: colors.SKcustom[10] }} >
            {/* SN */}
            <div style={{ maxWidth: '1000px' }}>
                {/* HEADER */}
                <div style={{ backgroundColor: '#ab966d', alignItems: 'center', height: '60px' }}>
                    <img
                        src={theme.palette.mode === 'dark' ? SnLogo : SnLogo}
                        alt="Image 3"
                        style={{ height: '100px', marginTop: "-20px" }} />
                </div>

                {/* FAST CHANNEL ////////////////////// */}
                <div className="barChartWidth">
                    <div className="h1sk" style={{ margin: '10px 0px 0px 10px' }}>SN Fast Channel • 2024</div>
                    <div style={{ overflowY: 'hidden', overflowX: 'auto', width: '100%', marginBottom: '20px', paddingLeft: '10px' }}>
                        <div style={{ width: '1000px' }}>
                            <Chart
                                chartType="ComboChart"
                                width="90%"
                                height="400px"
                                data={barLineGraphData}
                                options={barLineGraphOptions}
                                style={{ marginLeft: '-5px' }}
                            />
                        </div>
                    </div>
                </div>

                {/* DEMOS ////////////////////// */}
                <div className="rowPlain">
                    {/* Age ////////////////////// */}
                    <div className="columnInARow" style={{ width: '350px', paddingLeft: '20px' }}>
                        <div className="h1sk" style={{ margin: '0px 0px 0px 0px' }}>Age</div>
                        <VizioAgeSN style={{ width: '100%' }} />
                    </div>

                    {/* Gender ////////////////////// */}
                    <div>
                        <div className="h1sk" style={{ margin: '10px 0px 0px 10px' }}>Gender</div>
                        <VizioGenderSN style={{}} />
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Vizio;
