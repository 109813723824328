import React from "react";
import { useEffect, useState } from "react"
import { Box, TextField } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../theme";
import { useTheme } from "@mui/material";

import SNprogrammingStats from "../components/SN/programming/SNprogrammingStats";
import GNprogrammingStats from "../components/GN/GNprogrammingStats"

import ShowColumns from "../components/visuals/columns/ShowColumns";

const Programming = () => {

    //aesthetics
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const s2 = {
        color: colors.SKcustom[10],
        fontWeight: '300',
        fontStyle: 'oblique',
        fontStretch: 'semi-expanded',
        fontSize: '1em',
        textAlign: 'left',
        width: '200px'
    }

    const baseUrl = window.location.href;

    //functionality
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredShows, setFilteredShows] = useState([]);
    const [shows, setShows] = useState([]);

    useEffect(() => {
        const fetchShows = async () => {
            //initiate HTTP request
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/shows`)
            const json = await response.json()

            if (response.ok) {
                setShows(json)

            }
        }

        fetchShows()

    }, []);

    //click
    const handleRowClick = (rowInfo) => {
        console.log('what is x', rowInfo);

        window.open(`${baseUrl}/${rowInfo.row.clip}`, '_blank')
    }

    //search filter
    const filterShows = (shows, searchTerm) => {
        if (!searchTerm) {
            return shows;
        }

        return shows.filter((show) => {
            const fieldValue = Object.values(show)
                .map((value) => (value || "").toString().toLowerCase())
                .join(" ");

            return fieldValue.includes(searchTerm.toLowerCase());
        });
    };

    // Update the filtered shows whenever the search term or original data changes
    useEffect(() => {
        const filteredData = filterShows(shows, searchTerm);
        setFilteredShows(filteredData);
    }, [shows, searchTerm]);


    return (
        <Box m="20px">

            <div className="row" style={{ alignItems: "center" }}>

                <div className="column" >


                    <div style={s2}>Search by title, talent, brand </div>

                    <TextField
                        label="Search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        variant="outlined"
                        margin="normal"
                        inputProps={{ style: { height: '15px' } }}
                        InputLabelProps={{
                            shrink: true,
                            style: { fontSize: '12px' }
                        }}
                        InputProps={{
                            style: {
                                height: '25px',
                                paddingTop: '10px',
                                paddingBottom: '0px',
                            },
                        }}
                    />
                </div>

                <div className="row" style={{ justifyContent: "space-between" }}>

                    <div className="column" style={{ marginLeft: "3em" }}><SNprogrammingStats /></div>
                    {/* <div className="column" style={{ marginLeft: "12em" }}><GNprogrammingStats /></div> */}

                </div>

            </div>

            <Box
                m="10px 0 0 0"
                height="100vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                        lineHeight: "10px"
                    },
                    "& .name-column--cell":
                    {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                        fontSize: "13px",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                        maxHeight: 50,
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                        fontSize: "10px"
                    },
                }}
            >
                <DataGrid
                    getRowId={(row) => row._id}
                    rows={filteredShows}
                    headerHeight={25}
                    rowHeight={20}
                    columns={ShowColumns}
                    components={{ Toolbar: GridToolbar }}
                    sortingOrder={['desc', 'asc']}
                    onRowClick={handleRowClick}
                />
            </Box>
        </Box>
    );
};


export default Programming;