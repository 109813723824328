import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";

function BarGraph() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  function hexToRgba(hex, opacity) {
    hex = hex.replace("#", "");
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return "rgba(" + r + "," + g + "," + b + "," + opacity + ")";
  }

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Active Members",
        backgroundColor: hexToRgba("97ABB1", 0.6),
        data: [],
      },
    ],
  });

  const [selectedYear, setSelectedYear] = useState(2024);

  useEffect(() => {
    // Fetch data from your MongoDB using Axios
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/botm/${selectedYear}`)
      .then((response) => {
        const dataFromServer = response.data;

        // Extract the necessary data for the chart
        const dates = dataFromServer.map((item) => item.month);
        const activeNumbers = dataFromServer.map((item) => item.active);

        // Update the chart data
        setChartData({
          ...chartData,
          labels: dates,
          datasets: [
            {
              ...chartData.datasets[0],
              data: activeNumbers,
            },
          ],
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [selectedYear]);

  const options = {
    scales: {
      x: {
        title: {
          display: false,
          text: "Date",
        },
        ticks: {
          display: true,
          color: colors.SKcustom[10],
        },
      },
      y: {
        title: {
          display: false,
          text: "Active Numbers",
        },

        ticks: {
          display: false,
          color: colors.SKcustom[10],
        },
      },
    },
    plugins: {
      datalabels: {
        anchor: "top",
        color: "#000",
        font: { size: "15em" },
        display: true,
      },

      legend: {
        labels: {
          font: { size: "15em" },
          color: colors.SKcustom[10],
          boxHeight: 15,
        },
      },
    },
  };

  const handleYearButtonClick = (year) => {
    setSelectedYear(year);
  };

  return (
    <div>
      <div>
        <button
          className="neonButton"
          style={{ marginRight: "10px" }}
          onClick={() => handleYearButtonClick(2021)}
        >
          2021
        </button>
        <button
          className="neonButton"
          style={{ marginRight: "10px" }}
          onClick={() => handleYearButtonClick(2022)}
        >
          2022
        </button>
        <button
          className="neonButton"
          style={{ marginRight: "10px" }}
          onClick={() => handleYearButtonClick(2023)}
        >
          2023
        </button>
        <button
          className="neonButton"
          style={{ marginRight: "10px" }}
          onClick={() => handleYearButtonClick(2024)}
        >
          2024
        </button>
      </div>
      <div
        style={{
          color: colors.SKcustom[20],
          fontSize: "1.2em",
          fontWeight: 600,
          marginTop: "5px",
        }}
      >
        {selectedYear}
      </div>
      <Bar data={chartData} options={options} />
    </div>
  );
}

export default BarGraph;
