import Calendar from "../components/Resources/calendar"
import CalendarKey from "../components/visuals/calendar/CalendarKey";

//aesthetics
import { tokens } from "../theme"
import { useTheme } from '@mui/material';



const MktgCalendar = () => {


    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const link = "https://docs.google.com/spreadsheets/d/1W7dQXYwfeFzcCSAftDckX_0OUCQU3qvdBn7jAj62rTI/edit?usp=sharing"
    return (

        <div style={{ marginLeft: "15px" }
        }>
            <CalendarKey />
            <div >
                <a href={link} target="_blank" rel="noopener noreferrer" style={{ color: colors.SKcustom[17] }}>
                    <p>Click here for GoogleSheet with more details</p>
                </a>
            </div>
            <Calendar />
        </div>

    );

};

export default MktgCalendar;
