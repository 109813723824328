import React, { useState, useEffect } from "react";
import axios from "axios";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";

import { Line } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(ChartDataLabels);

const BottleSales = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Bottle Sales',
                data: [],
                borderColor: colors.SKcustom[800],
                tension: 0.1,
                fill: false
            },
        ]
    });

    const loadChartData = () => {

        const convertGBPtoUSD = (amountInGBP) => amountInGBP / 0.8;

        // Fetch data from both endpoints
        Promise.all([
            axios.get(`${process.env.REACT_APP_SERVER_URL}/usbc/annualgross`),
            axios.get(`${process.env.REACT_APP_SERVER_URL}/whipshots/annualgross`),
            axios.get(`${process.env.REACT_APP_SERVER_URL}/ukbc/gross`),
        ])
            .then(responses => {
                const [bottleSalesResponse, whipshotsResponse, ukResponse] = responses;

                // Check if the data is valid and an array
                if (bottleSalesResponse.data && Array.isArray(bottleSalesResponse.data) &&
                    whipshotsResponse.data && Array.isArray(whipshotsResponse.data) &&
                    ukResponse.data && Array.isArray(ukResponse.data)
                ) {
                    // Extract the sales data and convert to numbers
                    const usSales = bottleSalesResponse.data.map(item => parseFloat(item.totalGross.replace(/[^0-9.-]+/g, "")));
                    const whipshotsSales = whipshotsResponse.data.map(item => parseFloat(item.totalGross.replace(/[^0-9.-]+/g, "")));
                    const ukSales = ukResponse.data.map(item => convertGBPtoUSD(parseFloat(item.totalGross.replace(/[^0-9.-]+/g, ""))));

                    // Compute combined sales
                    const combinedSales = usSales.map((sale, index) => {
                        const whipshotsSale = whipshotsSales[index] || 0;
                        const ukSale = ukSales[index] || 0;
                        return sale + whipshotsSale + ukSale;
                    });

                    setChartData({
                        labels: bottleSalesResponse.data.map(item => item.year),
                        datasets: [
                            {
                                label: 'US Bottle Sales',
                                data: usSales,
                                borderColor: "#0095B6",
                                tension: 0.1,
                                fill: false
                            },
                            {
                                label: 'Whipshots Sales',
                                data: whipshotsSales,
                                borderColor: "#F6B65C",
                                tension: 0.1,
                                fill: false
                            },
                            {
                                label: 'UK Bottle Sales ($1 : £0.8)',
                                data: ukSales,
                                borderColor: "#38b000",
                                tension: 0.1,
                                fill: false
                            },
                            {
                                label: 'Combined Sales',
                                data: combinedSales,
                                borderColor: "#D473D4",
                                tension: 0.1,
                                fill: false
                            }
                        ]
                    });
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };


    useEffect(() => {
        loadChartData();
    }, []);

    const chartOptions = {
        scales: {
            y: {
                ticks: {
                    display: false,
                    min: 'auto',
                    max: 'auto'

                },
                grid: {
                    display: false,
                    borderColor: colors.SKcustom[11],
                    borderWidth: 1
                },

                afterDataLimits: (axis) => {

                    axis.min -= 5; // Decrease the min limit to create space below the lowest data point
                }
            },
            x: {
                ticks: {

                    font: {
                        color: colors.SKcustom[11],
                        size: "18em",
                        weight: 600
                    }

                },
                grid: {
                    display: false,
                    borderColor: colors.SKcustom[11],
                    borderWidth: 1
                },
                // afterSetDimensions: (axis) => {
                //     axis.paddingBottom = 50; // Adjust the value as needed for padding above the x-axis
                // }
            }
        },
        plugins: {
            legend: {
                display: false
            },
            datalabels: {

                backgroundColor: colors.SKcustom[15],

                color: (context) => {
                    // Check the dataset index and change the color accordingly
                    if (context.datasetIndex === 1) {
                        //whipshots
                        return "#F6B65C";
                    } else if (context.datasetIndex === 2) {
                        //UK
                        return "#38b000";
                    } else if (context.datasetIndex === 3) {
                        //total
                        return "#D473D4";
                    } else {
                        //US
                        return "#0095B6"; // Default color for other datasets
                    }
                },
                align: (context) => {
                    if (context.datasetIndex === 1) {

                        //whipshots
                        return "bottom"

                    } else if (context.datasetIndex === 2) {

                        //UK
                        return "top"
                    }
                    else if (context.datasetIndex === 3) {

                        //total
                        return "top"

                    } else {

                        //US
                        return "top"
                    }
                },

                offset: (context) => {
                    if (context.datasetIndex === 1) {
                        //whipshots
                        return "10"
                    } else if (context.datasetIndex === 2) {
                        //UK
                        return "5"
                    }
                    else if (context.datasetIndex === 3) {
                        //total
                        return "10"
                    } else {
                        //US
                        return "10"
                    }
                },
                padding: (context) => {
                    if (context.datasetIndex === 1) {
                        //whipshots
                        return "10"
                    } else if (context.datasetIndex === 2) {
                        //UK
                        return "10"
                    }
                    else if (context.datasetIndex === 3) {
                        //total
                        return "10"
                    } else {
                        //US
                        return "10"
                    }
                },
                font: {
                    size: "14em",
                },
                formatter: function (value) {
                    return '$' + value.toLocaleString();
                }
            }
        },
        elements: {
            point: {
                backgroundColor: colors.text,
            },
            line: {
                borderWidth: 2,
            }
        },
        layout: {
            padding: {
                right: 50 // Add padding to the right side of the chart
            }
        },

        maintainAspectRatio: false,
    };

    const CustomLegend = () => (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', width: "90%" }}>
            {chartData.datasets.map((dataset, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                    <div style={{ width: '10px', height: '10px', backgroundColor: dataset.borderColor, marginRight: '5px' }}></div>
                    <span style={{ color: dataset.borderColor }}>{dataset.label}</span>
                </div>
            ))}
        </div>
    );


    return (
        <div style={{ height: "40em" }}>
            <CustomLegend />
            <Line data={chartData} options={chartOptions} />
        </div>
    );
};

export default BottleSales;



