import React, { useEffect, useState } from 'react';
import { Line } from "react-chartjs-2";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";

const GNsales = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [sales, setSales] = useState({ grossByYear: {}, unitsByYear: {} });

    useEffect(() => {
        const fetchSales = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/gnbc`);
            const data = await response.json();

            if (response.ok) {
                setSales(data);
            }
        };
        fetchSales();
    }, []);

    const { grossByYear, unitsByYear } = sales;
    const years = Object.keys(grossByYear).filter(year => year !== 'total');

    const data = {
        labels: years,
        datasets: [
            {
                label: 'Gross By Year',
                data: years.map(year => grossByYear[year]),
                borderColor: 'rgba(75, 192, 192, 1)',
                fill: false,

            },
            {
                label: 'Units By Year',
                data: years.map(year => unitsByYear[year]),
                borderColor: 'rgba(153, 102, 255, 1)',
                fill: false,
            },
        ],
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    display: false
                }
            },
        },

        plugins: {
            datalabels: {
                anchor: 'end',
                align: 'top',
                color: colors.SKcustom[10],
                formatter: (value, context) => {
                    return context.dataset.label === 'Gross By Year' ? `$${value.toLocaleString()}` : value.toLocaleString();
                },
            },
            legend: {
                labels: {
                    font: { size: "12em" },
                    color: colors.SKcustom[10],
                    boxHeight: 12,
                    boxWidth: 12
                }
            }
        },

        layout: {
            padding: {
                left: 20,
                right: 30
            }
        }
    };

    return (
        <div style={{ width: '500px' }} >
            <Line data={data} options={options} />
        </div >
    );
};

export default GNsales;