import React, { useEffect, useState } from "react"
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

import { tokens } from '../../theme'
import { useTheme } from '@mui/material'


//functiionality
const RTBstates = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [fullData, setFullData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            {
                data: [],
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
            },
        ],
    });

    const itemsPerPage = 20;

    useEffect(() => {
        const fetchStates = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/usbc/clstates`);
            const data = await response.json();
            if (response.ok) {
                const totalOrders = data.reduce((acc, { count }) => acc + count, 0);
                const formattedData = data.map(({ _id, count }) => ({
                    state: _id,
                    percentage: ((count / totalOrders) * 100).toFixed(2)
                }));
                setFullData(formattedData);
                setPageData(0, formattedData); // Initialize view with the first page of data
            } else {
                console.error('Failed to fetch states data:', data);
            }
        };
        fetchStates();
    }, []);

    const setPageData = (pageIndex, data) => {
        const startIndex = pageIndex * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const pageData = data.slice(startIndex, endIndex);

        setChartData({
            labels: pageData.map(item => item.state),
            datasets: [{
                ...chartData.datasets[0],
                data: pageData.map(item => item.percentage),
            }],
        });
    };

    const handlePrevClick = () => {
        const newPageIndex = Math.max(0, currentPage - 1);
        setCurrentPage(newPageIndex);
        setPageData(newPageIndex, fullData);
    };

    const handleNextClick = () => {
        const newPageIndex = Math.min(fullData.length / itemsPerPage - 1, currentPage + 1);
        setCurrentPage(newPageIndex);
        setPageData(newPageIndex, fullData);
    };

    return (
        <div>
            <h3>Orders by state - coming soon</h3>
            {/* <Bar data={chartData}
                options={{
                    scales: {
                        y: {
                            beginAtZero: true,
                            title: {
                                display: true,
                                text: 'Percentage (%)',
                                color: colors.SKcustom[10]
                            },
                            ticks: {
                                color: colors.SKcustom[10],

                                callback: function (value) {
                                    return `${value}%`;
                                },
                            },
                        },
                        x: {
                            ticks: {
                                color: colors.SKcustom[10]
                            }
                        }
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                        title: {
                            display: false,
                        },
                        tooltip: {
                            enabled: false,
                        },
                    },
                }} />
            <div>
                <button onClick={handlePrevClick} disabled={currentPage === 0}>Previous</button>
                <button onClick={handleNextClick} disabled={currentPage >= fullData.length / itemsPerPage - 1}>Next</button>
            </div> */}
        </div>
    );
};
export default RTBstates;