import React, { useState, useEffect } from "react";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Line } from "react-chartjs-2";

import { tokens } from "../../../../theme";
import { useTheme } from "@mui/material";

Chart.register(ChartDataLabels);

function Bc20_line() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/bc/zero`
      );
      const dataW = await response.json();

      if (response.ok) {
        setOrders(dataW);
      }
    };
    fetchOrders();
  }, []);

  const quantitiesPerDay = {};
  orders.forEach((order) => {
    const formatDate = (dateString) => {
      const date = new Date(
        new Date(dateString).toLocaleString("en", {
          timeZone: "America/New_York",
        })
      );
      const month = date.getMonth() + 1; // Adding 1 since getMonth() returns zero-based month
      const day = date.getDate();
      return `${month < 10 ? "0" + month : month}/${
        day < 10 ? "0" + day : day
      }`;
    };

    // const date = order.date;
    const formattedDate = formatDate(order.date);
    const quantity = order.units;
    if (quantitiesPerDay[formattedDate]) {
      quantitiesPerDay[formattedDate] += quantity;
    } else {
      quantitiesPerDay[formattedDate] = quantity;
    }
  });

  const labels = Object.keys(quantitiesPerDay);
  const quantities = Object.values(quantitiesPerDay);

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Units sold",
        data: quantities,
        backgroundColor: colors.SKcustom[100],
        borderColor: colors.SKcustom[100],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
      datalabels: {
        align: "end",
        anchor: "end",
        offset: 10,
        color: colors.SKcustom[200],
        font: { size: 10 },
      },
    },
    scales: {
      x: {
        ticks: {
          source: "auto",
          color: colors.SKcustom[200],
        },
        grid: { display: false },
      },
      y: {
        beginAtZero: true,
        min: 0,
        max: 30,
        ticks: { color: colors.SKcustom[100] },
      },
    },
  };

  const graphContainerStyle = {
    width: "100%",
    overflowX: "auto",
  };

  return (
    <div style={graphContainerStyle}>
      <h4>2020</h4>
      <p>
        This does not include MiniBar orders, which was primary on-demand
        service in 2020.
      </p>
      <Line data={data} options={options} width={100} height={30} />
    </div>
  );
}

export default Bc20_line;
