import { React, useState } from 'react';
import { tokens } from '../theme';

import { Link } from 'react-router-dom';
// import { Box, TextField } from "@mui/material";

import { useTheme, Dialog, IconButton } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import ClientForm from '../components/Resources/Ask'

//icons

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


//img
import TimelineImage from "../img/pitchSteps.png"

//components
import ClientFormButton from "../components/Resources/AskOG"
import Hats from '../components/Resources/Hats'


//execution
const Resources = () => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  function hexToRgba(hex, opacity) {
    hex = hex.replace('#', '');
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
  }

  //links


  function hood() { window.location.href = '/confidential'; };


  //carousel 
  const [currentSlide, setCurrentSlide] = useState(0);

  const videos = [
    'https://player.vimeo.com/video/841646657?h=dae78d676f',
    'https://player.vimeo.com/video/841647508?h=801bad18cf',
  ];

  const handleNextSlide = () => {
    setCurrentSlide((currentSlide + 1) % videos.length);
  };

  const handlePrevSlide = () => {
    setCurrentSlide((currentSlide - 1 + videos.length) % videos.length);
  };



  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (



    <div>


      {/* quicklinks */}
      < div className="rowHome" >
        <div className="homeCard" style={{ backgroundColor: colors.SKcustom[3], color: colors.SKcustom[10], borderColor: hexToRgba(colors.SKcustom[10], 9) }}>
          <h1 style={{ color: colors.SKcustom[15] }}>Brand Assets</h1>
          <ul className="homeCardList">
            <li><a
              rel="noopener noreferrer"
              href="/keynotes">
              Keynote Templates</a>
            </li>
            <li><a
              rel="noopener noreferrer"
              href="/sizzles">
              Sizzle Links</a>
            </li>
            <li><a target="_blank"
              rel="noopener noreferrer"
              href="https://docs.google.com/spreadsheets/d/14iURe81JuMUMh0kViVpwnvyUUcTGRHfTJTRTmtngevk/edit?usp=sharing">
              Press Links</a>
            </li>
            <li><a
              rel="noopener noreferrer"
              href="/toolkit">
              Branding: Logos, Fonts, Guides, & Messaging</a>
            </li>

          </ul>
        </div>

        <div className="homeCard" style={{ backgroundColor: colors.SKcustom[3], color: colors.SKcustom[10], borderColor: hexToRgba(colors.SKcustom[10], 9) }}>
          <h1 style={{ color: colors.SKcustom[15] }}>BizDev</h1>
          <ul className="homeCardList">
            <li><a target="_blank"
              rel="noopener noreferrer"
              href="https://docs.google.com/spreadsheets/d/1ox3mtB0AuRNJapd383ktphZY9XDOVui5CwAVjDlbiVc/edit?usp=sharing">
              BD Tracker</a>
            </li>
            <li><a
              rel="noopener noreferrer"
              href="/training">
              Training Tools</a>
            </li>
            <li><a
              rel="noopener noreferrer"
              href="/BrandSales">
              Brand Metrics</a>
            </li>
            <li><a
              rel="noopener noreferrer"
              href="https://www.relaydigital.com/requests/nbtv-requests">
              Design Requests</a>
            </li>
          </ul>
        </div>

        <div className="homeCard" style={{ backgroundColor: colors.SKcustom[3], color: colors.SKcustom[10], borderColor: hexToRgba(colors.SKcustom[10], 9) }}>
          <h1 style={{ color: colors.SKcustom[15] }}>Marketing</h1>
          <ul className="homeCardList">
            <li><a
              rel="noopener noreferrer"
              href="/Calendar">
              Calendar</a>
            </li>
            <li><a
              rel="noopener noreferrer"
              href="/campaigns">
              Campaign Reports</a>
            </li>
            <li><a target="_blank"
              rel="noopener noreferrer"
              href="https://login.qr-code-generator.com/#!/insights?folder=active&id=23353935">
              QR Generator</a>
            </li>
          </ul>
        </div>

        <div className="homeCard" style={{ backgroundColor: colors.SKcustom[3], color: colors.SKcustom[10], borderColor: hexToRgba(colors.SKcustom[10], 9) }}>
          <h1 style={{ color: colors.SKcustom[15] }}>Production</h1>
          <ul className="homeCardList">
            <li><a target="_blank"
              rel="noopener noreferrer"
              href="https://docs.google.com/document/d/1oZxPcJ_65t_vcs_QGoNpSIKNf5yr3MwrGDcfPsyK0Zg/edit?usp=sharing">
              Delivery Specs</a>
            </li>
            <li><a
              rel="noopener noreferrer"
              href="/confidential">
              How To Air</a>
            </li>
            <li><a
              rel="noopener noreferrer"
              href="/videokit">
              Video Branding Kit</a>
            </li>

          </ul>
        </div>

        <div className="homeCard" style={{ backgroundColor: colors.SKcustom[3], color: colors.SKcustom[10], borderColor: hexToRgba(colors.SKcustom[10], 9) }}>
          <h1 style={{ color: colors.SKcustom[15] }}>Employee Reources</h1>
          <ul className="homeCardList">
            <li><a target="_blank"
              rel="noopener noreferrer"
              href="https://docs.google.com/spreadsheets/d/1JRVNRSwo-pBaeK4Y6qNCehsSAPOtM6fxf20oo6wymVw/edit?usp=sharing">
              Directory</a>
            </li>
            <li><a
              rel="noopener noreferrer"
              href="/holidays">
              Holiday Calendar</a>
            </li>
            <li><a target="_blank"
              rel="noopener noreferrer"
              href="https://drive.google.com/drive/folders/1a36tMw8a1IkLBrNTWNnXx8BGWueGBZbP?usp=sharing">
              Spirits G-Drive</a>
            </li>
            <li><a target="_blank"
              rel="noopener noreferrer"
              href="https://drive.google.com/drive/folders/1S9UN_rtEdFMrPyliabh5TPGbruthCLc-?usp=sharing">
              Golf G-Drive</a>
            </li>
          </ul>
        </div>
      </div >

      <div className="rowHome" style={{ alignItems: "center" }}>

        <div onClick={handleOpen} className="askButton" style={{ borderColor: hexToRgba(colors.SKcustom[10], 0.5) }}>
          <p style={{ fontWeight: 600, cursor: "pointer", color: colors.SKcustom[10] }}>
            Don't see what you're looking for?
          </p>
        </div>

      </div>

      {/* pop-up */}

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <IconButton
          onClick={handleClose}
          style={{ position: 'absolute', right: '10px', top: '10px' }}
        >
          <CloseIcon />
        </IconButton>
        <ClientForm />
      </Dialog>


      {/* HELP SECTION */}
      {/* <div className="helpBox" style={{ color: colors.SKcustom[10] }}>
        <div className="row" style={{ alignItems: "center" }} >
          <div className="column" >
            <InfoOutlinedIcon style={{ color: colors.SKcustom[17], fontSize: "3.5em", marginLeft: "0.8em" }} />
            <p style={{ width: "150px", fontWeight: 700, color: colors.SKcustom[17] }}>Don't see what you're looking for?</p>
          </div>
          <ClientFormButton />
        </div>
      </div > */}

    </div>
  );
};

export default Resources

