import { tokens } from '../../../theme'
import { useTheme } from '@mui/material';
import '../../visuals/design/sk.css'

//imgs
import ChannelsLogo from '../../visuals/logoMode/logoModeChannels';
import GNLogo from '../../visuals/logoMode/logoModeGN'
import sn from "../../../img/SN.png"

const Toolkits = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    function hexToRgba(hex, opacity) {
        hex = hex.replace('#', '');
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
    }

    return (

        <div style={{ paddingLeft: "10px" }}>
            <div className="responsive-div" style={{ color: colors.SKcustom[10] }}><p>Click a channel to download logos, fonts and messaging guide.</p></div>

            <div className="rowPlain">

                <div className="kitCard" style={{ borderColor: hexToRgba(colors.SKcustom[10], 0.4) }}>
                    <a href={"https://drive.google.com/drive/folders/1PurwebnV_RzW634lw_dlRTld76NNyzVw?usp=drive_link"} target="_blank">
                        <ChannelsLogo mode={theme.palette.mode} style={{ width: "50px" }} />
                    </a>
                </div>

                <div className="kitCard" style={{ borderColor: hexToRgba(colors.SKcustom[10], 0.4) }}>
                    <a href={"https://drive.google.com/drive/folders/1VWCTdOO9WetB2orQfP4ZUDQ08CIdfxE0?usp=sharing"} target="_blank">
                        <img src={sn} alt="spirits network" className="kit" /></a>
                </div>

                <div className="kitCard" style={{ borderColor: hexToRgba(colors.SKcustom[10], 0.4) }}>
                    <a href={"https://drive.google.com/drive/folders/1204fPido3JYl52dwUG99xNsm-wKerdMI?usp=sharing"} target="_blank">
                        <GNLogo mode={theme.palette.mode} style={{ width: "50px" }} /></a>
                </div>


            </div>
        </div>
    )
}

export default Toolkits;