import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";

Chart.register(ChartDataLabels);

const UKBCannual = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


    //TOTAL
    const [bcSum, setSum] = useState(0);
    useEffect(() => {
        const fetchSum = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/ukup/total`);
            const data = await response.json()
            if (response.ok) {
                setSum(data.toLocaleString())
            }
        };
        fetchSum();
    }, []);

    //ANNUALS
    const [total2024, setTotal2024] = useState(0);
    useEffect(() => {
        const fetchTotal2024 = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/ukup/24total`);
            const data = await response.json();
            if (response.ok) {
                setTotal2024(data);
            };
        };
        fetchTotal2024();
    }, []);

    const years = ['2021', '2022', '2023', '2024']
    const signUpData = [742, 1210, 448, total2024]
    const sumData = [0, 742, 1952, 2400]

    // ANNUAL SIGN UPS
    const signUpDataset = {
        label: 'Annual Sign-Ups',
        backgroundColor: colors.SKcustom[30],
        data: signUpData,
        barThickness: 40,
        datalabels: {
            anchor: 'end',
            align: 'top',
            color: colors.SKcustom[30],
            font: { size: 10 },
            display: true,
            padding: {
                top: 10,
            },
            formatter: function (value, context) {
                return value.toLocaleString();
            },
        }
    };

    // CUMULATIVE SIGN UPS
    const sumDataset = {
        label: 'Cumulative',
        backgroundColor: colors.SKcustom[31],
        data: sumData,
        barThickness: 40,
        datalabels: {

            color: colors.SKcustom[10],
            font: { size: 10 },
            display: true,
            anchor: 'end',
            align: 'start',
            formatter: function (value, context) {
                return value.toLocaleString(); // Format the label using toLocaleString()
            },

        }
    };

    const chartData = {
        labels: years,
        datasets: [sumDataset, signUpDataset],
    };

    const options = {
        scales: {
            x: {
                stacked: true,
                beginAtZero: false,
                ticks: {
                    color: colors.SKcustom[10],
                    font: { size: 12, weight: 500 },
                    padding: 15
                },
                grid: { display: false }
            },
            y: {
                stacked: true,
                beginAtZero: false,
                ticks: {
                    display: false,
                    color: colors.SKcustom[10],
                    font: { size: 12 }
                },
                grid: { display: false },
                max: 3000
            },
        },
        plugins: {
            datalabels: {
                font: { size: 9 },
                display: true,

            },
            legend: {
                position: 'top',
                labels: {
                    font: { size: "12em" },
                    color: colors.SKcustom[10],
                    boxHeight: 12,
                    boxWidth: 12
                }
            }
        },

    };

    return (
        <div >
            <div className="h2sk" style={{ color: colors.SKcustom[10] }}>Total: {bcSum}</div>
            <Bar data={chartData} options={options} />
        </div>
    )
}
export default UKBCannual;