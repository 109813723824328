import React from "react";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";

const Tally1 = ({ children }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // Define the styles
    const tally1Styles = {
        color: `${colors.SKcustom[10]}`,
        // backgroundColor: hexToRgba(colors.SKcustom[12], 0.9),
        height: 120,
        width: 160,
        paddingTop: '22px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // borderLeft: '1px solid'
    };

    return <div style={tally1Styles}>{children}</div>;
};

export default Tally1;
