import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material';
import { tokens } from '../../theme'

import '../../components/visuals/design/videoMetrics.css'


import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, Tooltip, Legend, CategoryScale } from 'chart.js';

ChartJS.register(LineElement, PointElement, LinearScale, Title, Tooltip, Legend, CategoryScale);

const BCNewRegisters = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    function hexToRgba(hex, opacity) {
        hex = hex.replace('#', '');
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        return `rgba(${r},${g},${b},${opacity})`;
    }

    const [data, setData] = useState([]);
    const [golfdata, setGolfData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/usup/2024new`);
                if (response.ok) {
                    const fetchedData = await response.json();
                    setData(fetchedData);
                } else {
                    console.error('Failed to fetch data');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        const fetchGolfData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/gnup/2024new`);
                if (response.ok) {
                    const fetchedGolfData = await response.json();
                    setGolfData(fetchedGolfData);
                } else {
                    console.error('Failed to fetch data');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();
        fetchGolfData();
    }, []);

    const signUpData = {
        labels: data.map(item => item.month), // X-axis labels as months
        datasets: [
            {
                label: 'SN',
                data: data.map(item => item.signUp),
                borderColor: '#ab966d', // Line color
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                fill: false,
                tension: 0.1,
                borderWidth: 2,
                pointRadius: 3,
            },
            {
                label: 'GN',
                data: golfdata.map(item => item.newBC),
                borderColor: '#1c3ba2', // Line color
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                fill: false,
                tension: 0.1,
                borderWidth: 2,
                pointRadius: 3,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
                labels: {

                    boxWidth: 10,
                    font: { size: 8 }
                },
            },
            title: {
                display: true,
                text: 'New Registered Users',
                font: {
                    size: 10
                }
            },
            datalabels: {
                display: true,
                align: 'top',
                font: { size: 8 },
                formatter: function (value, context) {
                    if (typeof value === 'number' || value instanceof Date) {
                        return value.toLocaleString();
                    } else {
                        console.error('Value is not a number or date:', value);
                        return value;
                    }
                }
            }
        },
        scales: {
            y: {
                ticks: {
                    display: false,
                    font: { size: 8 },
                },
                grid: {
                    display: false,
                    drawBorder: false
                },
            },
            x: {
                ticks: { font: { size: 8 }, },
                grid: {
                    display: false,
                },
            },
        },
    };



    return (
        <div style={{ height: '200px' }}>

            <Line data={signUpData} options={options} />


        </div>
    );
};

export default BCNewRegisters;