import React from "react";
import { useEffect, useState } from "react";

const SNorg22 = () => {

    const [org22, setOrg22] = useState([]);

    useEffect(() => {
        const fetchOrg22 = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/shows/org22`);
            const data = await response.json();

            setOrg22(data)

        };
        fetchOrg22()
    })

    return (
        <div>
            {org22}
        </div>
    )

};

export default SNorg22