import React from "react";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";

const Tally3 = ({ children }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    function hexToRgba(hex, opacity) {
        hex = hex.replace('#', '');
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
    }


    // Define the styles
    const tally3Styles = {
        color: `${colors.SKcustom[10]}`,
        // backgroundColor: hexToRgba(colors.SKcustom[12], 0.9),
        height: 100,
        width: 200,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        borderLeft: '1px solid'
    };

    return <div style={tally3Styles}>{children}</div>;
};

export default Tally3;
