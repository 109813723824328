
import { tokens } from "../theme"
import { useTheme } from '@mui/material';
import hexToRgba from '../components/visuals/design/hexToRgba';

const Tj = () => {



    const theme = useTheme();
    const colors = tokens(theme.palette.mode);






    return (
        <div
            style={{ borderColor: hexToRgba(colors.SKcustom[50], 0.9) }}><p>test</p></div>
    )
}

export default Tj