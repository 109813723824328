import { tokens } from "../theme"
import { useTheme } from "@mui/material"
import '../components/visuals/design/sk.css'
import UsbcHistory from "../components/SN/BigCommerce/history/usbcHistory"
import BottleSalesLine from "../components/SN/BigCommerce/BottleSalesLine";
import TopSellers from "../components/SN/BigCommerce/TopSellers"
import BrandComaprison from "../components/SN/campaigns/BrandComparison";

const Annual = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <div>
            <h2 style={{ backgroundColor: colors.SKcustom[19], color: "#ffffff", fontWeight: 600, paddingLeft: "10px" }}>Annual Sales</h2>

            <div className="rowPlain">
                <div style={{ marginLeft: "2px", }}> <UsbcHistory /> </div>

                <div style={{ marginLeft: "2px", width: "80%" }}> <BottleSalesLine /> </div>

            </div>

            {/* topsellers */}
            <div style={{ marginTop: "5em" }}>
                <h2 style={{ backgroundColor: colors.SKcustom[19], color: "#ffffff", fontWeight: 600, paddingLeft: "10px" }}>Top Sellers</h2>
                <div style={{ margin: "15px" }}><TopSellers /></div>
            </div>

        </div >
    );
};

export default Annual;

