import { useEffect, useState } from "react"
import { Bar } from "react-chartjs-2";
// import { Chart, registerables } from 'chart.js';
// import ChartDataLabels from 'chartjs-plugin-datalabels';

import { tokens } from "../../theme";
import { useTheme } from "@mui/material";

import '../../components/visuals/design/sk.css'

const PantryFB = () => {

    //aesthetics
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    function hexToRgba(hex, opacity) {
        hex = hex.replace('#', '');
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
    }

    const fetchData = async (url, setter) => {
        const response = await fetch(url);
        const data = await response.json();
        if (response.ok) {
            setter(data);
        }
    };

    // Awareness Campaign
    const [adApril, setAdApril] = useState(0);
    const [adMay, setAdMay] = useState(0);
    const adJune = 0;

    useEffect(() => {
        fetchData(`${process.env.REACT_APP_SERVER_URL}/pantryfb/april`, setAdApril);
        fetchData(`${process.env.REACT_APP_SERVER_URL}/pantryfb/may`, setAdMay);
    }, []);

    // Camarena Campaign
    const camarenaApril = 0;
    const [camarenaMay, setCamarenaMay] = useState(0);
    const [camarenaJune, setCamarenaJune] = useState(0);
    const [camarenaJuly, setCamarenaJuly] = useState(0);

    useEffect(() => {
        fetchData(`${process.env.REACT_APP_SERVER_URL}/pantryfb/camarenamay`, setCamarenaMay);
        fetchData(`${process.env.REACT_APP_SERVER_URL}/pantryfb/camarenajune`, setCamarenaJune);
        fetchData(`${process.env.REACT_APP_SERVER_URL}/pantryfb/camarenajuly`, setCamarenaJuly);
    }, []);

    // Crown Maple
    const crownApril = 0;
    const [crownMay, setCrownMay] = useState(0);
    const [crownJune, setCrownJune] = useState(0);
    const [crownJuly, setCrownJuly] = useState(0);

    useEffect(() => {
        fetchData(`${process.env.REACT_APP_SERVER_URL}/pantryfb/crownmay`, setCrownMay);
        fetchData(`${process.env.REACT_APP_SERVER_URL}/pantryfb/crownjune`, setCrownJune);
        fetchData(`${process.env.REACT_APP_SERVER_URL}/pantryfb/crownjuly`, setCrownJuly);
    }, []);

    const [goatJune, setGoatJune] = useState(0);
    const [goatJuly, setGoatJuly] = useState(0);
    useEffect(() => {
        fetchData(`${process.env.REACT_APP_SERVER_URL}/pantryfb/goatjune`, setGoatJune);
        fetchData(`${process.env.REACT_APP_SERVER_URL}/pantryfb/goatjuly`, setGoatJuly);
    }, []);


    const metaAdData = [
        {
            month: "April '24",
            imps: adApril,
            camarenaImps: camarenaApril,
            crownImps: crownApril,
            goatImps: 0,
        },

        {
            month: "May '24",
            imps: adMay,
            camarenaImps: camarenaMay,
            crownImps: crownMay,
            goatImps: 0,
        },

        {
            month: "June '24",
            imps: adJune,
            camarenaImps: camarenaJune,
            crownImps: crownJune,
            goatImps: goatJune
        },
        {
            month: "July '24",
            camarenaImps: camarenaJuly,
            crownImps: crownJuly,
            goatImps: goatJuly
        },
    ]

    const chartData = {
        labels: metaAdData.map(item => item.month),
        datasets: [{
            label: 'Pantry Awareness',
            data: metaAdData.map(item => (item.imps)),
            borderColor: "orange",
            borderWidth: 1,
            backgroundColor: "orange",
            impsStrings: metaAdData.map(item => item.imps),
            datalabels: { display: true }
        },

        {
            label: 'Camarena',
            data: metaAdData.map(item => (item.camarenaImps)),
            borderColor: '#FC0FC0',
            borderWidth: 1,
            backgroundColor: '#FC0FC0',
            impsStrings: metaAdData.map(item => item.camarenaImps),
            datalabels: { display: true }
        },
        {
            label: 'Crown Maple',
            data: metaAdData.map(item => (item.crownImps)),
            borderColor: "#80FF00",
            borderWidth: 1,
            pointRadius: 5,
            backgroundColor: "#80FF00",
            impsStrings: metaAdData.map(item => item.crownImps),
            datalabels: { display: true }
        },
        {
            label: 'Little Goat',
            data: metaAdData.map(item => (item.goatImps)),
            borderColor: "#00B9E8",
            borderWidth: 1,
            pointRadius: 5,
            backgroundColor: "#00B9E8",
            impsStrings: metaAdData.map(item => item.goatImps),
            datalabels: { display: true }
        }
        ]
    };

    const options = {
        scales: {
            y: {
                beginAtZero: false,
                min: 0,
                max: 1000000,

                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    display: false,
                    color: colors.SKcustom[10],

                }
            },
            x: {
                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    font: {
                        size: "8px",
                    },
                    color: colors.SKcustom[10],
                }
            }
        },
        plugins: {
            legend: {
                display: true,
                labels: {
                    color: colors.SKcustom[10]
                },
                marginBottom: 40
            },
            datalabels: {
                display: true,
                anchor: 'end',
                align: 'top',
                color: colors.SKcustom[10],
                font: { size: 9 },
                rotation: -45,
                formatter: function (value, context) {

                    if (value === 0) {
                        return null; // Do not display labels with value 0
                    }
                    if (typeof value === 'number' || value instanceof Date) {
                        return value.toLocaleString();
                    } else {
                        console.error('Value is not a number or date:', value);
                        return value;
                    }
                }
            }
        },
        layout: {
            padding: {
                top: 10,
                bottom: 10,
                left: 0,
                right: 0,
            },
        },
        maintainAspectRatio: false,
        backgroundColor: 'transparent',
    };

    return (
        <div style={{ overflowX: 'auto', maxWidth: '600px', backgroundColor: 'transparent' }}>
            <div style={{ width: '300px', height: '300px', marginLeft: '50px' }}>
                <Bar data={chartData} options={options} />
            </div> </div>
    );
};
export default PantryFB;

