import React from "react";
import { useEffect, useState, useContext } from "react"
import { useParams } from 'react-router-dom';
import { tokens } from "../../theme"
import { useTheme } from "@mui/material";

import Counter from "../../context/Counter";

import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import NorthEastOutlinedIcon from '@mui/icons-material/NorthEastOutlined';

const PantryProductPage = () => {

  //aesthetics: 
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const metadata = {
    color: colors.SKcustom[20],
    margin: "0px 10px 0px 5px",
    fontWeight: 400
  }

  const { clip } = useParams()

  //occurs after render
  const [product, setProduct] = useState([]);
  const [selectedTab, setSelectedTab] = useState('Additional Info');

  useEffect(() => {
    const fetchProduct = async () => {
      //initiate HTTP request
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantry/${clip}`);
      const data = await response.json();
      console.log('productData', data)
      setProduct(data);
    }

    fetchProduct()

  }, [clip]);

  if (!product) {
    return <div>Loading...</div>;
  }

  const TabHeaders = {
    ADDITIONAL: 'Additional Info',
    ENJOY: 'How to Enjoy',
    SPECS: 'Specs',
  };

  //select info
  const renderContent = () => {
    switch (selectedTab) {
      case 'Additional Info':
        return <p>{product?.additional}</p>;
      case 'How to Enjoy':
        return <p>{product?.enjoy}</p>;
      case 'Specs':
        return <p>{product?.specs}</p>;
      default:
        return null;
    }
  };

  if (!product) {
    return <div>Loading...</div>;
  }

  // Inline styling function for tabs based on selection
  const getTabStyle = (tabName) => ({
    cursor: 'pointer',
    borderTop: selectedTab === tabName ? '3px solid #ab966d' : 'none',
    borderLeft: selectedTab === tabName ? '3px solid #ab966d' : 'none',
    borderRight: selectedTab === tabName ? '3px solid #ab966d' : 'none',
    borderBottom: selectedTab !== tabName ? '3px solid #ab966d' : 'none',
    padding: '10px',
    // backgroundColor: selectedTab === tabName ? '#f4f4f4' : 'transparent', 
  });

  if (!product) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ paddingLeft: "10px" }}>
      <div className="responsive-div"></div>

      <div className="product-row1">

        <div className="product-column1">
          <img className="product-card2" src={product.skImage} style={{ maxWidth: "30em" }} />

          <div className="product-row1">
            <img className="product-card3" src={product.skImage} style={{ maxWidth: "8em" }} />
            <img className="product-card3" src={product.skImage} style={{ maxWidth: "8em" }} />
            <img className="product-card3" src={product.skImage} style={{ maxWidth: "8em" }} />
          </div>

        </div>

        <div className="product-column1" style={{ marginLeft: "3em", marginRight: "3em" }}>
          <h1 style={{ color: colors.SKcustom[10] }}>{product.productTitle}</h1>

          <div className="product-row1">

            <div className="product-column1">

              <p style={{ color: "#ab966d", fontWeight: 650, fontSize: "1em" }}>FLAVOR</p>
              <div className="variant">{product.size ? product.size : 'N/A'}</div>

            </div>

            <div className="product-column1">
              <p style={{ color: "#ab966d", fontWeight: 650, fontSize: "1em" }}>COLOR</p>
              <div className="variant">{product.color ? product.color : 'N/A'}</div>
            </div>
          </div>

          <div className="product-row1">
            <Counter />
            <div className="goldBorder" style={{ marginLeft: "30px", color: "white", fontSize: "1.5em", borderRadius: "20px", width: "25em", display: "flex", justifyContent: "center", alignItems: "center" }} ><ShoppingCartOutlinedIcon /> <span style={{ paddingLeft: "40x" }} /> ADD TO CART</div>
          </div>

          <div className="product-row1">
            <div style={{
              background: "linear-gradient(#5c3400, #ca8e1d)",
              marginLeft: "0px",
              color: "black",
              fontSize: "1.5em",
              borderRadius: "20px",
              width: "100%",
              height: "3em",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px"
            }} ><ShoppingCartOutlinedIcon /> <span style={{ paddingLeft: "40x" }} /> QUICK BUY</div>
          </div>

          <p style={{ color: colors.SKcustom[10], fontSize: "1.5em" }}>{product.description}</p>


          <div className="product-row1" style={{ color: "white" }}>
            <div className="product-column1">
              <FavoriteBorderOutlinedIcon />
              WISHLIST
            </div>
            <div className="product-column1">
              <NorthEastOutlinedIcon />
              SHARE
            </div>
          </div>

        </div>
      </div>



      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "center" }}>

        {['Additional Info', 'How to Enjoy', 'Specs'].map(header => (
          <div
            key={header}
            className="det"
            style={{ width: selectedTab === header ? '30%' : 'auto' }}
            onClick={() => setSelectedTab(header)}
          >
            <h2
              style={{
                margin: 0,
                justifyContent: 'center',
                padding: '10px 50px',
                backgroundColor: 'transparent',
                borderTop: selectedTab === header ? '3px solid #ab966d' : 'none',
                borderLeft: selectedTab === header ? '3px solid #ab966d' : 'none',
                borderRight: selectedTab === header ? '3px solid #ab966d' : 'none',
                borderBottom: selectedTab !== header ? '3px solid #ab966d' : 'none',
              }}
            >
              {header}
            </h2>
            {selectedTab === header && renderContent()}
          </div>
        ))}
      </div>

    </div >
  );
};

export default PantryProductPage;