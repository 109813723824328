import React from 'react';

import BottlesBC from "../components/SN/BigCommerce/USBottlesBC"

//functiionality
const Bottles = () => {

    //display
    return (
        <div>
            <BottlesBC />
        </div>
    );
};

export default Bottles;