import React, { useEffect, useState } from "react";

import { tokens } from '../../../theme';
import { useTheme } from '@mui/material';

const Breakdown = (props) => {
    // console.log('props', props);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    //mntn
    const [mntn, setMntn] = useState(0);
    useEffect(() => {
        const fetchMntn = async () => {

            const response = await fetch('https://api.mountain.com/apidata?key=uowowFvBF4RIqXYp55td&begin=2023-12-01&end=2024-01-31&format=json&data=Advertiser.Impressions')

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            // setMntn(data)

            // Sum the impressions
            const sumOfImpressions = data.data.reduce((acc, item) => {

                //impressions must be converted from string to # 
                const impressions = Number(item.Impressions.replace(/,/g, ''));
                return acc + (isNaN(impressions) ? 0 : impressions);
            }, 0);

            setMntn(sumOfImpressions);

        }
        fetchMntn()

    }, [])

    return (
        <div className="visibleBorder2" id="lato">

            {/* header  ///////////////////////////// */}
            <div style={{ padding: '10px', justifyContent: "Center", color: colors.SKcustom[10], backgroundColor: "#15616d" }}>
                <div style={{ display: 'flex', Flexdirection: 'column' }}>

                    <div style={{ marginRight: '20px' }}>
                        <div><span style={{ fontSize: '11px', color: "#F6B65C", fontWeight: 500 }}>Asset: &nbsp;&nbsp;</span> <span
                            style={{ fontSize: '11px', fontWeight: 800 }}>{props.details.asset}</span> </div>
                        <div><span style={{ fontSize: '11px', color: "#F6B65C", fontWeight: 500 }}>Aired: &nbsp;&nbsp;</span> <span
                            style={{ fontSize: '11px', fontWeight: 800 }}>{props.details.live}</span> </div>
                    </div>

                    <div>
                        <div><span style={{ fontSize: '11px', color: "#F6B65C", fontWeight: 500 }}>Campaign: &nbsp;&nbsp;</span> <span
                            style={{ fontSize: '11px', fontWeight: 800 }}>{props.details.campaign}</span> </div>
                        <div><span style={{ fontSize: '11px', color: "#F6B65C", fontWeight: 500 }}>Brands: &nbsp;&nbsp;</span> <span
                            style={{ fontSize: '11px', fontWeight: 800 }}>{props.details.brands}</span> </div>
                    </div>
                </div>
            </div>

            {/* body  ///////////////////////////// */}
            <div style={{ display: 'flex', backgroundColor: "#111522", padding: "10px", justifyContent: "Center" }}>

                <div className="campaignColumn">

                    {/*  GoogleAds */}
                    <div style={{ color: "#fff", marginBottom: '0', fontSize: ".8em", width: "100px", fontWeight: 600 }}>Google Ads</div>

                    <div className="campaignData">Imp:
                        <span className="campaignStat">{props.details.g_imp_old ? (props.details.g_imp_old.toLocaleString()) : 'n/a'}</span> </div>

                    <div className="campaignData">Interactions:
                        <span className="campaignStat">*</span></div>

                    <div className="campaignData">Avg CPM:
                        <span className="campaignStat">*</span> </div>

                    {/*  YouTube */}
                    <div style={{ color: "#fff", marginTop: '10px', fontSize: ".8em", width: "100px", fontWeight: 600 }}>YouTube</div>

                    <div className="campaignData">Views:
                        <span className="campaignStat">{props.details.YTviews ? (props.details.YTviews.toLocaleString()) : 'n/a'}</span></div>

                    <div className="campaignData">Likes:
                        <span className="campaignStat">{props.details.YTlikes ? (props.details.YTlikes) : 'n/a'}</span></div>

                    {/*  GA4 */}
                    <div style={{ color: "#fff", marginTop: '10px', fontSize: ".8em", width: "100px", fontWeight: 600 }}>GA4</div>

                    <div className="campaignData">Page Views:
                        <span className="campaignStat">*</span></div>

                    <div className="campaignData">Users:
                        <span className="campaignStat">*</span></div>

                    <div className="campaignData">Views Per User:
                        <span className="campaignStat">*</span></div>

                    <div className="campaignData">Avg Engagement Time:
                        <span className="campaignStat">*</span></div>
                </div>

                <div className="campaignColumn" style={{ borderLeft: "1px solid", paddingLeft: "15px" }}>
                    {/*  Facebook */}
                    <div style={{ color: "#fff", marginBottom: '0', fontSize: ".8em", width: "100px", fontWeight: 600 }}>Facebook Ads</div>

                    <div className="campaignData" >Imp:
                        <span className="campaignStat">{props.details.meta_imp_old ? props.details.meta_imp_old : 'n/ a'}</span></div>

                    <div className="campaignData">Views:
                        <span className="campaignStat">{props.details.meta_view_old ? props.details.meta_view_old : 'n/a'}</span></div>

                    {/* Instagram */}
                    <div style={{ color: "#fff", marginTop: '10px', fontSize: ".8em", width: "100px", fontWeight: 600 }}>Instagram</div>
                    <div className="campaignData">Reach:
                        <span className="campaignStat">{props.details.IGreach ? props.details.IGreach : 'n/a'}</span></div>
                    <div className="campaignData"># of Reels:
                        <span className="campaignStat">{props.details.IGposts ? props.details.IGposts : '0'}</span></div>

                    {/* Klaviyo */}
                    <div style={{ color: "#fff", marginTop: '10px', fontSize: ".8em", width: "100px", fontWeight: 600 }}>Klaviyo</div>
                    <div className="campaignData">Opens:
                        <span className="campaignStat">*</span></div>

                    <div className="campaignData">Clicks:
                        <span className="campaignStat">*</span></div>

                    <div className="campaignData">Emails sent:
                        <span className="campaignStat">{props.details.EmailsSent !== undefined ? Number(props.details.EmailsSent) : '*'}</span></div>
                </div>

                <div className="campaignColumn" style={{ borderLeft: "1px solid", paddingLeft: '15px' }}>
                    {/*  CTV */}
                    <div style={{ color: "#fff", marginBottom: '0', fontSize: ".8em", width: "150px", fontWeight: 600 }}>CTV</div>

                    <div className="campaignData">Vizio:
                        <span className="campaignStat" style={{ marginLeft: "10px" }}> </span>
                        <span style={{ marginLeft: "5px" }}>Min watched</span></div>

                    <div className="campaignData">Mntn: <span className="campaignStat">
                        <span className="campaignData"></span> </span> Imp</div>

                    <div style={{ color: "#fff", marginTop: '10px', fontSize: ".8em", width: "100px", fontWeight: 600 }}>Platform</div>
                    <div className="campaignData">Views:  <span className="campaignStat">{props.details.PlatformViews}</span></div>
                    <div className="campaignData">Units Sold:  <span className="campaignStat">*</span></div>


                </div>
            </div >

            {/* FOOTER - Guranteed */}
            < div className="row" style={{ backgroundColor: "#33658a", paddingBottom: "10px", justifyContent: "Center" }}>

                <div style={{ display: 'flex', flexDirection: 'column' }} >

                    <div style={{ color: "#fff", marginTop: '10px', fontSize: "14px", fontWeight: 600, color: "#F6B65C" }}>Campaign Overview <span style={{ fontSize: "11px" }}>(above metrics are for an individual asset)
                    </span></div>
                    <table className="tableSK" style={{ alignItems: "top", marginTop: "10px", marginBottom: '10px' }}>
                        <thead>
                            <tr style={{ color: "#fff" }}>
                                <th className="subcolS" style={{ borderRight: "1px solid", fontWeight: 400, fontSize: ".8em", color: "#fff", paddingLeft: "5px" }}></th>
                                <th className="subcolS" style={{ borderRight: "1px solid", fontWeight: 400, fontSize: ".8em", color: "#fff", paddingLeft: "5px" }}>Imps</th>
                                <th className="subcolS" style={{ borderRight: "1px solid", fontWeight: 400, fontSize: ".8em", color: "#fff", paddingLeft: "5px" }}>View</th>
                                <th className="subcolS" style={{ borderRight: "1px solid", fontWeight: 400, fontSize: ".8em", color: "#fff", paddingLeft: "5px" }}>CTV</th>
                                <th className="subcolS" style={{ fontWeight: 400, color: "#fff", paddingLeft: "5px", fontSize: ".8em" }}>Emails</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr key={props.details._id} style={{ color: "#fff" }}>
                                <td className="subcolS" style={{ borderRight: "1px solid", paddingLeft: "12px", fontWeight: 300, fontSize: '10px' }}>Actual:</td>
                                <td className="subcolS" style={{ borderRight: "1px solid", paddingLeft: "12px", fontWeight: 300, fontSize: '10px' }}></td>
                                <td className="subcolS" style={{ borderRight: "1px solid", paddingLeft: "12px", fontWeight: 300, fontSize: '10px' }}>{props.details.viewTotal}</td>
                                <td className="subcolS" style={{ borderRight: "1px solid", paddingLeft: "12px", fontWeight: 300, fontSize: '10px' }}></td>
                                <td className="subcolS" style={{ paddingLeft: "12px", fontWeight: 300, fontSize: '10px' }}></td>
                            </tr>
                            <tr key={props.details._id} style={{ color: "#fff" }}>
                                <td className="subcolS" style={{ borderRight: "1px solid", paddingLeft: "12px", fontWeight: 300, fontSize: '10px' }}>Guaranteed:</td>
                                <td className="subcolS" style={{ borderRight: "1px solid", paddingLeft: "12px", fontWeight: 300, fontSize: '10px' }}>{props.details.imp_min ? props.details.imp_min : 'N/A'}</td>
                                <td className="subcolS" style={{ borderRight: "1px solid", paddingLeft: "12px", fontWeight: 300, fontSize: '10px' }}>{props.details.view_min ? props.details.view_min : 'N/A'}</td>
                                <td className="subcolS" style={{ borderRight: "1px solid", paddingLeft: "12px", fontWeight: 300, fontSize: '10px' }}>{props.details.ctv_min ? props.details.ctv_min : 'N/A'} imp </td>
                                <td className="subcolS" style={{ paddingLeft: "12px", fontWeight: 300, fontSize: '10px' }}>{props.details.emails}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div >

        </div >

    );


}

export default Breakdown;