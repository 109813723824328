// TotalPantryViews.js
export const fetchTotalPantryViews = async () => {


    // Fetch IG LIVE TOTAL
    const responseInstagram = await fetch(`${process.env.REACT_APP_SERVER_URL}/meta/total`);
    const dataInstagram = await responseInstagram.json();
    // const totalInstagram = responseInstagram.ok ? Number(dataInstagram.sum) : 0;

    // Fetch YT SERIES TRAILER
    const responseYT = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantryyoutube/total`);
    const dataYT = await responseYT.json()


    // Calculate total views
    const totalViews = dataInstagram + dataYT + 403689
    return totalViews;
};

export default fetchTotalPantryViews;
