import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";

// color design tokens export. 
//This function returns a value + array of dependencies.
export const tokens = (mode) => ({
    ...(mode === "dark"
        ? {
            SKcustom: {
                0: 'rgba(231, 232, 229, 0.5)', //platinum
                1: 'rgba(145, 163, 176, 0.3)', //grey to 
                2: 'transparent',
                3: "#1F2A40", //space cadet (blue sidebar)
                10: "#EDEFF6", // ghost white to black
                11: "#AB966D", //gold to black
                12: "#EDEFF6", // ghost white  to gray
                13: "#F6B65C", //orange to white
                14: "#F6B65C", //orange to black
                15: "#000", // black to ghose white
                16: "#f38447", //better orange
                17: "#F6B65C", //orange to red
                18: 'rgba(255, 255, 0, 0.5)', //green to black
                19: '#ab966d', //gold to blue
                20: "#F6B65C", // orange+yellow to black
                21: "#FFEF00", //canary to black
                25: "#8A8BAB", //lavendar to gold
                30: '#7FFFD4',//aqua to skyblue
                31: '#27849E',//stays teal
                32: "#1e6488", //lapis lazuli to gray
                33: "#7FFFD4", //aqua
                34: "#2072AF",
                35: "#3c6e71",
                36: "#727A9A",
                40: '#FBCEB1', //apricot
                50: '#ab966d', //gold to gold
                100: 'rgba(64, 224, 208, 1)',
                200: 'rgba(245, 245, 245, 1)',
                300: 'rgba(255, 0, 128, 1',//rose
                700: "#1e6488", //lapis lazuli (teal)
                701: "#FFFACD", //light yellow to orange
                800: "#a9d6e5", //lightBlue to dark
                801: "#6082B6", //glaucous blue to white
                802: "#6082B6", //glaucous blue to black
                // 801: "#d100d1", //magenta
            },


            //gold translucent 'rgba(171, 150, 109, 0.7)'

            grey: {
                100: "#e0e0e0", //platinum
                200: "#c2c2c2", //silver
                300: "#a3a3a3", //silver
                400: "#858585", //battleship gray
                500: "#666666", //dim gray
                600: "#525252", //davys gray
                700: "#3d3d3d", //onyx
                800: "#292929", //jet
                900: "#141414", //night
            },

            SKgrey: {
                100: "#e8e9f3", //ghost white
                200: "#cecece", //silver
                300: "#a6a6a8", //frech gray
                400: "#C3B497", //non photo blue
                500: "#cecece", //silver

            },
            primary: {
                100: "#d0d1d5", //french gray
                200: "#a1a4ab", //cadet gray
                300: "#727681", //slate gray
                400: "#1F2A40", //space cadet (blue sidebar)
                500: "#111522", //rich black (background)
                600: "#101624", //rich black
                700: "#0c101b", //rich black
                800: "#080b12", //rich black
                900: "#040509", //black
            },
            greenAccent: {
                100: "#dbf5ee", //mintGreen
                200: "#b7ebde", //celeste (aqua)
                300: "#94e2cd", //tiffany blue
                400: "#70d8bd", //turquoise
                500: "#ffffff", //white
                600: "#ffffff", //white
                700: "#2e7c67", //Viridian (light emerald)
                800: "#1e5245", //brunswich green
                900: "#0f2922", //dark green
            },
            redAccent: {
                100: "#f8dcdb", //misty rose
                200: "#f1b9b7", //tea rose
                300: "#e99592", //light coral
                400: "#e2726e", //light coral
                500: "#db4f4a", //jasper (red)
                600: "#af3f3b", //auburn
                700: "#832f2c", //burnt umber
                800: "#58201e", //caput moruum
                900: "#2c100f", //black bean
            },
            blueAccent: {
                50: "#439cfb", //GN Blue Light to Dark
                100: "#e1e2fe", //lavender
                200: "#c3c6fd", //periwinkle
                300: "#a4a9fc", //powder blue
                400: "#868dfb", //tropical indigo
                500: "#6870fa", //medium slate blue
                600: "#535ac8", //savoy blue
                700: "#1e6488", //lapis lazuli (teal)
                800: "#2a2d64", //deft blue
                900: "#151632", //space cadet
            },
        }
        : {
            grey: {
                100: "#141414", //night (black)
                200: "#292929", //jet
                300: "#3d3d3d", //onyx
                400: "#525252", //davys gray
                500: "#666666", //dim gray
                600: "#858585", //battleship gray
                700: "#a3a3a3", //silver
                800: "#c2c2c2", //ligher silver
                900: "#e0e0e0", //platinum
            },

            // light
            SKcustom: {
                0: 'rgba(159, 160, 158, 1)', //platinum
                2: "#6082B6", //frech gray
                3: '#fff',  //space cadet (blue sidebar)
                10: "#000", //black
                11: "#000", //gold to black
                12: '#ab966d',
                13: "#FFFFFF", //orange to white
                14: "#000000",
                15: "#EDEFF6", //ghost white
                16: "#E25822",
                17: "#f68080",
                18: "#000",
                19: '#1c3ba2', //gold to blue
                20: "#000", // black
                21: "#000", //canary to black
                25: "#8B8589",
                30: "#45caff", //sky blue
                31: '#27849E',//emerald to orange
                32: '#cecece', //teal to gray
                34: "#0003",
                35: "#3c6e71",
                36: "#727A9A",
                40: '#FBCEB1', //apricot
                50: '#ab966d', //gold to gold
                100: 'rgba(222, 49, 99, 1)',
                200: 'rgba(222, 49, 99, 1)',
                700: "#d0d1d5", //french gray,
                701: "#F38447",
                800: "#1c3ba2", //lightBlue to dark
                801: "#ffffff", //glaucous blue to white
                802: "#000000", //glaucous blue to black
                // 801: "#d100d1", //magenta
            },

            SKgrey: {
                100: "#e8e9f3", //ghost white
                200: "#cecece", //silver
                300: "#a6a6a8", //frech gray
                400: "#C3B497", //non photo blue
                500: "000000", //black
            },
            primary: {
                100: "#040509", //black
                200: "#080b12", //rich black
                300: "#0c101b", //rich black
                400: "#f2f0f0", //white smoke
                500: "#ffffff", //oxford blue
                600: "#1F2A40", //space cadet
                700: "#727681", //slate gray
                800: "#a1a4ab", //cadet gray
                900: "#d0d1d5", //french gray
            },
            greenAccent: {
                100: "#0f2922",
                200: "#1e5245",
                300: "#2e7c67",
                400: "#3da58a",
                500: "#ffffff",
                600: "#ffffff",
                700: "#94e2cd",
                800: "#b7ebde",
                900: "#dbf5ee",
            },
            redAccent: {
                100: "#2c100f",
                200: "#58201e",
                300: "#832f2c",
                400: "#af3f3b",
                500: "#db4f4a",
                600: "#e2726e",
                700: "#e99592",
                800: "#f1b9b7",
                900: "#f8dcdb",
            },
            blueAccent: {
                50: "#1c3ba2", //GN Blue Light to Dark
                100: "#151632",
                200: "#2a2d64",
                300: "#ffffff",
                400: "#535ac8",
                500: "#6870fa",
                600: "#868dfb",
                700: "#ffffff",
                800: "#c3c6fd",
                900: "#e1e2fe",
            },
        }),
});

// mui theme settings
export const themeSettings = (mode) => {
    const colors = tokens(mode);
    return {
        palette: {
            mode: mode,
            ...(mode === "dark"
                ? {
                    // palette values for dark mode
                    primary: {
                        main: colors.primary[500],
                    },
                    secondary: {
                        main: colors.greenAccent[500],
                    },
                    neutral: {
                        dark: colors.grey[700],
                        main: colors.grey[500],
                        light: colors.grey[100],
                    },
                    background: {
                        default: colors.primary[500],
                    },
                }
                : {
                    // palette values for light mode
                    primary: {
                        main: colors.primary[100],
                    },
                    secondary: {
                        main: colors.greenAccent[500],
                    },
                    neutral: {
                        dark: colors.grey[700],
                        main: colors.grey[500],
                        light: colors.grey[100],
                    },
                    background: {
                        default: "#fcfcfc",
                    },
                }),
        },
        typography: {
            fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
            htmlFontSize: 16,
            fontWeightLight: 300,
            fontWeightRegular: 400,
            fontWeightMedium: 500,
            fontWeightBold: 600,
            h1: {
                fontWeight: 600,
                fontSize: '2.375rem',
                lineHeight: 1.21
            },
            h2: {
                fontWeight: 300,
                fontSize: '1.575rem',
                lineHeight: 1.27,
                color: `${colors.SKcustom[300]}`,
            },
            h3: {
                fontWeight: 100,
                fontSize: '1.2rem',
                lineHeight: 1.33
            },
            h4: {
                fontWeight: 600,
                fontSize: '1.25rem',
                lineHeight: 1.4
            },
            h5: {
                fontWeight: 600,
                fontSize: '1rem',
                lineHeight: 1.5
            },
            h6: {
                fontWeight: 400,
                fontSize: '0.875rem',
                lineHeight: 1.57
            },
            caption: {
                fontWeight: 400,
                fontSize: '0.75rem',
                lineHeight: 1.66
            },
            body1: {
                fontSize: '0.875rem',
                lineHeight: 1.57
            },
            body2: {
                fontSize: '0.75rem',
                lineHeight: 1.66
            },
            subtitle1: {
                fontSize: '0.875rem',
                fontWeight: 600,
                lineHeight: 1.57
            },
            subtitle2: {
                fontSize: '0.75rem',
                fontWeight: 500,
                lineHeight: 1.66
            },
            overline: {
                lineHeight: 1.66
            },
            button: {
                textTransform: 'capitalize'
            }
        },
        components: {
            MuiIcon: {
                styleOverrides: {
                    root: {
                        fontSize: "150px"
                    }
                }
            }
        }
    };
};




// context for color mode
export const ColorModeContext = createContext({
    toggleColorMode: () => { },
});

export const useMode = () => {
    const [mode, setMode] = useState("dark");

    const colorMode = useMemo(
        () => ({
            toggleColorMode: () =>
                setMode((prev) => (prev === "light" ? "dark" : "light")),
        }),
        []
    );

    const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
    return [theme, colorMode];
};
