const Question = (props) => {

    const renderInput = () => {
        const inputName = props.question.name;
        const inputValue = props.response[inputName] || [];

        switch (props.question.type) {
            case "number":
                return (
                    <input
                        type="number"
                        min="0"
                        max="5"
                        name={inputName}
                        value={inputValue}
                        onChange={props.handleChange} />
                )

            case "string":
                return (
                    <textarea
                        name={inputName}
                        value={inputValue}
                        onChange={props.handleChange}>
                    </textarea>
                )

            case "selectOne":
            case "selectOneV":
                return (
                    <div>
                        {props.question.choices.map(choice => (
                            <label className="choice-label" key={choice}>
                                <input
                                    type="radio"
                                    name={inputName}
                                    value={choice}
                                    checked={inputValue === choice}
                                    onChange={props.handleChange} />
                                {choice}
                            </label>
                        ))}
                    </div>
                )

            case "checkbox":
                return (
                    <div>
                        {props.question.choices.map(choice => (
                            <label className="choice-label" key={choice}>
                                <input
                                    type="checkbox"
                                    name={inputName}
                                    value={choice}
                                    checked={Array.isArray(inputValue) && inputValue.includes(choice)}
                                    onChange={props.handleChange} />
                                {choice}
                            </label>
                        ))}
                    </div>
                )

            default:
                return null;
        }
    }

    return (
        <div key={props.index}
            className={`question ${props.index === props.currentQuestion ? 'active' : ''}`}
            style={{ marginBottom: "20px" }}>

            <label className="survey-contents">
                {props.question.label}
                {renderInput()}
            </label>
        </div>
    )
};

export default Question;
