import React from 'react';

//aesthetics
import { tokens } from "../../theme"
import { useTheme } from '@mui/material';

//imgs
import nbBD from '../Resources/img/nbBD.png'
import nbC from '../Resources/img/nbC.png'
import nbMktg from '../Resources/img/nbMktg.png'

//execution
const Hats = () => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const circleStyle = {
    // borderRadius: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: '15px',
    padding: '15px',
    boxShadow: '12px 12px 6px rgba(0, 0, 0, 0.50)',
    // boxShadow: `12px 12px 6px rgba(${colors.primary[100]}, 0.5)`,
    // border: '3px solid #000',
  };

  const circleOneStyle = {
    ...circleStyle,
    // borderColor: `${colors.SKgrey[400]}`,
    backgroundColor: `${colors.SKgrey[400]}`,
  };

  const circleTwoStyle = {
    ...circleStyle,
    backgroundColor: `${colors.SKgrey[300]}`,
  };

  const circleThreeStyle = {
    ...circleStyle,
    backgroundColor: `${colors.SKgrey[200]}`,
  };

  return (
    <div className="Stackcontainer">
      <div className ="squares" style={circleOneStyle}>
        <img className = "nb" src={nbC} alt="placeholder" />
        <h2 style={{color: '#040509', margin:0}}>Creative</h2>
        <p style={{color: '#040509', margin:0,}}>Craft the narrative.</p>
      </div>
      <div className ="squares" style={circleTwoStyle}>
        <img className = "nb" src={nbBD}alt="placeholder" />
        <h2 style={{color: '#040509',margin:0}}>BD</h2>
        <p style={{color: '#040509', margin:0,}}>Pitch it to the client.</p>
      </div>
      <div className ="squares" style={circleThreeStyle}>
        <img className = "nb" src={nbMktg}alt="placeholder" />
        <h2 style={{color: '#040509', margin:0}}>Mktg</h2>
        <p style={{color: '#040509', margin:0,}}>Share it with the world.</p>
      </div>
    </div>
  );
};

export default Hats;
