import React from 'react';
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";

import email from "../../../img/email.png"
import instagram from "../../../img/instagram.png"
import meta from "../../../img/meta.png"
import tiktok from "../../../img/tiktok.png"

const CalendarKey = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const circles = [
        { label: 'The Pantry', color: '#C0C0C0', textColor: "#C0C0C0" },
        { label: 'Spirits Network', color: '#ab966d', textColor: "#ab966d" },
        { label: 'Golf Nation', color: colors.blueAccent[50], textColor: colors.blueAccent[50] },
    ];


    return (
        <div className="row3" >

            {/* channel key */}
            <div style={{ width: "400px", paddingLeft: "20px" }} className="KeyContainer">

                {circles.map((circle, index) => (
                    <div key={index} style={{ display: 'flex', marginRight: '20px' }}>

                        {/* circles */}
                        <div style={{ width: '20px', height: '20px', borderRadius: '50%', backgroundColor: circle.color, marginRight: '10px' }} />

                        {/* text */}
                        <span style={{ color: circle.textColor }}>{circle.label}</span>

                    </div>
                ))}
            </div>

            {/* social key */}
            <div style={{ width: "250px", paddingLeft: "20px", color: colors.SKcustom[10] }} className="KeyContainer">

                <div style={{ marginRight: '2em', textAlign: 'center' }}>
                    <img src={email} alt="Email" className="KeyIcon" />
                    <p>Email</p>
                </div>
                <div style={{ marginRight: '2em', textAlign: 'center' }}>
                    <img src={instagram} alt="Instagram" className="KeyIcon" />
                    <p>IG</p>
                </div>
                <div style={{ marginRight: '2em', textAlign: 'center' }}>
                    <img src={meta} alt="Meta" className="KeyIcon" />
                    <p>Meta</p>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <img src={tiktok} alt="TikTok" className="KeyIcon" />
                    <p>TikTok</p>
                </div>
            </div>

        </div>
    );

};

export default CalendarKey;
