import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material';
import { tokens } from '../../theme'

import '../../components/visuals/design/videoMetrics.css'


import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, Tooltip, Legend, CategoryScale } from 'chart.js';

ChartJS.register(LineElement, PointElement, LinearScale, Title, Tooltip, Legend, CategoryScale);

const UsBcPurchases = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    function hexToRgba(hex, opacity) {
        hex = hex.replace('#', '');
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        return `rgba(${r},${g},${b},${opacity})`;
    }

    const [data, setData] = useState([]);
    const [gnData, setGnData] = useState([])

    const monthNameMapping = {
        '1': 'Jan',
        '2': 'Feb',
        '3': 'Mar',
        '4': 'Apr',
        '5': 'May',
        '6': 'Jun',
        '7': 'Jul',
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/audience/usbc`);
                if (response.ok) {
                    const fetchedData = await response.json();
                    setData(fetchedData);
                } else {
                    console.error('Failed to fetch data');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        const fetchGnData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/gnbc/orders24`);
                if (response.ok) {
                    const fetchedData = await response.json();
                    setGnData(fetchedData);
                } else {
                    console.error('Failed to fetch data');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };



        fetchData();
        fetchGnData()

    }, []);

    const snData = {
        labels: data.map(item => monthNameMapping[item.month]), // X-axis labels as months

        datasets: [
            {
                label: 'SN',
                data: data.map(item => item.totalOrders),
                borderColor: '#ab966d', // Line color
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                fill: false,
                tension: 0.1,
                borderWidth: 2,
                pointRadius: 3,
            },
            {
                label: 'GN',
                data: gnData.map(item => item.totalOrders),
                borderColor: '#1c3ba2',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                fill: false,
                tension: 0.1,
                borderWidth: 2,
                pointRadius: 3,
            },


        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
                labels: {

                    boxWidth: 10,
                    font: { size: 8 }
                },
            },
            title: {
                display: true,
                text: 'Orders Placed',
                font: {
                    size: 10
                }
            },
            datalabels: {
                display: true,
                align: 'top',
                font: { size: 8 },

            }
        },
        scales: {
            y: {
                ticks: {
                    display: false,
                    font: { size: 8 },
                },
                grid: {
                    display: false,
                    drawBorder: false
                },
            },
            x: {
                ticks: { font: { size: 8 }, },
                grid: {
                    display: false,
                },
            },
        },
    };



    return (
        <div style={{ height: '200px' }}>

            <Line data={snData} options={options} />


        </div>
    );
};

export default UsBcPurchases;